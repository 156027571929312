import { API_BASE_URL } from '../../common/constants';
import { Api } from '../../core/api';
import { generateQueryParams } from '../helpers/helper';
import routesConstants from './routes';

const getIsolationCertificates = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_ISOLATION_CERTIFICATES}`, data);
};

const createIsolationCertificate = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_ISOLATION_CERTIFICATE}`, data);
};

const getIsolationCertificateDetails = async isolationCertificateId => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_ISOLATION_CERTIFICATE_DETAILS}?isolation_certificate_id=${isolationCertificateId}`);
};

const archiveIsolationCertificate = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ARCHIVE_ISOLATION_CERTIFICATE}`, data);
};

const deleteIsolationCertificate = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_ISOLATION_CERTIFICATE}`, data);
};

const updateIsolationCertificateDetails = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_ISOLATION_CERTIFICATE_DETAILS}`, data);
};

const getIsolationCertificateTemplates = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_ISOLATION_CERTIFICATE_TEMPLATES}`, data);
};

const getIsolationCertificateTemplatesFilters = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_ISOLATION_CERTIFICATE_TEMPLATES_FILTERS}`, data);
};

const isolationCertificateAnswerOnQuestion = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ISOLATION_CERTIFICATE_ANSWER_ON_QUESTION}`, data);
};

const getAvailableKeyBoxes = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_AVAILABLE_KEY_BOXES}`, data);
};

const assignKeyBox = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ASSIGN_KEY_BOX}`, data);
};

const addIsolationComponent = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_ISOLATION_COMPONENT}`, data);
};

const updateIsolationComponent = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_ISOLATION_COMPONENT}`, data);
};

const deleteIsolationComponent = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_ISOLATION_COMPONENT}`, data);
};

const deleteIsolationComponentFile = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_ISOLATION_COMPONENT_FILE}`, data);
};

const orderIsolationComponents = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ORDER_ISOLATION_COMPONENTS}`, data);
};

const changeIsolationCertificateStatus = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CHANGE_ISOLATION_CERTIFICATE_STATUS}`, data);
};

const generatePDF = async (isolationCertificateId, includePopulatedDrawingsPDF) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstants.GENERATE_PDF}${generateQueryParams([{ isolation_certificate_id: isolationCertificateId }, { include_populated_drawings: includePopulatedDrawingsPDF }])}`,
    { responseType: 'blob' }
  );
};

const generatePDFPopulatedDrawing = async data => {
  return await Api.get(
    `${API_BASE_URL}${routesConstants.GENERATE_PDF_POPULATED_DRAWING}${generateQueryParams([{ isolation_certificate_id: data.IsolationCertificateID }, { file_id: data.FileID }])}`,
    {
      responseType: 'blob',
    }
  );
};

const changeIsolationCertificateStatusWithData = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CHANGE_ISOLATION_CERTIFICATE_STATUS_WITH_DATA}`, data);
};

const getIsolationContractorsList = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_ISOLATION_CONTRACTORS_LIST}`, data);
};

const deleteIsolationCertificateFile = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_ISOLATION_CERTIFICATE_FILE}`, data);
};

const unAssignKeyBox = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UNASSIGN_KEY_BOX}`, data);
};

const getIsolationCertificateStatusFlows = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_ISOLATION_CERTIFICATE_STATUS_FLOWS}${generateQueryParams(params)}`);
};

export const fetchIsolationCertificateComments = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.FETCH_ISOLATION_CERTIFICATE_COMMENTS}${generateQueryParams(params)}`);
};

export const addIsolationCertificateComment = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_ISOLATION_CERTIFICATE_COMMENT}`, params);
};

export const deleteIsolationCertificateComment = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_ISOLATION_CERTIFICATE_COMMENT}`, params);
};

export default {
  getIsolationCertificates,
  createIsolationCertificate,
  getIsolationCertificateDetails,
  archiveIsolationCertificate,
  deleteIsolationCertificate,
  updateIsolationCertificateDetails,
  getIsolationCertificateTemplates,
  getIsolationCertificateTemplatesFilters,
  isolationCertificateAnswerOnQuestion,
  getAvailableKeyBoxes,
  assignKeyBox,
  addIsolationComponent,
  updateIsolationComponent,
  deleteIsolationComponent,
  deleteIsolationComponentFile,
  orderIsolationComponents,
  changeIsolationCertificateStatus,
  generatePDF,
  generatePDFPopulatedDrawing,
  changeIsolationCertificateStatusWithData,
  getIsolationContractorsList,
  deleteIsolationCertificateFile,
  unAssignKeyBox,
  getIsolationCertificateStatusFlows,
  fetchIsolationCertificateComments,
  addIsolationCertificateComment,
  deleteIsolationCertificateComment,
};
