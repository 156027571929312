import { filter, find, findIndex, groupBy, uniqBy, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import Checkbox from '../../../../common/form/components/checkbox';
import Icon from '../../../../common/icon/components/icon';
import SearchInput from '../../../../common/input/components/search-input';
import { assigneeFields, checklistFields, componentDetailsDisplayProps, componentFields } from './constants/constants';

import DisplayStatus from '../../../../common/display-status/components/display-status';
import Helpers from '../../../../common/helpers';
import { componentsPickerSelectKeys } from '../../../../common/question-components/constants/question-constants';
import InfoTooltip from '../../../../common/tooltip/components/info-tooltip';
import { statuses } from '../../../checklists-procedures-management/constants/checklists-procedures-constants';
import '../../styles/add-assignees.scss';
import '../../styles/add-cp-templates.scss';

const AddCPTemplates = (
  {
    cpTemplates,
    workorderCPTemplates,
    handleSearchCPTemplates,
    addCPTemplateToWorkorder,
    removeCPTemplateFromWorkorder,
    linkToComponent,
    unlinkFromComponent,
    closeConfirmModal,
    workorderComponents,
    removeComponentFromCPTemplate,
  },
  { t }
) => {
  const [componentsByCPTemplate, uniqueWorkorderCPTemplates] = useMemo(() => {
    return [
      groupBy(workorderCPTemplates || [], assigneeFields.id),
      uniqBy(
        filter(workorderCPTemplates, c => c.ComponentID === undefined),
        assigneeFields.id
      ),
    ];
  }, [workorderCPTemplates]);

  const [filteredWorkorderComponents, setFilteredWorkorderComponents] = useState(workorderComponents);

  const handleAssignCPTemplate = (item, isSelected) => {
    if (isSelected) {
      removeCPTemplateFromWorkorder(item);
    } else {
      addCPTemplateToWorkorder(item);
    }
  };

  const handleSearchComponents = name => {
    // using a case-insensitive string search
    const searchTerm = name.toLowerCase();
    const updatedWorkorderComponents = workorderComponents.filter(component => component[componentsPickerSelectKeys.textKey].toLowerCase().includes(searchTerm));
    setFilteredWorkorderComponents(updatedWorkorderComponents);
  };

  const renderCPTemplateComponents = (item, selectedComponentIds = []) => {
    const selectedComponents = filter(workorderComponents, i => selectedComponentIds.findIndex(id => id === i.ID) > -1);
    return (
      <div className="checklist-component-picker">
        <div className="input-with-dropdown">
          <div className="d-flex align-items-center justify-content-between">
            <label className="search-input-label">{t('CREATE_WORKORDER_FORM.LINKED_COMPONENT_LABEL')}</label>
          </div>
          <SearchInput
            onChange={e => handleSearchComponents(e.target.value)}
            placeholder={t('PROJECT.SEARCH.PLACEHOLDER')}
            includeDropdown={true}
            items={filteredWorkorderComponents || []}
            emptyStateLabel={'SECTIONS.QUESTION_COMPONENT_SEARCH_PLACEHOLDER'}
            renderItem={(component, index) => {
              const isDisabled = find(selectedComponents, i => component[componentsPickerSelectKeys.valueKey] === i.ID);
              return (
                <>
                  <p
                    key={component[componentsPickerSelectKeys.valueKey]}
                    id={`row-${index}`}
                    className={`pointer ${isDisabled ? 'disabled' : ''}`}
                    onClick={isDisabled ? () => null : () => addCPTemplateToWorkorder({ ...item, ComponentID: component[componentsPickerSelectKeys.valueKey] })}
                  >
                    {component[componentsPickerSelectKeys.textKey]}
                  </p>
                </>
              );
            }}
          />
        </div>
        <div className="added-items">
          <p className="f-secondary-dark bold">{t('COMPONENT_PICKER.COMPONENT_LIST_TITLE', { number: selectedComponents ? selectedComponents.length : 0 })}</p>
          <div className="added-items-list">
            {selectedComponents &&
              selectedComponents.map((component, index) => {
                return (
                  <div className="item-r" key={index}>
                    <p className="f-primary c-title">{`${index + 1}. ${component[componentFields.name]}`}</p>
                    <InfoTooltip
                      actionsMenu={Helpers.mapInfoIconDisplayProps(component, componentDetailsDisplayProps)}
                      offsetY={8}
                      offsetX={8}
                      Component={() => <Icon name="info" size="sm" />}
                      componentProps={{ title: '' }}
                    />
                    <Icon name="close" className="x-hover pointer" onClick={() => removeComponentFromCPTemplate(item, component)} />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="add-work-order-cp-templates">
      <div className="input-with-dropdown">
        <div className="d-flex align-items-center justify-content-between">
          <label className="search-input-label">{t('CHOOSE_WORK_ORDER_NOTIFICATION.ADD_CP_LABEL')}</label>
        </div>
        <SearchInput
          onChange={e => handleSearchCPTemplates(e.target.value)}
          placeholder={t('PROJECT.SEARCH.PLACEHOLDER')}
          includeDropdown={true}
          items={cpTemplates || []}
          emptyStateLabel={'CREATE_WORKORDER_FORM.CP_TEMPLATES_EMPTY_STATE'}
          renderItem={(item, index) => {
            const isSelected = findIndex(uniqueWorkorderCPTemplates, { [assigneeFields.id]: item[assigneeFields.id] }) > -1;

            return (
              <div
                className={`dropdown-render-item-container ${isSelected ? 'selected' : ''}`}
                key={`work-order-permit-${item[assigneeFields.id]}`}
                onClick={() => handleAssignCPTemplate(item, isSelected)}
              >
                <div className="user-checkbox-wrapper">
                  {index === 0 && <p className="f-secondary-dark user-title bold">{t('WORK_ORDER.CP_TEMPLATE_NAME')}</p>}
                  <div>
                    <Checkbox meta={{}} input={{ value: isSelected }} />
                  </div>
                  <p className="f-primary light-bold module-name">{item[assigneeFields.name]}</p>
                </div>
                <div className="user-email-wrapper">
                  {index === 0 && <p className="f-secondary-dark user-title bold">{t('WORK_ORDER.PERMIT_STATUS')}</p>}
                  <DisplayStatus statuses={values(statuses)} status={item[assigneeFields.status]} />
                </div>
              </div>
            );
          }}
        />
      </div>
      <div className="added-items">
        <p className="f-secondary-dark bold">{t('CREATE_WORKORDER_FORM.ADDED_COMPONENTS', { number: uniqueWorkorderCPTemplates ? uniqueWorkorderCPTemplates.length : 0 })}</p>
        <div className="added-items-list">
          {uniqueWorkorderCPTemplates &&
            uniqueWorkorderCPTemplates.map((item, index) => {
              return (
                <>
                  <div className="item-r" key={`work-order-cp-templates-assigned-${item[assigneeFields.id]}`}>
                    <p className="f-primary c-title">{`${index + 1}. ${item[assigneeFields.name]}`}</p>
                    <div className="link_component" onClick={() => (!item[checklistFields.isLinkedToComponent] ? linkToComponent(item) : unlinkFromComponent(item))}>
                      <Icon name={`${!item[checklistFields.isLinkedToComponent] ? 'link' : 'unlink'}`} size="sm" className="active link_component__icon" />
                      <p className="f-secondary-green light-bold link_component__text">
                        {t(!item[checklistFields.isLinkedToComponent] ? 'CREATE_WORKORDER_FORM.LINK_TO_COMPONENT' : 'CREATE_WORKORDER_FORM.UNLINK_FROM_COMPONENT')}
                      </p>
                    </div>
                    <Icon name="close" className="x-hover pointer add-work-order-cp-templates__close-icon" onClick={() => removeCPTemplateFromWorkorder(item, closeConfirmModal)} />
                  </div>
                  {item[checklistFields.isLinkedToComponent] &&
                    renderCPTemplateComponents(
                      item,
                      (componentsByCPTemplate[item[assigneeFields.id]] || []).filter(i => i.ComponentID).map(i => i.ComponentID)
                    )}
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
};

AddCPTemplates.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default AddCPTemplates;
