import { API_BASE_URL } from '../../common/constants';
import { Api } from '../../core/api';
import routesConstants from './routes';

const fetchAugmentedRealityVideoList = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_VIDEO_LIST}`, data);
};

export default {
  fetchAugmentedRealityVideoList,
};
