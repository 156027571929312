import Hls from 'hls.js';
import React, { useEffect, useRef } from 'react';

const VideoPlayer = ({ src, autoplay = false, headers = {}, className = '' }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    if (video && Hls.isSupported()) {
      const hls = new Hls({
        debug: true,
        enableWorker: true,
        lowLatencyMode: true,
        backBufferLength: 90,
        startPosition: 0, // Start playback at the beginning
        maxBufferLength: 30, // Buffer 30 seconds of video
        liveBackBufferLength: 90, // Keep 90 seconds of live stream buffer
        xhrSetup: xhr => {
          // Set custom headers for all requests
          Object.entries(headers).forEach(([key, value]) => {
            xhr.setRequestHeader(key, value);
          });
        },
      });

      hls.loadSource(src);
      hls.attachMedia(video);

      hls.on(Hls.Events.ERROR, (event, data) => {
        console.error('HLS.js error:', data);
      });

      // TODO: add event listener FRAG_LOADING

      return () => {
        hls.destroy();
      };
    } else if (video) {
      // Native playback fallback
      video.src = src;
    }
  }, [src, autoplay, headers]);

  return <video ref={videoRef} className={className} controls />;
};

export default VideoPlayer;
