import { get, isEmpty, map, toInteger } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { setCollapsibleInfo, setCollapsibleInfoContentProps, setCollapsibleInfoIsExpanded, setCollapsibleInfoTitle } from '../../../../../common/collapsible-info/actions/action-creators';
import Button from '../../../../../common/form/components/button';
import Loader from '../../../../../common/global-loader/components/simple-loader';
import Helpers from '../../../../../common/helpers';
import { ReactComponent as PaperclipSvg } from '../../../../../common/icon/assets/paperclip.svg';
import Icon from '../../../../../common/icon/components/icon';
import Modal from '../../../../../common/modal/components/modal';
import { setGenericNotification } from '../../../../../common/notification/actions/action-creators';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../../common/permissions-constants';
import RenderIf from '../../../../../common/render-if/components/render-if';
import Dropzone from '../../../../../common/upload/components/upload-dropzone';
import { useFileOpener } from '../../../../../hooks/use-file-opener';
import { getChecklistQuestionDMSFilesUploaded } from '../../../../checklists-procedures-management/actions/checklists-procedures-management-actions';
import { fetchComponentHierarchyLevels, fetchComponents, updateComponentHierarchyComponent } from '../../../../component-hierarchy/actions/component-hierarchy-levels-actions';
import {
  confirmCPEvidenceDMSFileUploadIsDone,
  confirmCPQuestionDMSFileUploadIsDone,
  confirmComponentDMSFileUploadIsDone,
  confirmMeasurementReadingDMSFileUploadIsDone,
  confirmNotificationDMSFileUploadIsDone,
  confirmObservationDMSFileUploadIsDone,
  confirmWorkorderCompletionDMSFileUploadIsDone,
  confirmWorkorderDMSFileUploadIsDone,
  confirmWorkorderWizardDMSFileUploadIsDone,
  fetchCPQuestionDMFiles,
  fetchCPQuestionDMFolders,
  fetchComponentDMFiles,
  fetchComponentDMFolders,
  fetchMeasurementReadingDMFiles,
  fetchMeasurementReadingDMFolders,
  fetchNotificationDMFiles,
  fetchNotificationDMFolders,
  fetchObservationDMFiles,
  fetchObservationDMFolders,
  fetchWorkorderCompletionDMFiles,
  fetchWorkorderCompletionDMFolders,
  fetchWorkorderDMFiles,
  fetchWorkorderDMFolders,
  getPdfComponentDrawings,
  linkDMSFileFromChecklistQuestion,
  linkDMSFileFromChecklistQuestionEvidence,
  linkDMSFileFromMeasurementReading,
  linkDMSFileFromNotification,
  linkDMSFileFromObservation,
  linkDMSFileFromWorkorder,
  linkDMSFileFromWorkorderCompletion,
  unlinkDMSFileFromChecklistQuestion,
  unlinkDMSFileFromChecklistQuestionEvidence,
  unlinkDMSFileFromMeasurementReading,
  unlinkDMSFileFromNotification,
  unlinkDMSFileFromObservation,
  unlinkDMSFileFromWorkorder,
  unlinkDMSFileFromWorkorderCompletion,
} from '../../../../document-management/actions/dm-api-calls';
import { getIconForFileType } from '../../../../document-management/actions/table-formatting';
import DMSUploadDrawer from '../../../../document-management/components/dms-upload-drawer/dms-upload-drawer';
import ActionModal from '../../../../document-management/components/modals/action-modal/action-modal';
import ChangeDmsLocation from '../../../../document-management/components/modals/change-dms-location/change-dms-location';
import { fields, supportedUploadFileMimeTypes, uploadedFileValidation } from '../../../../document-management/constants/constants';
import {
  getExcessFiles,
  isUploadedDMSFileNameValid,
  isUploadedDMSFileValid,
  onDMSDropAsset,
  wouldExceedCategoryFileLimit,
  wouldExceedTotalFileLimit,
} from '../../../../document-management/helpers/dms-helpers';
import { setPdfComponents } from '../../../../pdf-tag/actions/action-creators';
import { changePDFPage } from '../../../../pdf-tag/actions/pdf-tag-actions';
import { fetchDMSCategories } from '../../../../project/actions/project-actions';
import LinkedImagesSliderSlider from '../../../../start-workflow/components/linked-images-slider';
import orientationConstants from '../../../../start-workflow/constants/orientation-constants';
import { amendWorkorderFilesUploaded, removeWorkorderFileUploadedByID } from '../../../../upload/actions/action-creators';
import { getComponentDMSFilesUploaded } from '../../../../upload/actions/external-upload-actions';
import { setUploadBatchItems, uploadBatchItems } from '../../../../upload/actions/upload-actions';
import { uploadType } from '../../../../upload/constants/constants';
import { createNewBatch } from '../../../../upload/helpers/upload-helpers';
import { setInspectionComponents } from '../../../actions/action-creators';
import {
  closeInspectionModal,
  linkDMSFileFromComponent,
  maximizeInspectionModal,
  minimizeInspectionModal,
  openAsSeparatePageInspectionModal,
  toggleInspectionModal,
  unlinkDMSFileFromComponent,
} from '../../../actions/inspection-actions';
import { modules } from '../../../constants/constants';
import { formConstants } from '../../../constants/defect-constants';
import '../../../styles/change-hierarchy-modal.scss';
import { getNotificationDMSFilesUploaded } from '../../notifications/actions/notifications-api-calls';
import { getObservationDMSFilesUploaded } from '../../observations/actions/observations-api-calls';
import { getMeasurementReadingDMSFilesUploaded } from '../../readings-and-gauges/actions/measurement-readings-actions';
import { measurementReadingFormConstants } from '../../readings-and-gauges/constants/measurement-readings-constants';
import { getWorkorderCompletionDMSFilesUploaded, getWorkorderDMSFilesUploaded } from '../../work-orders/actions/work-orders-api-calls';
import ComponentPdf from '../component-pdf';

const dmsLinkingActions = {
  linkFile: 'linkFileFromSelectedModuleItem',
  // manageFile is unlink or upload file, they use same permissions [moduleItem]:Create, [moduleItem]:Edit
  manageFile: 'manageFileFromSelectedModuleItem',
};

const moduleItemTypeFieldMap = {
  [modules.defects]: 'DefectID',
  [modules.components]: 'ComponentID',
  [modules.notifications]: 'NotificationID',
  [modules.workorders]: 'WorkorderID',
  [modules.checklistQuestion]: 'QuestionID',
  [modules.checklistQuestionEvidence]: 'QuestionID',
  [modules.workorderCompletion]: 'WorkorderID',
  [modules.readingsAndGauges]: 'MeasurementReadingID',
};

const EditModuleItemFilesModal = (props, context) => {
  const {
    location,
    projectDMSCategories,
    selectedModuleItem,
    linkDMSFileFromComponent,
    unlinkDMSFileFromComponent,
    linkDMSFileFromObservation,
    unlinkDMSFileFromObservation,
    linkDMSFileFromNotification,
    unlinkDMSFileFromNotification,
    linkDMSFileFromWorkorder,
    linkDMSFileFromWorkorderCompletion,
    unlinkDMSFileFromWorkorder,
    unlinkDMSFileFromWorkorderCompletion,
    setGenericNotification,
    defaultDMSCategoryID,
    getComponentDMSFilesUploaded,
    getObservationDMSFilesUploaded,
    getNotificationDMSFilesUploaded,
    getMeasurementReadingDMSFilesUploaded,
    getWorkorderDMSFilesUploaded,
    getWorkorderCompletionDMSFilesUploaded,
    getChecklistQuestionDMSFilesUploaded,
    confirmComponentDMSFileUploadIsDone,
    confirmNotificationDMSFileUploadIsDone,
    confirmObservationDMSFileUploadIsDone,
    confirmWorkorderDMSFileUploadIsDone,
    confirmWorkorderWizardDMSFileUploadIsDone,
    confirmCPQuestionDMSFileUploadIsDone,
    confirmWorkorderCompletionDMSFileUploadIsDone,
    confirmMeasurementReadingDMSFileUploadIsDone,
    defaultUnassignedDMSFolderID,
    defaultComponentUnassignedDMSFolderID,
    defaultReadingsAndGaugesUnassignedDMSFolderID,
    defaultObservationstUnassignedDMSFolderID,
    defaultWorkOrdersUnassignedDMSFolderID,
    defaultNotificationsUnassignedDMSFolderID,
    defaultIsolationsUnassignedDMSFolderID,
    defaultPermitsUnassignedDMSFolderID,
    defaultChecklistProcedureUnassignedDMSFolderID,
    uploadFiles,
    setUploadBatchItems,
    setCollapsibleInfo,
    uploadBatchItems,
    defectFiles,
    componentFiles,
    notificationFiles,
    workorderFiles,
    workOrderCompletionFiles,
    amendWorkorderFilesUploaded,
    removeWorkorderFileUploadedByID,
    checklistQuestionFiles,
    linkDMSFileFromChecklistQuestion,
    unlinkDMSFileFromChecklistQuestion,
    // fallback goes to Components module, so it handles the smart drawing module
    moduleType = modules.components,
    overrideModuleTypeWithProp = false,
    fetchDMSCategories,
    fetchComponentDMFolders,
    fetchNotificationDMFolders,
    fetchObservationDMFolders,
    fetchWorkorderDMFolders,
    fetchWorkorderCompletionDMFolders,
    fetchCPQuestionDMFolders,
    fetchComponentDMFiles,
    fetchNotificationDMFiles,
    fetchObservationDMFiles,
    fetchWorkorderDMFiles,
    fetchWorkorderCompletionDMFiles,
    fetchCPQuestionDMFiles,
    fetchMeasurementReadingDMFolders,
    fetchMeasurementReadingDMFiles,
    checklistQuestionEvidenceFiles,
    confirmCPEvidenceDMSFileUploadIsDone,
    linkDMSFileFromChecklistQuestionEvidence,
    unlinkDMSFileFromChecklistQuestionEvidence,
    linkDMSFileFromMeasurementReading,
    unlinkDMSFileFromMeasurementReading,
    measurementReadingFiles,
    onFileUploaded,
    onFilesLinked,
    onFileUnlinked,
    user,
    disabled = false,
    allowMultiple = true,
    maxFiles = null,
    getPdfComponentDrawings,
    setPdfComponents,
    customUnlinkFileConfirmButtonText = null,
    customUnlinkFileFirstParagraph = null,
    customUnlinkFileTitle = null,
    customUnlinkFileCloseButtonText = null,
    toggleInspectionModal,
    inspectionModalData,
    closeInspectionModal,
    minimizeInspectionModal,
    maximizeInspectionModal,
    openAsSeparatePageInspectionModal,
    changePDFPage,
    pdfPageNumber,
  } = props;
  const { query } = location;
  const projectID = toInteger(get(query, 'project_id'));
  const inspectionID = toInteger(get(query, 'inspection_id'));
  const moduleItemType = overrideModuleTypeWithProp ? moduleType : get(query, 'type') || moduleType;
  const moduleItemTypeField = moduleItemTypeFieldMap[moduleItemType] || null;

  const filesMap = useMemo(
    () => ({
      [modules.defects]: defectFiles,
      [modules.components]: componentFiles,
      [modules.notifications]: notificationFiles,
      [modules.workorders]: workorderFiles,
      [modules.checklistQuestion]: !isEmpty(selectedModuleItem) ? checklistQuestionFiles[selectedModuleItem[fields.id]] : null,
      [modules.checklistQuestionEvidence]: checklistQuestionEvidenceFiles,
      [modules.workorderCompletion]: workOrderCompletionFiles,
      [modules.readingsAndGauges]: measurementReadingFiles,
    }),
    [defectFiles, componentFiles, notificationFiles, workorderFiles, selectedModuleItem, checklistQuestionFiles, checklistQuestionEvidenceFiles, workOrderCompletionFiles, measurementReadingFiles]
  );

  const uploadTypeMap = {
    [modules.defects]: uploadType.dmFilesFromDefect,
    [modules.components]: uploadType.dmFilesFromInspection,
    [modules.notifications]: uploadType.dmFilesFromNotification,
    [modules.workorders]: uploadType.dmFilesFromWorkorder,
    [modules.checklistQuestion]: uploadType.dmFilesFromChecklistQuestion,
    [modules.checklistQuestionEvidence]: uploadType.dmFilesFromChecklistQuestionEvidence,
    [modules.workorderCompletion]: uploadType.dmFilesFromWorkorderCompletion,
    [modules.readingsAndGauges]: uploadType.dmFilesFromMeasurementReadingWizard,
  };

  // if !selectedModuleItem then this component is mounted on the Work Order creation wizard, therefore the selectedUploadType is uploadType.dmFilesFromWorkorderWizard
  const selectedUploadType = selectedModuleItem ? uploadTypeMap[moduleItemType] || null : uploadType.dmFilesFromWorkorderWizard;
  const selectedModuleItemFiles = useMemo(() => {
    return !isEmpty(filesMap[moduleItemType]) ? filesMap[moduleItemType] : [];
  }, [filesMap, moduleItemType]);

  const { t } = context;

  const [modalData, setModalData] = useState({ isOpen: false });
  const [openFileModalData, setOpenFileModalData] = useState({ isOpen: false });
  const [chooseFromDocumentsModalData, setChooseFromDocumentsModalData] = useState({ isOpen: false });
  const [fileCategories, setFileCategories] = useState(projectDMSCategories);
  const [fileCategoriesLoading, setFileCategoriesLoading] = useState(false);

  useEffect(() => {
    // if the override is happening then the categories from reducer can't be used here
    // they need to be fetched for the module item type
    // for WORKORDER_COMPLETION no need to refetch the DMS categories, as those are propagated from the WO module and need to be used
    if (overrideModuleTypeWithProp && moduleItemType !== modules.workorderCompletion && moduleItemType !== modules.checklistQuestionEvidence) {
      fetchDMSCategories(
        projectID,
        moduleItemType,
        newCategories => {
          setFileCategories(newCategories);
        },
        setFileCategoriesLoading
      );
    }
    //eslint-disable-next-line
  }, []);

  const onDropAssetHandler = async (category, files) => {
    const updatedFiles = [...(filesMap[moduleItemType] || []), ...files];
    filesMap[moduleItemType] = updatedFiles;
    // this one will become obsolete once we fully migrate to notification stacking via invalidUploadedFiles method
    if (!isUploadedDMSFileValid(files)) {
      setGenericNotification({
        isDisplayed: true,
        type: 'error',
        text: t('WORK_ORDER_CREATE_VALIDATION.VALIDATION_FILE_SIZE', { fileSize: `${uploadedFileValidation.value}${uploadedFileValidation.unit}` }),
        icon: 'trash',
      });
      // Remove invalid files from the array
      filesMap[moduleItemType] = filesMap[moduleItemType].filter(file => isUploadedDMSFileValid([file]));

      // TODO: display invalid files in the notification drawer via invalidUploadedFiles
      // const invalidFiles = invalidUploadedFiles(files);
    } else if (!isUploadedDMSFileNameValid(files)) {
      setGenericNotification({
        isDisplayed: true,
        type: 'error',
        text: t('DOCUMENT_MANAGEMENT_MODAL.FILE_NAME_MAX'),
        icon: 'close',
      });

      // Remove invalid files from the array
      filesMap[moduleItemType] = filesMap[moduleItemType].filter(file => isUploadedDMSFileNameValid([file]));
    }

    // file upload limit checks
    if (maxFiles && wouldExceedCategoryFileLimit(selectedModuleItemFiles, files, category, maxFiles)) {
      setGenericNotification({
        isDisplayed: true,
        type: 'error',
        text: t('DOCUMENT_MANAGEMENT_MODAL.FILE_UPLOAD_NUMBER_MAX', { maxFiles }),
        icon: 'close',
      });

      return false;
    } else if (maxFiles && wouldExceedTotalFileLimit(updatedFiles, maxFiles)) {
      setGenericNotification({
        isDisplayed: true,
        type: 'error',
        text: t('DOCUMENT_MANAGEMENT_MODAL.FILE_UPLOAD_NUMBER_MAX', { maxFiles }),
        icon: 'close',
      });

      const excessFiles = getExcessFiles(updatedFiles, maxFiles);
      // Remove the excess files from the end of the updatedFiles array
      updatedFiles.splice(-excessFiles);
      filesMap[moduleItemType] = updatedFiles;
      return false;
    }

    await onDMSDropAsset(
      files,
      () => {},
      uploadedFiles => fileUploadHandler(uploadedFiles, category),
      selectedUploadType
    );
  };

  const batchDoneCallback = fileObj => {
    const batchDoneActionCallbackMap = {
      [modules.defects]: getObservationDMSFilesUploaded,
      [modules.components]: getComponentDMSFilesUploaded,
      [modules.notifications]: getNotificationDMSFilesUploaded,
      [modules.workorders]: getWorkorderDMSFilesUploaded,
      [modules.checklistQuestion]: getChecklistQuestionDMSFilesUploaded,
      [modules.checklistQuestionEvidence]: null,
      [modules.workorderCompletion]: getWorkorderCompletionDMSFilesUploaded,
      [modules.readingsAndGauges]: getMeasurementReadingDMSFilesUploaded,
    };

    const selectedBatchDoneCallback = batchDoneActionCallbackMap[moduleItemType] || null;
    if (selectedBatchDoneCallback) {
      selectedBatchDoneCallback(selectedModuleItem[fields.id] || selectedModuleItem[moduleItemTypeField]);
    }
    if (onFileUploaded) {
      onFileUploaded(fileObj);
    }
  };

  const confirmFileUpload = (fileID, batch, isVersionUpload, isActiveVersion, IDs, index, fileObj) => {
    const confirmDoneActionCallbackMap = {
      [modules.defects]: confirmObservationDMSFileUploadIsDone,
      [modules.components]: confirmComponentDMSFileUploadIsDone,
      [modules.notifications]: confirmNotificationDMSFileUploadIsDone,
      [modules.workorders]: confirmWorkorderDMSFileUploadIsDone,
      [modules.checklistQuestion]: confirmCPQuestionDMSFileUploadIsDone,
      [modules.checklistQuestionEvidence]: confirmCPEvidenceDMSFileUploadIsDone,
      [modules.workorderCompletion]: confirmWorkorderCompletionDMSFileUploadIsDone,
      [modules.readingsAndGauges]: confirmMeasurementReadingDMSFileUploadIsDone,
    };

    const selectedConfirmDoneCallback = selectedModuleItem ? confirmDoneActionCallbackMap[moduleItemType] || null : confirmWorkorderWizardDMSFileUploadIsDone;

    selectedConfirmDoneCallback(
      fileID,
      isActiveVersion,
      () => batchDoneCallback(fileObj),
      error => {
        const errCode = Helpers.getErrorCode(error);
        if (errCode === 6136 && maxFiles) {
          // TODO: check -> since this is a custom error code for measurement reading, unsure if error code is the same for other module items
          setGenericNotification({
            isDisplayed: true,
            type: 'error',
            text: t('DOCUMENT_MANAGEMENT_MODAL.FILE_UPLOAD_NUMBER_MAX', { maxFiles: maxFiles }),
            icon: 'close',
          });
        }
      }
    );

    if (!selectedModuleItem) {
      // work orders specific - to render uploaded files on UI
      mockConfirmFileUpload(fileID, batch, isVersionUpload, isActiveVersion, IDs, index);
    }
  };

  // work orders specific, used in work order creation wizard
  // renders the uploaded files on the FE without API call
  const mockConfirmFileUpload = (fileID, batch, _isVersionUpload, _isActive, IDs, index) => {
    let updatedFiles = batch.files[index];
    updatedFiles.fileId = fileID;
    updatedFiles = { ...updatedFiles, ...IDs };

    amendWorkorderFilesUploaded([updatedFiles]);
  };

  // reuse this as it is redundant code same as in document-management.js?
  const fileUploadHandler = async (files, category, UploadGroup, _onUpload) => {
    const moduleItemTypeUnassignedHierarchyIDMap = {
      [modules.defects]: defaultObservationstUnassignedDMSFolderID,
      [modules.components]: defaultComponentUnassignedDMSFolderID,
      [modules.notifications]: defaultNotificationsUnassignedDMSFolderID,
      [modules.workorders]: defaultWorkOrdersUnassignedDMSFolderID,
      [modules.checklistQuestion]: defaultChecklistProcedureUnassignedDMSFolderID,
      [modules.checklistQuestionEvidence]: defaultWorkOrdersUnassignedDMSFolderID,
      [modules.workorderCompletion]: defaultWorkOrdersUnassignedDMSFolderID,
      [modules.permits]: defaultPermitsUnassignedDMSFolderID,
      [modules.isolationCertificates]: defaultIsolationsUnassignedDMSFolderID,
      [modules.readingsAndGauges]: defaultReadingsAndGaugesUnassignedDMSFolderID,
    };

    // for the selected module, find the specific Unassigned Hierarchy ID and send it as payload, so these files would appear in the module Unassigned subfolder
    const moduleItemTypeUnassignedHierarchyID = moduleItemTypeUnassignedHierarchyIDMap[moduleItemType] || defaultUnassignedDMSFolderID;

    // added is active to each new file that is added directly on module item
    const newBatch = createNewBatch(
      files.map(file => ({ ...file, [fields.isActive]: true })),
      moduleItemTypeUnassignedHierarchyID
    );

    const customGetIDs = file => {
      const baseObject = {
        ProjectID: projectID,
        SourceID: 0,
        UploadGroup,
        DocumentHierarchyID: moduleItemTypeUnassignedHierarchyID,
        [fields.status]: file[fields.status],
        [fields.fileSizeKB]: file.size,
        [fields.fileType]: file[fields.fileType],
        [fields.smartDrawing]: file[fields.smartDrawing],
        UploadType: selectedUploadType,
        [fields.categoryId]: category?.[fields.id] || defaultDMSCategoryID,
        [fields.isPdf]: Helpers.isPDF(file[fields.fileName] || file[fields.name] || file.name, file[fields.fileType]),
        [fields.isImage]: Helpers.isImage(file[fields.fileName] || file[fields.name] || file.name, file[fields.fileType]),
      };

      if (selectedModuleItem) {
        baseObject[moduleItemTypeField] = selectedModuleItem[fields.id] || selectedModuleItem[moduleItemTypeField] || null;
      }

      return baseObject;
    };

    // set batch to upload
    setUploadBatchItems(newBatch);
    // Show files in the notification drawer
    setCollapsibleInfo({
      customContent: () => <DMSUploadDrawer />,
      isDisplayed: true,
      isExpanded: true,
      title: t('DOCUMENT_MANAGEMENT.FILE_UPLOADING_TITLE', { totalItems: files.length + uploadFiles.length }),
    });

    // Start uploading the batch
    uploadBatchItems(newBatch, customGetIDs, (fileID, batch, isVersionUpload, isActive, index, fileObj) => {
      confirmFileUpload(fileID, batch, isVersionUpload, isActive, { ...customGetIDs(newBatch) }, index, fileObj);
    });
  };

  const onLinkFileFromSelectedModuleItem = async (files, category) => {
    if (maxFiles && wouldExceedCategoryFileLimit(selectedModuleItemFiles, files, category, maxFiles)) {
      setGenericNotification({
        isDisplayed: true,
        type: 'error',
        text: t('DOCUMENT_MANAGEMENT_MODAL.FILE_LINK_NUMBER_MAX', { maxFiles: maxFiles }),
        icon: 'close',
      });

      return false;
    }

    const linkedFilesCategories = [
      ...files.map(f => {
        return { [fields.id]: f.fileId, [fields.categoryId]: category[fields.id] };
      }),
    ];

    const linkCallbackMap = {
      [modules.defects]: getObservationDMSFilesUploaded,
      [modules.components]: getComponentDMSFilesUploaded,
      [modules.notifications]: getNotificationDMSFilesUploaded,
      [modules.workorders]: getWorkorderDMSFilesUploaded,
      [modules.checklistQuestion]: getChecklistQuestionDMSFilesUploaded,
      [modules.checklistQuestionEvidence]: null,
      [modules.workorderCompletion]: getWorkorderCompletionDMSFilesUploaded,
      [modules.readingsAndGauges]: getMeasurementReadingDMSFilesUploaded,
    };

    const selectedCallback = linkCallbackMap[moduleItemType] || null;

    const linkActionsMap = {
      [modules.defects]: linkDMSFileFromObservation,
      [modules.components]: linkDMSFileFromComponent,
      [modules.notifications]: linkDMSFileFromNotification,
      [modules.workorders]: linkDMSFileFromWorkorder,
      [modules.checklistQuestion]: linkDMSFileFromChecklistQuestion,
      [modules.checklistQuestionEvidence]: linkDMSFileFromChecklistQuestionEvidence,
      [modules.workorderCompletion]: linkDMSFileFromWorkorderCompletion,
      [modules.readingsAndGauges]: linkDMSFileFromMeasurementReading,
    };

    const selectedLinkAction = linkActionsMap[moduleItemType] || null;

    selectedLinkAction(selectedModuleItem[fields.id] || selectedModuleItem[moduleItemTypeField], linkedFilesCategories, () => {
      if (selectedCallback) {
        selectedCallback(selectedModuleItem[fields.id] || selectedModuleItem[moduleItemTypeField] || null);
      }
      if (onFilesLinked) {
        onFilesLinked([
          ...files.map(f => {
            return { ...f, [fields.sourceID]: f.fileId, [fields.id]: f.fileId, [fields.fileId]: f.fileId, [fields.categoryId]: category[fields.id] };
          }),
        ]);
      }
    });
  };

  // work orders specific, used in work order creation wizard
  const mockOnLinkFileFromSelectedModuleItem = (files, category, modalWorkorderFiles) => {
    if (modalWorkorderFiles?.length > 0 && files?.length > 0) {
      // disable linking the same file if for the same category
      const fileToLink = files[0];
      if (modalWorkorderFiles.find(woFile => woFile.fileId === fileToLink[fields.id] && woFile[fields.categoryId] === category[fields.id])) {
        // display generic notification to notify user that linking same file within same category is not possible
        setGenericNotification({ isDisplayed: true, type: 'error', text: t('ERROR_MODAL.6125'), icon: 'close' });
        return;
      }
    }
    const updatedFiles = files.map(file => ({ ...file, [fields.categoryId]: category[fields.id] }));
    amendWorkorderFilesUploaded(updatedFiles);
  };

  const onUnlinkFileFromSelectedModuleItem = (file, component, categoryID) => {
    const unlinkFileSuccess = () => {
      const unlinkCallbackMap = {
        [modules.defects]: getObservationDMSFilesUploaded,
        [modules.components]: getComponentDMSFilesUploaded,
        [modules.notifications]: getNotificationDMSFilesUploaded,
        [modules.workorders]: getWorkorderDMSFilesUploaded,
        [modules.checklistQuestion]: getChecklistQuestionDMSFilesUploaded,
        [modules.checklistQuestionEvidence]: null,
        [modules.workorderCompletion]: getWorkorderCompletionDMSFilesUploaded,
        [modules.readingsAndGauges]: getMeasurementReadingDMSFilesUploaded,
      };

      const selectedCallback = unlinkCallbackMap[moduleItemType] || null;

      const unlinkActionsMap = {
        [modules.defects]: unlinkDMSFileFromObservation,
        [modules.components]: unlinkDMSFileFromComponent,
        [modules.notifications]: unlinkDMSFileFromNotification,
        [modules.workorders]: unlinkDMSFileFromWorkorder,
        [modules.checklistQuestion]: unlinkDMSFileFromChecklistQuestion,
        [modules.checklistQuestionEvidence]: unlinkDMSFileFromChecklistQuestionEvidence,
        [modules.workorderCompletion]: unlinkDMSFileFromWorkorderCompletion,
        [modules.readingsAndGauges]: unlinkDMSFileFromMeasurementReading,
      };

      const selectedUnlinkAction = unlinkActionsMap[moduleItemType] || null;
      selectedUnlinkAction(file.SourceID, selectedModuleItem[fields.id] || selectedModuleItem[moduleItemTypeField], categoryID, () => {
        if (selectedCallback) {
          selectedCallback(selectedModuleItem[fields.id] || selectedModuleItem[moduleItemTypeField] || null);
        }
        if (onFileUnlinked) {
          onFileUnlinked(file);
        }
      });

      setModalData({ isOpen: false });
    };

    // work orders specific, used in work order creation wizard
    const mockUnlinkFileSuccess = () => {
      removeWorkorderFileUploadedByID(file);
      setModalData({ isOpen: false });
    };

    setModalData({
      type: '',
      isOpen: true,
      closeAction: () => setModalData({ isOpen: false }),
      customCloseAction: () => setModalData({ isOpen: false }),
      className: 'modal-no-max-height modal-medium',
      CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
      confirmButtonText: customUnlinkFileConfirmButtonText || 'UNLINK_FILE',
      closeButtonText: customUnlinkFileCloseButtonText || 'CLOSE',
      customConfirmAction: () => (selectedModuleItem ? unlinkFileSuccess() : mockUnlinkFileSuccess()),
      // TODO: passing WORK_ORDER.CURRENT_TITLE is not modular, needs to be tweaked
      firstParagraphProps: { name: Helpers.decodeIfStringEncoded(file.FileName || file[fields.realName]), component: component?.[fields.name] || t('WORK_ORDER.CURRENT_TITLE') },
      firstParagraph: customUnlinkFileFirstParagraph || 'UNLINK_FILE_CONFIRMATION',
      title: t(customUnlinkFileTitle || '') || t('UNLINK_FILE'),
    });
  };

  const openChooseDocumentsModal = category => {
    setChooseFromDocumentsModalData({
      isOpen: true,
      type: '',
      customClassName: 'modal-no-max-height modal-large',
      closeAction: () => setChooseFromDocumentsModalData({ isOpen: false }),
      customCloseAction: () => setChooseFromDocumentsModalData({ isOpen: false }),
      CustomContent: dynamicProps => <ChangeDmsLocation {...dynamicProps} />,
      title: t('CHOOSE_DOCUMENT'),
      fileInformation: category, // sending category so selectedModuleItemFiles can be filtered for selected category
      customConfirmAction: () => null,
      isChooseDocument: true,
      moduleItemType: moduleItemType,
      projectID: projectID,
    });
  };

  const openImagesModal = (selectedImage, linkedImages) => {
    let index = 0;
    linkedImages = map(linkedImages, (item, i) => {
      if (selectedImage.FileID === item.FileID) {
        index = i;
      }
      return item;
    });

    setOpenFileModalData({
      ...openFileModalData,
      customClassName: 'linked-images-slider',
      CustomContent: () => (
        <LinkedImagesSliderSlider
          linkedImages={linkedImages}
          currentSlideIndex={index}
          setOrientation={() => null}
          disabledOrientations={[orientationConstants.orientation.chart]}
          isPreviewModal={true}
        />
      ),
      isOpen: true,
      type: 'none',
      closeAction: closeOpenFileModal,
    });
  };

  const { openFile } = useFileOpener({
    inspectionId: inspectionID,
    getPdfComponentsForPopup: getPdfComponentDrawings,
    setPdfComponents,
    setModalData,
    t,
    openImagesModal,
    toggleInspectionModal,
    inspectionModalData,
    closeInspectionModal,
    minimizeInspectionModal,
    maximizeInspectionModal,
    openAsSeparatePageInspectionModal,
    changePDFPage,
    pdfPageNumber,
  });

  const handleFileOpen = (file, imageFiles) => {
    // when PDF opened in popup from module details or DMS, we show only confirmed tags/drawings
    openFile(file, imageFiles, null, props => <ComponentPdf {...props} file={file} rightCollapsed={true} showOnlyConfirmedDrawings={true} inspectionId={inspectionID} />);
  };

  const closeOpenFileModal = () => setOpenFileModalData({ isOpen: false });

  const hasRequiredPermissions = action => {
    // Disabled prop overrides all other permissions & rules
    if (disabled) {
      return false;
    }
    // action can be link or manage
    const specialCases = {
      [modules.checklistQuestion]: PERMISSION_TYPES.checklists,
      [modules.workorders]: PERMISSION_TYPES.workOrders,
      [modules.checklistQuestionEvidence]: PERMISSION_TYPES.workOrders,
      [modules.workorderCompletion]: PERMISSION_TYPES.workOrders,
      [modules.readingsAndGauges]: PERMISSION_TYPES.readingsAndGauges,
    };

    let formattedModuleItemType;

    switch (specialCases[moduleItemType]) {
      case PERMISSION_TYPES.workOrders:
        formattedModuleItemType = specialCases[moduleItemType].charAt(0).toLowerCase() + specialCases[moduleItemType].slice(1);
        break;
      case PERMISSION_TYPES.checklists:
        formattedModuleItemType = specialCases[moduleItemType].toLowerCase();
        break;
      case PERMISSION_TYPES.readingsAndGauges:
        formattedModuleItemType = specialCases[moduleItemType].charAt(0).toLowerCase() + specialCases[moduleItemType].slice(1);
        break;
      default:
        formattedModuleItemType = moduleItemType.toLowerCase();
    }

    if (action === dmsLinkingActions.linkFile) {
      const permission = PERMISSIONS[PERMISSION_TYPES[formattedModuleItemType]]?.chooseFromDMS?.name;
      return permission ? Helpers.hasAccess({ user, visibleFor: permission }) : true;
    } else if (action === dmsLinkingActions.manageFile) {
      let createPermission,
        editPermission = null;

      switch (moduleItemType) {
        // this is due to this component being used only for Measurement Reading file management
        case modules.readingsAndGauges:
          createPermission = PERMISSIONS[PERMISSION_TYPES[formattedModuleItemType]]?.readingCreate?.name;
          editPermission = PERMISSIONS[PERMISSION_TYPES[formattedModuleItemType]]?.readingEdit?.name;
          break;
        default:
          createPermission = PERMISSIONS[PERMISSION_TYPES[formattedModuleItemType]]?.create?.name;
          editPermission = PERMISSIONS[PERMISSION_TYPES[formattedModuleItemType]]?.edit?.name;
      }

      const createdByPropMap = {
        [modules.defects]: formConstants.fields.loggedByUserID,
        [modules.components]: formConstants.fields.createdByUserID,
        [modules.notifications]: fields.createdByUserID,
        [modules.workorders]: fields.createdByUserID,
        [modules.checklistQuestion]: fields.createdByID,
        [modules.checklistQuestionEvidence]: fields.createdByUserID,
        [modules.workorderCompletion]: fields.createdByUserID,
        // additional mapping due to the API inconsistency in the measurement readings module with regards to the createdByID field
        [modules.readingsAndGauges]: measurementReadingFormConstants.fields.measuredById || fields.createdByID,
      };

      const selectedModuleItemCreatedByProp = get(createdByPropMap, moduleItemType) || null;

      return selectedModuleItem
        ? Helpers.hasAccess({
            user,
            visibleFor: editPermission,
            id: get(selectedModuleItem, selectedModuleItemCreatedByProp),
            ownerRequiredPermission: createPermission,
          })
        : true;
    }
  };

  const isUploadLimitReached = useMemo(() => {
    return maxFiles && selectedModuleItemFiles.length >= maxFiles;
  }, [selectedModuleItemFiles, maxFiles]);

  const renderCategoriesFiles = () => {
    let dmsCategories = [...fileCategories];
    let files = [...selectedModuleItemFiles];
    if (moduleItemType === modules.checklistQuestion) {
      // special case, render all files under one category
      let defaultDMSCategory = projectDMSCategories.find(c => c[fields.name] === 'NO_CATEGORY');
      defaultDMSCategory = { [fields.id]: defaultDMSCategory[fields.id], [fields.name]: 'WORK_ORDER.FILES_TITLE' };
      dmsCategories = defaultDMSCategory ? [defaultDMSCategory] : [];
      files = selectedModuleItemFiles.map(el => ({ ...el, [fields.categoryId]: defaultDMSCategory[fields.id] }));
    }

    return (
      <>
        <RenderIf if={fileCategoriesLoading}>
          <Loader isLoading={fileCategoriesLoading} />
        </RenderIf>
        <RenderIf if={!fileCategoriesLoading}>
          {dmsCategories.map(c => {
            const category = c[fields.id];

            if (!category) return null;
            const categoryFiles = files.filter(cf => cf[fields.categoryId] === category) || [];

            return (
              <div className="category-wrapper" key={c[fields.id]}>
                <h3 className="title">
                  {t(c[fields.name])}
                  {!isEmpty(c?.[fields.description]) && <span className="description">({t(c[fields.description])})</span>}
                </h3>
                {categoryFiles?.length > 0 && (
                  <div className="category-files-wrapper">
                    <div className="header">
                      <p>{t('TABLE.COMPANY_NAME')}</p>
                    </div>
                    {categoryFiles.map(file => {
                      const decodedFileName = Helpers.decodeIfStringEncoded(file[fields.fileName] || file[fields.realName]);
                      return (
                        <div className="row" key={file.FileID}>
                          <Icon name={getIconForFileType(file[fields.fileType] || file[fields.fileName] || file[fields.realName])} size="sm" />
                          <span title={Helpers.decodeIfStringEncoded(file.FileName)} className="title" onClick={() => handleFileOpen(file)}>
                            {Helpers.truncateText(decodedFileName, 60)}
                          </span>
                          <Icon
                            name="close"
                            size="sm"
                            disabled={!hasRequiredPermissions(dmsLinkingActions.manageFile)}
                            handleHover={hasRequiredPermissions(dmsLinkingActions.manageFile)}
                            className={`action ${!hasRequiredPermissions(dmsLinkingActions.manageFile) ? 'disabled' : ''}`}
                            onClick={() => (hasRequiredPermissions(dmsLinkingActions.manageFile) ? onUnlinkFileFromSelectedModuleItem(file, selectedModuleItem, category) : null)}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className="buttons-wrapper">
                  <Button
                    type="button"
                    width="lg"
                    variant="success-outline"
                    keepOriginalText={true}
                    icon={'documents'}
                    className="load-more__button"
                    onClick={() => openChooseDocumentsModal(c)}
                    // ChooseFromDocuments button is disabled if the Role does not have link file permission
                    disabled={!hasRequiredPermissions(dmsLinkingActions.linkFile) || isUploadLimitReached}
                    text={t('CHOOSE_FROM_DOCUMENTS')}
                  />
                  <Dropzone
                    {...props}
                    supportedMimeTypes={supportedUploadFileMimeTypes}
                    onDrop={file => onDropAssetHandler(c, file)}
                    CustomImage={PaperclipSvg}
                    labelWhite={t('WORK_ORDER.UPLOAD_FILES')}
                    multiple={allowMultiple}
                    // Upload File button is disabled if the Role does not have per module manage file permissions (Create, Edit)
                    disabled={!hasRequiredPermissions(dmsLinkingActions.manageFile) || isUploadLimitReached}
                  />
                </div>
              </div>
            );
          })}
        </RenderIf>
      </>
    );
  };

  const chooseFromDocumentsDynamicProps = (() => {
    if (chooseFromDocumentsModalData.isOpen && chooseFromDocumentsModalData.fileInformation) {
      const fetchDMFoldersActionsMap = {
        [modules.defects]: fetchObservationDMFolders,
        [modules.components]: fetchComponentDMFolders,
        [modules.notifications]: fetchNotificationDMFolders,
        [modules.workorders]: fetchWorkorderDMFolders,
        [modules.checklistQuestion]: fetchCPQuestionDMFolders,
        [modules.checklistQuestionEvidence]: fetchWorkorderDMFolders,
        [modules.workorderCompletion]: fetchWorkorderCompletionDMFolders,
        [modules.readingsAndGauges]: fetchMeasurementReadingDMFolders,
      };

      const fetchDMFilesActionsMap = {
        [modules.defects]: fetchObservationDMFiles,
        [modules.components]: fetchComponentDMFiles,
        [modules.notifications]: fetchNotificationDMFiles,
        [modules.workorders]: fetchWorkorderDMFiles,
        [modules.checklistQuestion]: fetchCPQuestionDMFiles,
        [modules.checklistQuestionEvidence]: fetchWorkorderDMFiles,
        [modules.workorderCompletion]: fetchWorkorderCompletionDMFiles,
        [modules.readingsAndGauges]: fetchMeasurementReadingDMFiles,
      };

      const selectedModuleItemDMFoldersAction = fetchDMFoldersActionsMap[moduleItemType] || null;
      const selectedModuleItemDMFilesAction = fetchDMFilesActionsMap[moduleItemType] || null;
      // modal opened
      return {
        onUnlinkFileFromSelectedModuleItem: file => onUnlinkFileFromSelectedModuleItem(file, selectedModuleItem, chooseFromDocumentsModalData.fileInformation[fields.id]),
        // if no selectedModuleItem, then it is work orders specific, used in work order creation wizard, we mock the file linking
        onLinkFileFromSelectedModuleItem: (file, files) =>
          selectedModuleItem
            ? onLinkFileFromSelectedModuleItem(file, chooseFromDocumentsModalData.fileInformation)
            : mockOnLinkFileFromSelectedModuleItem(file, chooseFromDocumentsModalData.fileInformation, files),
        hasLinkDMSFilePermissions: hasRequiredPermissions(dmsLinkingActions.linkFile),
        hasUnlinkDMSFilePermissions: hasRequiredPermissions(dmsLinkingActions.manageFile),
        fetchDMFolders: selectedModuleItemDMFoldersAction,
        fetchDMFiles: selectedModuleItemDMFilesAction,
      };
    }
    return {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })();

  return (
    <div className="change-hierarchy-modal component-files-modal">
      <div className="change-hierarchy-modal__container">
        <div className="change-hierarchy-modal__container__left-side">{renderCategoriesFiles()}</div>
      </div>
      <Modal
        {...chooseFromDocumentsModalData}
        {...chooseFromDocumentsDynamicProps}
        moduleType={moduleItemType}
        selectedModuleItem={selectedModuleItem}
        overrideModuleTypeWithProp={overrideModuleTypeWithProp}
        modalWorkorderFiles={workorderFiles}
        checklistQuestionEvidenceFiles={checklistQuestionEvidenceFiles}
        measurementReadingFiles={measurementReadingFiles}
      />
      <Modal {...modalData} />
      <Modal {...openFileModalData} />
    </div>
  );
};

const mapStateToProps = state => ({
  components: state.inspectionReducer.components,
  inspectionDetails: state.inspectionReducer.inspectionDetails,
  uploadFiles: state.uploadBatchesReducer.uploadFiles,
  defaultUnassignedDMSFolderID: state.projectDetailsReducer.DefaultUnassignedDMSFolderID,
  defaultComponentUnassignedDMSFolderID: state.projectDetailsReducer.DefaultComponentUnassignedDMSFolderID,
  defaultReadingsAndGaugesUnassignedDMSFolderID: state.projectDetailsReducer.DefaultReadingsAndGaugesUnassignedDMSFolderID,
  defaultObservationstUnassignedDMSFolderID: state.projectDetailsReducer.DefaultObservationstUnassignedDMSFolderID,
  defaultWorkOrdersUnassignedDMSFolderID: state.projectDetailsReducer.DefaultWorkOrdersUnassignedDMSFolderID,
  defaultNotificationsUnassignedDMSFolderID: state.projectDetailsReducer.DefaultNotificationsUnassignedDMSFolderID,
  defaultIsolationsUnassignedDMSFolderID: state.projectDetailsReducer.DefaultIsolationsUnassignedDMSFolderID,
  defaultPermitsUnassignedDMSFolderID: state.projectDetailsReducer.DefaultPermitsUnassignedDMSFolderID,
  defaultChecklistProcedureUnassignedDMSFolderID: state.projectDetailsReducer.DefaultChecklistProcedureUnassignedDMSFolderID,
  defectFiles: state.uploadReducer.defectFiles,
  componentFiles: state.uploadReducer.componentFiles,
  notificationFiles: state.uploadReducer.notificationFiles,
  workorderFiles: state.uploadReducer.workorderFiles,
  workOrderCompletionFiles: state.workOrderReducer.workOrderCompletionFiles,
  checklistQuestionFiles: state.uploadReducer.checklistQuestionFiles,
  measurementReadingFiles: state.uploadReducer.measurementReadingFiles,
  user: state.userReducer,
  inspectionModalData: state.inspectionReducer.inspectionModalData,
  pdfPageNumber: state.pdfTagReducer.pdfPageNumber,
});

const mapDispatchToProps = dispatch => ({
  fetchComponentHierarchyLevels: (data, callback, loadMore, componentLevels) => dispatch(fetchComponentHierarchyLevels(data, callback, loadMore, componentLevels)),
  fetchComponents: (data, callback, loadMore, components) => dispatch(fetchComponents(data, callback, loadMore, components)),
  updateComponentHierarchyComponent: (data, callback) => dispatch(updateComponentHierarchyComponent(data, callback)),
  setInspectionComponents: data => dispatch(setInspectionComponents(data)),
  setGenericNotification: data => dispatch(setGenericNotification(data)),
  linkDMSFileFromComponent: (componentId, files, callback) => dispatch(linkDMSFileFromComponent(componentId, files, callback)),
  unlinkDMSFileFromComponent: (sourceId, componentId, categoryID, callback) => dispatch(unlinkDMSFileFromComponent(sourceId, componentId, categoryID, callback)),
  linkDMSFileFromObservation: (defectId, files, callback) => dispatch(linkDMSFileFromObservation(defectId, files, callback)),
  unlinkDMSFileFromObservation: (sourceId, defectId, categoryID, callback) => dispatch(unlinkDMSFileFromObservation(sourceId, defectId, categoryID, callback)),
  linkDMSFileFromNotification: (notificationId, files, callback) => dispatch(linkDMSFileFromNotification(notificationId, files, callback)),
  unlinkDMSFileFromNotification: (sourceId, notificationId, categoryID, callback) => dispatch(unlinkDMSFileFromNotification(sourceId, notificationId, categoryID, callback)),
  linkDMSFileFromWorkorder: (workorderId, files, callback) => dispatch(linkDMSFileFromWorkorder(workorderId, files, callback)),
  linkDMSFileFromWorkorderCompletion: (workorderId, files, callback) => dispatch(linkDMSFileFromWorkorderCompletion(workorderId, files, callback)),
  unlinkDMSFileFromWorkorder: (sourceId, workorderId, categoryID, callback) => dispatch(unlinkDMSFileFromWorkorder(sourceId, workorderId, categoryID, callback)),
  unlinkDMSFileFromWorkorderCompletion: (sourceId, workorderId, categoryID, callback) => dispatch(unlinkDMSFileFromWorkorderCompletion(sourceId, workorderId, categoryID, callback)),
  linkDMSFileFromChecklistQuestion: (questionId, files, callback) => dispatch(linkDMSFileFromChecklistQuestion(questionId, files, callback)),
  unlinkDMSFileFromChecklistQuestion: (sourceId, questionId, categoryID, callback) => dispatch(unlinkDMSFileFromChecklistQuestion(sourceId, questionId, categoryID, callback)),
  linkDMSFileFromChecklistQuestionEvidence: (questionId, files, callback) => dispatch(linkDMSFileFromChecklistQuestionEvidence(questionId, files, callback)),
  unlinkDMSFileFromChecklistQuestionEvidence: (sourceId, questionId, categoryID, callback) => dispatch(unlinkDMSFileFromChecklistQuestionEvidence(sourceId, questionId, categoryID, callback)),
  linkDMSFileFromMeasurementReading: (questionId, files, callback) => dispatch(linkDMSFileFromMeasurementReading(questionId, files, callback)),
  unlinkDMSFileFromMeasurementReading: (sourceId, questionId, categoryID, callback) => dispatch(unlinkDMSFileFromMeasurementReading(sourceId, questionId, categoryID, callback)),
  getComponentDMSFilesUploaded: id => dispatch(getComponentDMSFilesUploaded(id)),
  getObservationDMSFilesUploaded: id => dispatch(getObservationDMSFilesUploaded(id)),
  getNotificationDMSFilesUploaded: id => dispatch(getNotificationDMSFilesUploaded(id)),
  getMeasurementReadingDMSFilesUploaded: id => dispatch(getMeasurementReadingDMSFilesUploaded(id)),
  getWorkorderDMSFilesUploaded: id => dispatch(getWorkorderDMSFilesUploaded(id)),
  getWorkorderCompletionDMSFilesUploaded: id => dispatch(getWorkorderCompletionDMSFilesUploaded(id)),
  getChecklistQuestionDMSFilesUploaded: id => dispatch(getChecklistQuestionDMSFilesUploaded(id)),
  confirmComponentDMSFileUploadIsDone: (id, isActiveVersion, callback) => dispatch(confirmComponentDMSFileUploadIsDone(id, isActiveVersion, callback)),
  confirmNotificationDMSFileUploadIsDone: (id, isActiveVersion, callback) => dispatch(confirmNotificationDMSFileUploadIsDone(id, isActiveVersion, callback)),
  confirmObservationDMSFileUploadIsDone: (id, isActiveVersion, callback) => dispatch(confirmObservationDMSFileUploadIsDone(id, isActiveVersion, callback)),
  confirmWorkorderDMSFileUploadIsDone: (id, isActiveVersion, callback) => dispatch(confirmWorkorderDMSFileUploadIsDone(id, isActiveVersion, callback)),
  confirmWorkorderWizardDMSFileUploadIsDone: (id, isActiveVersion, callback) => dispatch(confirmWorkorderWizardDMSFileUploadIsDone(id, isActiveVersion, callback)),
  confirmCPQuestionDMSFileUploadIsDone: (id, isActiveVersion, callback) => dispatch(confirmCPQuestionDMSFileUploadIsDone(id, isActiveVersion, callback)),
  confirmCPEvidenceDMSFileUploadIsDone: (id, isActiveVersion, callback) => dispatch(confirmCPEvidenceDMSFileUploadIsDone(id, isActiveVersion, callback)),
  confirmWorkorderCompletionDMSFileUploadIsDone: (id, isActiveVersion, callback) => dispatch(confirmWorkorderCompletionDMSFileUploadIsDone(id, isActiveVersion, callback)),
  confirmMeasurementReadingDMSFileUploadIsDone: (id, isActiveVersion, successCallback, errorCallback) =>
    dispatch(confirmMeasurementReadingDMSFileUploadIsDone(id, isActiveVersion, successCallback, errorCallback)),
  setCollapsibleInfo: data => dispatch(setCollapsibleInfo(data)),
  setCollapsibleInfoTitle: data => dispatch(setCollapsibleInfoTitle(data)),
  setCollapsibleInfoIsExpanded: data => dispatch(setCollapsibleInfoIsExpanded(data)),
  setCollapsibleInfoContentProps: data => dispatch(setCollapsibleInfoContentProps(data)),
  setUploadBatchItems: data => dispatch(setUploadBatchItems(data)),
  uploadBatchItems: (batch, getIDs, customDoneFunction, needLoader, retry, enforceRetry) => dispatch(uploadBatchItems(batch, getIDs, customDoneFunction, needLoader, retry, enforceRetry)),
  amendWorkorderFilesUploaded: files => dispatch(amendWorkorderFilesUploaded(files)),
  removeWorkorderFileUploadedByID: file => dispatch(removeWorkorderFileUploadedByID(file)),
  fetchDMSCategories: (projectID, moduleName, callback, loadingCallback) => dispatch(fetchDMSCategories(projectID, moduleName, callback, loadingCallback, false)),
  fetchComponentDMFolders: (filters, dataCallback, loadingCallback) => dispatch(fetchComponentDMFolders(filters, dataCallback, loadingCallback)),
  fetchNotificationDMFolders: (filters, dataCallback, loadingCallback) => dispatch(fetchNotificationDMFolders(filters, dataCallback, loadingCallback)),
  fetchObservationDMFolders: (filters, dataCallback, loadingCallback) => dispatch(fetchObservationDMFolders(filters, dataCallback, loadingCallback)),
  fetchWorkorderDMFolders: (filters, dataCallback, loadingCallback) => dispatch(fetchWorkorderDMFolders(filters, dataCallback, loadingCallback)),
  fetchWorkorderCompletionDMFolders: (filters, dataCallback, loadingCallback) => dispatch(fetchWorkorderCompletionDMFolders(filters, dataCallback, loadingCallback)),
  fetchMeasurementReadingDMFolders: (filters, dataCallback, loadingCallback) => dispatch(fetchMeasurementReadingDMFolders(filters, dataCallback, loadingCallback)),
  fetchMeasurementReadingDMFiles: (filters, dataCallback, loadingCallback) => dispatch(fetchMeasurementReadingDMFiles(filters, dataCallback, loadingCallback)),
  fetchCPQuestionDMFolders: (filters, dataCallback, loadingCallback) => dispatch(fetchCPQuestionDMFolders(filters, dataCallback, loadingCallback)),
  fetchComponentDMFiles: (filters, dataCallback, loadingCallback) => dispatch(fetchComponentDMFiles(filters, dataCallback, loadingCallback)),
  fetchNotificationDMFiles: (filters, dataCallback, loadingCallback) => dispatch(fetchNotificationDMFiles(filters, dataCallback, loadingCallback)),
  fetchObservationDMFiles: (filters, dataCallback, loadingCallback) => dispatch(fetchObservationDMFiles(filters, dataCallback, loadingCallback)),
  fetchWorkorderDMFiles: (filters, dataCallback, loadingCallback) => dispatch(fetchWorkorderDMFiles(filters, dataCallback, loadingCallback)),
  fetchWorkorderCompletionDMFiles: (filters, dataCallback, loadingCallback) => dispatch(fetchWorkorderCompletionDMFiles(filters, dataCallback, loadingCallback)),
  fetchCPQuestionDMFiles: (filters, dataCallback, loadingCallback) => dispatch(fetchCPQuestionDMFiles(filters, dataCallback, loadingCallback)),
  getPdfComponentDrawings: (inspectionID, fileID, filter, pdfPageNumber, componentId, callback) =>
    dispatch(getPdfComponentDrawings(inspectionID, fileID, filter, pdfPageNumber, componentId, callback)),
  setPdfComponents: selectedModuleItemDrawings => dispatch(setPdfComponents(selectedModuleItemDrawings)),
  toggleInspectionModal: data => dispatch(toggleInspectionModal(data)),
  closeInspectionModal: () => dispatch(closeInspectionModal()),
  minimizeInspectionModal: () => dispatch(minimizeInspectionModal()),
  maximizeInspectionModal: () => dispatch(maximizeInspectionModal()),
  changePDFPage: (newPDFPageNumber, inspectionId, fileId, filter, selectedComponent, showOnlyConfirmedDrawings, callback) =>
    dispatch(changePDFPage(newPDFPageNumber, inspectionId, fileId, filter, selectedComponent, showOnlyConfirmedDrawings, callback)),
  openAsSeparatePageInspectionModal: () => dispatch(openAsSeparatePageInspectionModal()),
});

EditModuleItemFilesModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditModuleItemFilesModal));
