import { debounce, get, toInteger } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchComponentDetails, fetchComponentHierarchyLevels, fetchComponents } from '../../../app/component-hierarchy/actions/component-hierarchy-levels-actions';
import { ComponentHierarchyUnassignedLevelID } from '../../../app/component-hierarchy/constants/constants';
import ComponentHierarchyHelpers from '../../../app/component-hierarchy/helpers/helpers';
import DoubleTable from '../../double-table/components/double-table';
import Helpers from '../../helpers';
import PathViewer from '../../path-viewer/components/path-viewer';
import { componentsPickerSelectKeys } from '../constants/question-constants';
import '../styles/component-picker-ch.scss';
import { formatComponentCells, formatLevelCells } from './component-picker-CH/actions/component-picker-CH-actions';
import ComponentPickerCHDetails from './component-picker-CH/components/component-picker-ch-details';
import ComponentPickerCHRightSide from './component-picker-CH/components/component-picker-ch-right-side';
import { defaultComponentsFilters, defaultLevelFilters, filterProps, firstTableConfig, SearchPropName, secondTableConfig } from './component-picker-CH/constants/constants';

class ComponentPickerCH extends Component {
  constructor(props, context) {
    super(props);

    const { t } = context;
    const { location } = props;
    const { query } = location;
    this.state = {
      levelPath: [{ Code: t('COMPONENT_HIERARCHY.ALL_LEVELS'), Name: '' }],
      componentLevels: [],
      TotalItems: 0,
      filters: defaultLevelFilters,
      [SearchPropName.levels]: '',
      isLoading: false,
      currentLevelID: null,
      components: [],
      componentsTotalItems: 0,
      isLoadingComponents: false,
      componentsFilters: defaultComponentsFilters,
      [SearchPropName.components]: '',
      confirmChangeHierarchyModal: { isOpen: false },
      selectedComponentData: {},
      selectedComponent: null,
      isLoadingSelectedComponentData: false,
      isUnassignedLevel: false,
    };
    this.searchLevelsChangeDebounced = debounce(this.handleSearchLevels, 300);
    this.searchComponentsChangeDebounced = debounce(this.handleSearchComponents, 300);
    this.projectID = toInteger(get(query, 'project_id'));
  }
  componentDidMount = () => {
    const { filters } = this.state;
    this.fetchLevels(filters, false);
  };

  stateCallback = newState => this.setState({ ...newState });

  fetchLevels = (filters, loadMore = false, isUnassignedLevel = false) => {
    const { componentLevels } = this.state;
    const { fetchComponentHierarchyLevels } = this.props;

    if (isUnassignedLevel) {
      this.setState({ componentLevels: [] });
      return;
    }

    const onSuccessFetch = newState => {
      const { levelPath } = this.state;
      const { t } = this.context;
      const { componentLevels, ...rest } = newState;
      const newComponentLevels = ComponentHierarchyHelpers.addUnassignedLevel(componentLevels, levelPath, true, t);
      this.setState({ componentLevels: newComponentLevels, ...rest });

      if (loadMore && newState.componentLevels) {
        Helpers.scrollIntoView('modal-level-table', `row-${filters[filterProps.lastSeen] - 1}`, -350);
      }
    };

    fetchComponentHierarchyLevels({ ProjectID: this.projectID, ...filters }, onSuccessFetch, loadMore, componentLevels);
  };

  fetchComponents = (filters, loadMore, isUnassignedLevel) => {
    const { components } = this.state;
    const { fetchComponents } = this.props;

    const onSuccessFetch = newState => {
      this.setState({ ...newState });

      if (loadMore && newState.components) {
        Helpers.scrollIntoView('components-table', `row-${filters[filterProps.lastSeen] - 1}`, -250);
      }
    };

    fetchComponents({ ProjectID: this.projectID, ...filters }, onSuccessFetch, loadMore, components, isUnassignedLevel);
  };

  loadMoreLevels = () => {
    const { filters } = this.state;
    this.fetchLevels(filters, true);
  };

  loadMoreComponents = () => {
    const { componentsFilters, isUnassignedLevel } = this.state;
    this.fetchComponents(componentsFilters, true, isUnassignedLevel);
  };

  handleRowClick = row => {
    const { levelPath, filters, componentsFilters } = this.state;
    if (!levelPath && !levelPath[0]) return;
    const isUnassignedLevel = row.ID === ComponentHierarchyUnassignedLevelID;
    this.setState({ isUnassignedLevel });
    ComponentHierarchyHelpers.onRowLevelClick(row, levelPath, filters, defaultLevelFilters, this.fetchLevels, this.stateCallback, null, SearchPropName.levels);
    ComponentHierarchyHelpers.onComponentLevelClick(row, componentsFilters, defaultComponentsFilters, this.fetchComponents, this.stateCallback, null, SearchPropName.components);
  };

  handleComponentClick = row => {
    const { fetchComponentDetails } = this.props;
    const { t } = this.context;
    fetchComponentDetails([{ component_id: row?.ID }], this.stateCallback, t);
  };

  handlePathBackClick = () => {
    const { levelPath, filters, componentsFilters } = this.state;
    ComponentHierarchyHelpers.onPathBackClick(levelPath, filters, defaultLevelFilters, this.fetchLevels, this.stateCallback, null, SearchPropName.levels);
    ComponentHierarchyHelpers.onPathBackClickComponents(levelPath, componentsFilters, defaultComponentsFilters, this.fetchComponents, this.stateCallback, null, SearchPropName.components);
  };
  handlePathClick = ID => {
    const { levelPath, filters, componentsFilters } = this.state;
    ComponentHierarchyHelpers.onPathClick(ID, levelPath, filters, defaultLevelFilters, this.fetchLevels, this.stateCallback, null, SearchPropName.levels);
    ComponentHierarchyHelpers.onPathClickComponent(ID, componentsFilters, defaultComponentsFilters, this.fetchComponents, this.stateCallback, null, SearchPropName.components);
  };

  //handling search functions
  handleSearchLevels = SearchText => {
    const { filters } = this.state;
    ComponentHierarchyHelpers.onSearch(SearchText, filters, defaultLevelFilters, this.fetchLevels);
  };

  handleSearchComponents = SearchText => {
    const { componentsFilters, currentLevelID, isUnassignedLevel } = this.state;
    ComponentHierarchyHelpers.onSearch(SearchText, componentsFilters, defaultComponentsFilters, this.fetchComponents, { [filterProps.hierarchyID]: currentLevelID }, isUnassignedLevel);
  };

  //handling input changes
  handleInputLevelChange = e => {
    this.setState({ [SearchPropName.levels]: e.target.value });
    this.searchLevelsChangeDebounced(e.target.value);
  };

  handleInputComponentsChange = e => {
    this.setState({ [SearchPropName.components]: e.target.value });
    this.searchComponentsChangeDebounced(e.target.value);
  };

  //handle sort click

  onLevelColumnSort = SortByColumn => {
    const { filters } = this.state;
    ComponentHierarchyHelpers.onColumnSort(SortByColumn, filters, this.fetchLevels);
  };

  onComponentsColumnSort = SortByColumn => {
    const { componentsFilters, isUnassignedLevel } = this.state;
    ComponentHierarchyHelpers.onColumnSort(SortByColumn, componentsFilters, this.fetchComponents, {}, isUnassignedLevel);
  };

  addSelectedComponent = (isFromTable = false, e, component) => {
    if (isFromTable) {
      e?.preventDefault();
      e?.stopPropagation();
    }
    const { handleComponentSelected, question } = this.props;
    const { selectedComponentData } = this.state;
    const componentToAdd = isFromTable ? component : selectedComponentData;

    if (componentToAdd) {
      componentToAdd.ComponentName = componentToAdd[componentsPickerSelectKeys.textKey];
      componentToAdd.ComponentID = componentToAdd[componentsPickerSelectKeys.valueKey];
      const componentIds = [componentToAdd];
      if (typeof handleComponentSelected === 'function') {
        handleComponentSelected(question?.ID, componentIds, e);
      }
    }
  };

  render() {
    const {
      levelPath,
      componentLevels,
      TotalItems,
      filters,
      isLoading,
      [SearchPropName.levels]: levelsSearchText,
      components,
      componentsFilters,
      componentsSearchText,
      componentsTotalItems,
      isLoadingComponents,
      selectedComponent,
      selectedComponentData,
      isLoadingSelectedComponentData,
    } = this.state;
    const { t } = this.context;
    const { question, handleDeleteComponent, customCloseAction } = this.props;
    const isNested = levelPath.length > 1;
    return (
      <div className="component-picker-ch">
        <div className="component-picker-ch__container">
          <div className="component-picker-ch__container__left-side">
            <div className={`component-picker-ch__container__left-side__path ${isNested ? 'show' : 'hide'}`}>
              <PathViewer levels={levelPath} onPathClick={this.handlePathClick} onBackClick={this.handlePathBackClick} />
            </div>
            <div className={`component-picker-ch__container__left-side__table ${isNested ? 'path-opened' : ''}`}>
              <DoubleTable
                firstTableProps={{
                  tableConfig: firstTableConfig,
                  handleFormatCells: formatLevelCells,
                  data: componentLevels,
                  sortDirection: filterProps.sortDirection,
                  sortByColumn: filterProps.sortByColumn,
                  filters,
                  searchPlaceholder: t('ASSIGN_COMPONENTS.SEARCH_LEVEL'),
                  noDataText: t('ASSIGN_COMPONENTS.LEVEL_EMPTY_STATE'),
                  selectedItem: null,
                  TotalItems,
                  hasNext: filters[filterProps.hasNext],
                  onRowClick: this.handleRowClick,
                  loadMoreOnClick: this.loadMoreLevels,
                  customTableClass: 'modal-level-table',
                  isLoading,
                  searchInputOnChange: this.handleInputLevelChange,
                  searchInputValue: levelsSearchText,
                  onSortClick: this.onLevelColumnSort,
                }}
                secondTableProps={{
                  tableConfig: secondTableConfig,
                  data: components,
                  handleFormatCells: formatComponentCells,
                  sortDirection: filterProps.sortDirection,
                  sortByColumn: filterProps.sortByColumn,
                  filters: componentsFilters,
                  searchPlaceholder: t('ASSIGN_COMPONENTS.SEARCH_COMPONENTS', { currentLevel: `${levelPath[levelPath.length - 1]?.Code} ${levelPath[levelPath.length - 1]?.Name}` }),
                  noDataText: t('ASSIGN_COMPONENTS.COMPONENT_EMPTY_STATE'),
                  selectedItem: selectedComponent,
                  searchInputValue: componentsSearchText,
                  customTableClass: 'components-table',
                  searchInputOnChange: this.handleInputComponentsChange,
                  loadMoreOnClick: this.loadMoreComponents,
                  hasNext: componentsFilters[filterProps.hasNext],
                  TotalItems: componentsTotalItems,
                  isLoading: isLoadingComponents,
                  onSortClick: this.onComponentsColumnSort,
                  onRowClick: this.handleComponentClick,
                  onDropdownClick: this.addSelectedComponent,
                }}
              />
            </div>
          </div>
          <div className="component-picker-ch__container__component-info">
            <ComponentPickerCHRightSide question={question} handleDeleteComponent={handleDeleteComponent} customCloseAction={customCloseAction} />
            <ComponentPickerCHDetails initialValues={selectedComponentData} isLoading={isLoadingSelectedComponentData} buttonAction={this.addSelectedComponent} />
          </div>
        </div>
      </div>
    );
  }
}
ComponentPickerCH.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  fetchComponentHierarchyLevels: (data, callback, loadMore, componentLevels) => dispatch(fetchComponentHierarchyLevels(data, callback, loadMore, componentLevels)),
  fetchComponents: (data, callback, loadMore, components, isUnassignedLevel) => dispatch(fetchComponents(data, callback, loadMore, components, isUnassignedLevel)),
  fetchComponentDetails: (data, callback, t) => dispatch(fetchComponentDetails(data, callback, t)),
});

export default withRouter(connect(null, mapDispatchToProps)(ComponentPickerCH));
