import { augmentedRealityVideoListFields } from './augmented-reality-constant';

export const postVideoRelocalisationConfig = [
  {
    title: 'NAME',
    key: augmentedRealityVideoListFields.name,
    type: augmentedRealityVideoListFields.name,
    class: 'name-column',
    isSort: true,
  },
  {
    title: 'TABLE.CREATED_DATE_AND_TIME',
    key: augmentedRealityVideoListFields.startedAt,
    type: augmentedRealityVideoListFields.startedAt,
    class: 'created-at-and-time-column',
    isSort: true,
  },
  {
    title: 'TABLE.DURATION',
    key: augmentedRealityVideoListFields.duration,
    type: augmentedRealityVideoListFields.duration,
    class: 'duration-column',
    isSort: true,
  },
  {
    title: '',
    key: augmentedRealityVideoListFields.actions,
    type: augmentedRealityVideoListFields.actions,
    class: 'actions-column',
    isSort: false,
  },
];

export const filterProps = {
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  perPage: 'PerPage',
  lastSeen: 'LastSeen',
  searchText: 'SearchText',
  hasNext: 'HasNext',
  totalItems: 'TotalItems',
};

export const supportedUploadFileMimeTypes = {
  'video/mp4': ['.mp4', '.MP4', '.qt', '.mov', '.avi'],
};

export const sortDirections = {
  asc: 'ASC',
  desc: 'DESC',
};

export const defaultFilters = {
  [filterProps.sortByColumn]: augmentedRealityVideoListFields.createdAt,
  [filterProps.sortDirection]: sortDirections.desc,
  [filterProps.perPage]: 10,
  [filterProps.lastSeen]: 0,
  [filterProps.searchText]: '',
  [filterProps.hasNext]: false,
  [augmentedRealityVideoListFields.isPostProcessed]: true,
};
