import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { chain, debounce, find, isEmpty, isString, keys, map, reduce } from 'lodash';

import { deselectAllMeasurements, deselectAllMeasurementsTemp, selectAllMeasurements, selectAllMeasurementsTemp, toggleMeasurement, toggleMeasurementTemp } from '../../actions/action-creators';
import { updateElementGeometry } from '../../actions/inspection-actions';

import AccessRenderer from '../../../../common/access-renderer/components/access-renderer';
import Icon from '../../../../common/icon/components/icon';
import ItemsTableRenderer from '../../../../common/items-renderer/components/items-table-renderer';
import ModuleHeader from './common/module-header';
import SearchText from './common/search-text';
import VisibilityBox from './common/visibility-box';
import MeasurementsFilter from './filters/measurements-filter';
import QuickFilter from './filters/quick-filter';
import InspectionRenderer from './inspection-renderer';

import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import { routes } from '../../../../common/routes-constants';

import { defaultPagingObj, defaultSortingFilter, filterParams, formConstants, measurementIcons, sortingDirection, tableConfig } from '../../constants/measurement-constants';

import Helpers from '../../../../common/helpers';

import '../../styles/measurements-section.scss';

class Measurements extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quickFilter: map(measurementIcons[props.theme], item => {
        return {
          ...item,
          customComponent: item.icon(item.active),
        };
      }),
      pagingObject: defaultPagingObj,
      sortingFilter: defaultSortingFilter,
      advancedFilter: {},
    };
    this.searchChangeDebounced = debounce(this.getMeasurements, 300);
  }

  componentDidUpdate = prevProps => {
    const { searchText, getInspectionElementsClustered } = this.props;

    // Only update if search text changed and it's a string
    if (isString(searchText) && prevProps.searchText !== searchText) {
      getInspectionElementsClustered(null, module, searchText);
      this.setState({
        pagingObject: defaultPagingObj,
      });
      this.searchChangeDebounced();
    }
  };

  componentDidMount = () => {
    const { queryItem } = this.props;
    let params = {};

    if (queryItem) {
      params = {
        IncludedIDs: [queryItem],
      };
    }

    this.getMeasurements(params);
  };

  getMeasurements = (params, loadMore = false) => {
    const { getMeasurements, searchText, setClusteringFilters } = this.props;
    let { quickFilter, advancedFilter, pagingObject, sortingFilter } = this.state;
    //normalize quick filter
    const qFilter =
      chain(quickFilter)
        .filter(item => item.active)
        .groupBy(item => item.field)
        .reduce((obj, items, key) => {
          if (key === filterParams.typeFilter) {
            obj[key] = map(items, it => it.value).toString();
          }
          return obj;
        }, {})
        .value() || {};
    //normalize advanced filter
    const newAdvancedFilter = reduce(
      advancedFilter,
      (obj, it, key) => {
        if ([filterParams.createdByFilter].indexOf(key) > -1) {
          obj[key] = map(advancedFilter[key], ({ ID, UserID }) => ID || UserID);
        } else if ([filterParams.dateFrom].indexOf(key) > -1) {
          obj[key] = (advancedFilter[key] && Helpers.getUnixDate(new Date(advancedFilter[key]).getTime())) || null;
        } else if ([filterParams.dateTo].indexOf(key) > -1) {
          // sets end/to dates to end of day 23:59:59 to include that day in filter results
          obj[key] = (advancedFilter[key] && Helpers.getUnixDate(new Date(advancedFilter[key]).setHours(23, 59, 59))) || null;
        }
        return obj;
      },
      {}
    );

    setClusteringFilters({ ...newAdvancedFilter, ...qFilter });
    getMeasurements(
      searchText,
      { ...newAdvancedFilter, ...qFilter, ...pagingObject, ...sortingFilter, ...params },
      (arr, incomingFilter, c) => {
        this.setState({ pagingObject: { ...pagingObject, [filterParams.totalItems]: incomingFilter[filterParams.totalItems], [filterParams.hasNext]: incomingFilter[filterParams.hasNext] } });
      },
      loadMore
    );
  };

  handleQuickFilter = item => {
    const { theme } = this.props;
    //exclusive filter
    this.setState({
      quickFilter: map(measurementIcons[theme], it => {
        if (item.label === it.label) {
          it = { ...it, active: !item.active };
        } else {
          it = { ...it, active: false };
        }
        return {
          ...it,
          customComponent: item.icon(it.active),
        };
      }),
      pagingObject: defaultPagingObj,
    });
    this.searchChangeDebounced();
  };

  loadMore = () => {
    const { pagingObject } = this.state;
    const { elements } = this.props;
    this.setState({
      pagingObject: {
        ...pagingObject,
        [filterParams.lastSeen]: elements.length || 0,
      },
    });
    this.searchChangeDebounced(null, true);
  };

  onColumnSort = column => {
    const { sortingFilter } = this.state;

    this.setState({
      sortingFilter: {
        [filterParams.sortByColumn]: column,
        [filterParams.sortDirection]: sortingFilter[filterParams.sortDirection] === sortingDirection.asc ? sortingDirection.desc : sortingDirection.asc,
      },
      pagingObject: defaultPagingObj,
    });

    this.searchChangeDebounced();
  };

  openAdvancedFilter = () => {
    const { t } = this.context;
    const { setModalState } = this.props;
    const { advancedFilter } = this.state;
    const closeAction = () => setModalState({ isOpen: false });

    const modalData = {
      isOpen: true,
      type: 'none',
      title: t('ADVANCED_FILTERS.TITLE'),
      CustomContent: () => (
        <MeasurementsFilter
          submitForm={values => {
            this.setState({
              advancedFilter: values,
              pagingObject: defaultPagingObj,
            });
            closeAction();
            this.searchChangeDebounced();
          }}
          resetFilter={() => {
            this.setState({
              advancedFilter: {},
              pagingObject: defaultPagingObj,
            });
            closeAction();
            this.searchChangeDebounced();
          }}
          initialValues={advancedFilter}
          closeAction={closeAction}
        />
      ),
      customClassName: 'modal-large defects-filter',
      closeAction,
    };
    setModalState(modalData);
  };

  goToHistoryPage = queryParams => {
    const path = `${routes.protectedRoutes.modulesHistory.fullPath}${queryParams}`;
    Helpers.goTo(path);
  };

  render() {
    const { t } = this.context;
    const { quickFilter, pagingObject, sortingFilter, advancedFilter } = this.state;
    const {
      elements,
      deselectAllMeasurements,
      selectAllMeasurements,
      toggleMeasurement,
      deselectAllMeasurementsTemp,
      selectAllMeasurementsTemp,
      toggleMeasurementTemp,
      updateGeometry,
      scrollToElement,
      generateCustomIdentificator,
      measurementsLoading,
      handleItemClick,
      queryItem,
      title,
      icon,
      backAction,
      viewer,
    } = this.props;

    const selectedMeasurement = find(elements, item => item.ID === queryItem);

    return (
      <AccessRenderer
        visibleFor={PERMISSIONS[PERMISSION_TYPES.measurements].edit.name}
        id={selectedMeasurement && selectedMeasurement[formConstants.fields.loggedByUserID]}
        ownerRequiredPermission={PERMISSIONS[PERMISSION_TYPES.measurements].create.name}
      >
        {({ hasAccess }) => {
          return (
            <InspectionRenderer
              deselectAll={deselectAllMeasurements}
              selectAll={selectAllMeasurements}
              toggleElement={toggleMeasurement}
              deselectAllTemp={deselectAllMeasurementsTemp}
              selectAllTemp={selectAllMeasurementsTemp}
              toggleElementTemp={toggleMeasurementTemp}
              selectElement={(data, autoScroll) => {
                handleItemClick(data.ID);
                if (autoScroll) {
                  scrollToElement(data);
                }
              }}
              queryItem={queryItem}
              selectedDefect={selectedMeasurement}
              updateElementGeometry={(...args) => (hasAccess ? updateGeometry(...args) : null)}
              elements={elements}
              disableMove={!hasAccess}
              viewer={viewer}
            >
              {({ elementClickHandler, elementShowHandler, elementHideHandler, selectAllHandler, deselectAllHandler }) => (
                <div className="measurements-section">
                  <ModuleHeader
                    {...{
                      title,
                      icon,
                      backAction,
                      actionsMenu: [],
                    }}
                  />
                  <div className="advanced-filter">
                    <SearchText />
                    <p className="f-secondary-green noselect pointer link" onClick={this.openAdvancedFilter}>
                      {t('ADVANCED_FILTER', { active: keys(advancedFilter).length })}
                    </p>
                  </div>

                  <QuickFilter items={quickFilter} onClick={this.handleQuickFilter} />

                  <VisibilityBox
                    {...{
                      selectAllHandler,
                      deselectAllHandler,
                    }}
                  />

                  <ItemsTableRenderer
                    isLoading={measurementsLoading}
                    tableConfig={tableConfig}
                    translationModule={t}
                    data={map(elements, item => ({ ...item, selected: item[formConstants.fields.id] === queryItem ? true : false }))}
                    onRowClick={elementClickHandler}
                    loadMore={{
                      visible: true,
                      disabled: !pagingObject[filterParams.hasNext] || measurementsLoading,
                      onClick: this.loadMore,
                    }}
                    sortingObj={sortingFilter}
                    onColumnSort={this.onColumnSort}
                    formatCell={(value, type, index, item) => {
                      if (type === formConstants.fields.status) {
                        return (
                          <>
                            {!item.Geometry ||
                              isEmpty(item.Geometry) ||
                              (isEmpty(item.Geometry.coordinates) && (
                                <div className="missing-pin">
                                  <Icon name="warning" warning handleHover={false} size="md" title={t('ITEMS_LIST.WARNING_ICON_TEXT')} />
                                </div>
                              ))}

                            <Icon
                              name="eye"
                              active={item.visible}
                              size="xs"
                              onClick={item.visible ? e => elementHideHandler(e, item) : e => elementShowHandler(e, item)}
                              title={t('ITEMS_LIST.VISIBILTY_ICON')}
                            />
                          </>
                        );
                      }

                      return value;
                    }}
                    emptyStateText={'INSPECTION_MEASUREMENTS.EMPTY_STATE'}
                    customIdentificatorFunction={generateCustomIdentificator}
                  />
                  {pagingObject[filterParams.totalItems] > 0 && (
                    <p className="f-secondary-dark total">
                      {t('SHOWING_TOTAL_ITEMS', {
                        items: elements?.length || 0,
                        totalItems: pagingObject[filterParams.totalItems],
                      })}
                    </p>
                  )}
                </div>
              )}
            </InspectionRenderer>
          );
        }}
      </AccessRenderer>
    );
  }
}

const mapStateToProps = state => ({
  theme: state.userReducer.Theme,
  viewer: state.potreeReducer.viewerInstance,
});

const mapDispatchToProps = dispatch => ({
  deselectAllMeasurements: (type, persistState) => dispatch(deselectAllMeasurements({ type, persistState })),
  selectAllMeasurements: (type, persistState) => dispatch(selectAllMeasurements({ type, persistState })),
  toggleMeasurement: id => dispatch(toggleMeasurement(id)),
  // TEMP
  deselectAllMeasurementsTemp: (type, persistState) => dispatch(deselectAllMeasurementsTemp({ type, persistState })),
  selectAllMeasurementsTemp: (type, persistState) => dispatch(selectAllMeasurementsTemp({ type, persistState })),
  toggleMeasurementTemp: id => dispatch(toggleMeasurementTemp(id)),
  //
  updateGeometry: data => dispatch(updateElementGeometry(data)),
});

Measurements.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Measurements));
