import PropTypes from 'prop-types';
import { useState } from 'react';
import BackButton from '../../../../../common/back-button/components/back-button';
import Toggle from '../../../../../common/form/components/toggle-field';
import Icon from '../../../../../common/icon/components/icon';
import Modal from '../../../../../common/modal/components/modal';
import { SpotStatistics } from '../../../../../common/spot-statistics/components/spot-statistics';
import VideoPlayer from '../../../../../common/video-player/video-player';
import StreamDetailsForm from './stream-details-form';

import '.././../../styles/stream-view.scss';

const StreamView = ({ navigationBackButtonPath }, { t }) => {
  const [modalData, setModalData] = useState({ isOpen: false });
  const isLocalised = false;
  const localisationBadgeText = isLocalised ? t('LOCALISED') : t('NOT_LOCALISED');
  const localisationBadgeIcon = isLocalised ? 'green-check' : 'alert-octagon';

  const handleEditDetailsModal = () => {
    // TODO: Implement edit action
    const close = () => {
      setModalData({ isOpen: false });
    };
    setModalData({
      isOpen: true,
      customClassName: 'stream-edit-modal modal-large uplift-modal',
      customClassWrapperName: 'stream-edit-modal__wrapper',
      type: '',
      CustomContent: dynamicProps => <StreamDetailsForm {...dynamicProps} />,
      closeAction: close,
      title: t('EDIT_VIDEO_DETAILS'),
      customCloseAction: close,
      onSubmit: data => close(),
    });
  };

  return (
    <>
      <div className="stream-view">
        <BackButton path={navigationBackButtonPath} />
        <div className="stream-view__title-section__top">
          <div className="flex-center left">
            <h3>New Stream</h3>
            <div className="chip">
              <div className="red-dot" />
              {t('LIVE')}
            </div>
          </div>
          <div className="flex-center right">
            <div className="localisation-badge">
              <Icon name={localisationBadgeIcon} size="sm" handleHover={false} />
              <p>{localisationBadgeText}</p>
            </div>
            <Icon size="md" name="pen" className="delete-action" onClick={handleEditDetailsModal} />
          </div>
        </div>
        <div className="video-wrapper">
          <div className="stream-view__title-section__bottom">
            <Toggle className={'show-tags'} rightValue={t('SHOW_TAGS_ON_VIDEO')} width={28} height={16} handleDiameter={13.5} input={{ value: true, onChange: value => console.log(value) }} />
            {/* TODO: Change FOV - if needed will be here */}
          </div>
          {/* TODO: Replace with valid stream URL */}
          <VideoPlayer className="stream-video" src="..." />
          <SpotStatistics />
        </div>
      </div>

      <Modal {...modalData} />
    </>
  );
};

StreamView.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default StreamView;
