import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { Field, getFormSyncErrors, isDirty, reduxForm } from 'redux-form';

import { FORMS } from '../../../../../common/constants';
import Button from '../../../../../common/form/components/button';
import CustomInput from '../../../../../common/form/components/input';

import '../../../styles/stream-details-form.scss';

import { augmentedRealityVideoListFields } from '../../../constants/augmented-reality-constant';
import { validateStreamDetails as validate } from '../../../validators/stream-details-validator';

const StreamDetailsForm = (props, { t }) => {
  const { handleSubmit, formErrors, isDirty } = props;

  const isSubmitButtonDisabled = useMemo(() => !isEmpty(formErrors), [formErrors]);

  return (
    <form noValidate className="stream-details-form" onSubmit={handleSubmit}>
      <Field
        id={augmentedRealityVideoListFields.name}
        name={augmentedRealityVideoListFields.name}
        component={CustomInput}
        size="lg"
        isRequired
        placeholder={t('VIDEO_NAME')}
        label={t('VIDEO_NAME')}
        customClassName="name-field"
      />
      <Button text={t('SAVE')} height="md" width="lg" isCapitalized disabled={isSubmitButtonDisabled || !isDirty} />
    </form>
  );
};

StreamDetailsForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  formErrors: getFormSyncErrors(FORMS.streamDetailsForm)(state),
  isDirty: isDirty(FORMS.streamDetailsForm)(state),
});

export default connect(mapStateToProps, null)(reduxForm({ form: FORMS.streamDetailsForm, validate, touchOnChange: true, destroyOnUnmount: true, enableReinitialize: true })(StreamDetailsForm));
