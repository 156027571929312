import axios from 'axios';
import { pick } from 'lodash';
import { updateSyncErrors } from 'redux-form';
import readingsAndGaugesAPI from '../../../../../api/readings-and-gauges/actions';
import { FORMS } from '../../../../../common/constants';
import Helpers from '../../../../../common/helpers';
import { dispatchErrorModalWired } from '../../../../../common/modal/actions/modal-actions';
import { measurementTypes } from '../../../constants/constants';
import { formConstants as inspectionSettingsConstants } from '../../../constants/inspection-settings';
import { deleteWithModalFields, fields } from '../constants/constants';
import { setMeasurementGroupsClustered } from './action-creators';

let cancelTokens = {
  measurementGroupsClustered: undefined,
};

export const fetchGroups = (filters, callback, filtersCallback, errorCallback, loadingCallback) => {
  return async () => {
    try {
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(true);
      const res = await readingsAndGaugesAPI.fetchGroups(filters);
      const { Items, ...newFilters } = res.data.Data;
      callback && typeof callback === 'function' && callback(Items);
      filtersCallback && typeof filtersCallback === 'function' && filtersCallback({ ...filters, ...newFilters });
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(false);
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback();
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(false);
    }
  };
};

export const createGroup = (Name, ProjectID, InspectionID, callback) => {
  return async dispatch => {
    try {
      const res = await readingsAndGaugesAPI.createGroup({ Name, ProjectID, InspectionID });
      const { Data } = res.data;
      callback && typeof callback === 'function' && callback(Data);
    } catch (e) {
      const customError = Helpers.getErrorContent(e);
      if (customError) {
        dispatch(
          updateSyncErrors(FORMS.groupForm, {
            [fields.name]: customError,
          })
        );
      } else {
        console.error(e);
        dispatchErrorModalWired(true, e);
      }
    }
  };
};

export const editGroup = (Name, ID, ProjectID, callback) => {
  return async dispatch => {
    try {
      const res = await readingsAndGaugesAPI.editGroup({ Name, ID, ProjectID });
      const { Data } = res.data;
      callback && typeof callback === 'function' && callback(Data);
    } catch (e) {
      const customError = Helpers.getErrorContent(e);
      if (customError) {
        dispatch(
          updateSyncErrors(FORMS.groupForm, {
            [fields.name]: customError,
          })
        );
      } else {
        console.error(e);
        dispatchErrorModalWired(true, e);
      }
    }
  };
};

export const deleteGroup = (ID, MovePointsToGroupID, ConfirmationCheck, DeleteAll, callback) => {
  return async dispatch => {
    try {
      await readingsAndGaugesAPI.deleteGroup({ ID, MovePointsToGroupID, ConfirmationCheck, DeleteAll });
      callback && typeof callback === 'function' && callback();
    } catch (e) {
      const customError = Helpers.getErrorContent(e);
      if (customError) {
        dispatch(
          updateSyncErrors(FORMS.deleteWithModalForm, {
            [deleteWithModalFields.field.name]: customError,
          })
        );
      } else {
        console.error(e);
        dispatchErrorModalWired(true, e);
      }
    }
  };
};

export const getMeasurementGroupsClustered = (inspection_id, _activeLeftSidebar, searchText = '', optionalParams = {}, callback) => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { inspectionSettings },
    } = getState();
    const clusterConfiguration = pick(inspectionSettings, [
      inspectionSettingsConstants.fields.numberOfRings,
      inspectionSettingsConstants.fields.numberOfSlices,
      inspectionSettingsConstants.fields.initialDistance,
      inspectionSettingsConstants.fields.multiplier,
    ]);
    try {
      //Check if there are any previous pending requests
      if (typeof cancelTokens.measurementGroupsClustered != typeof undefined) {
        cancelTokens.measurementGroupsClustered.cancel('Operation canceled due to new request.');
      }

      //Save the cancel token for the current request
      cancelTokens.measurementGroupsClustered = axios.CancelToken.source();

      const res = await readingsAndGaugesAPI.getMeasurementLocationsClustered(
        {
          InspectionID: parseInt(inspection_id),
          SearchText: searchText,
          SystemType: measurementTypes.rgMeasurementLocation,
          ...clusterConfiguration,
          ...optionalParams,
        },
        { cancelToken: cancelTokens.measurementGroupsClustered.token }
      );
      const { Data } = res.data;

      dispatch(
        setMeasurementGroupsClustered(
          (Data.Items || []).map((el, index) => ({
            ...el,
            ID: el.ID > 0 ? el.ID : `CLUSTER_${Date.now()}_${index}`,
          }))
        )
      );
      callback &&
        callback(
          (Data.Items || []).map((el, index) => ({
            ...el,
            ID: el.ID > 0 ? el.ID : `CLUSTER_${Date.now()}_${index}`,
          }))
        );
    } catch (e) {
      console.error(e);
    }
  };
};

export const generateAndDownloadReport = (data, callback, errorCallback, loadingCallback) => {
  return async () => {
    try {
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(true);
      await readingsAndGaugesAPI.generateAndDownloadReport(data);

      // callback used for displaying the success generic notification to the user
      callback && typeof callback === 'function' && callback();
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(false);
    } catch (e) {
      console.error(e);
      // callback used for displaying the error generic notification to the user
      errorCallback && typeof errorCallback === 'function' && errorCallback();
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(false);
    }
  };
};

export const fetchUnits = (filters, callback, filtersCallback, errorCallback, loadingCallback) => {
  return async () => {
    try {
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(true);
      const res = await readingsAndGaugesAPI.fetchUnits(filters);
      const { Items, ...newFilters } = res.data.Data;
      callback && typeof callback === 'function' && callback(Items);
      filtersCallback && typeof filtersCallback === 'function' && filtersCallback({ ...filters, ...newFilters });
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(false);
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback();
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(false);
    }
  };
};

// Checklist and Procedure template actions
export const fetchChecklistProceduresMeasurementGroups = (filters, dataCallback, loadingCallback, errorCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      const res = await readingsAndGaugesAPI.fetchChecklistProcedureMeasurementGroups(filters);
      const { Data } = res?.data;
      const { Items, ...rest } = Data;
      dataCallback && typeof dataCallback === 'function' && dataCallback(Items, { ...filters, ...rest });
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const fetchMeasurementReadingHierarchy = (filters, successCallback, loadingCallback, errorCallback) => {
  return async () => {
    try {
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(true);
      const res = await readingsAndGaugesAPI.fetchMeasurementReadingHierarchy(filters);
      const { Data } = res.data;
      successCallback && typeof successCallback === 'function' && successCallback(Data);
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(false);
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback();
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(false);
    }
  };
};
