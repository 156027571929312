import { augmentedRealityVideoListFields, MAX_STREAM_NAME_LENGTH, MIN_STREAM_NAME_LENGTH } from '../constants/augmented-reality-constant';

export const validateStreamDetails = values => {
  const errors = {};
  const streamName = values[augmentedRealityVideoListFields.name];

  if (!streamName) {
    errors[augmentedRealityVideoListFields.name] = 'STREAM_DETAILS.STREAM_NAME_REQUIRED';
  } else {
    if (streamName.length > MAX_STREAM_NAME_LENGTH) {
      errors[augmentedRealityVideoListFields.name] = `STREAM_DETAILS.STREAM_NAME_MIN`;
    }

    if (streamName.length < MIN_STREAM_NAME_LENGTH) {
      errors[augmentedRealityVideoListFields.name] = `STREAM_DETAILS.STREAM_NAME_MAX`;
    }
  }

  return errors;
};
