/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { reduxForm } from 'redux-form';

import { isEmpty } from 'lodash';
import { FORMS } from '../../../../../../common/constants';
import Button from '../../../../../../common/form/components/button';

import { fields } from '../../constants/constants';
import { measurementReadingFormSteps } from '../../constants/measurement-readings-constants';
import '../../styles/measurement-reading-form.scss';
import { measurementReadingValidate } from '../../validators/measurement-reading-validator';
import MeasurementReadingFormDetails from './measurement-reading-form-details';
import MeasurementReadingFormFiles from './measurement-reading-form-files';

const CreateMeasurementReadingForm = (props, { t }) => {
  const {
    onSubmit,
    formErrors,
    isEdit,
    customCloseAction,
    measurementPointId,
    formValues,
    requestInProgress = false,
    activeStep,
    activeStepObj,
    getDefectDetails,
    changeField,
    defaultReadingsAndGaugesUnassignedDMSFolderID,
    setStep,
    readingValuesData,
    getMeasurementReadingDMSFilesUploaded,
    setMeasurementReadingFilesUploaded,
    measuredById,
    user,
  } = props;

  const isSubmitButtonDisabled = useMemo(() => {
    // TODO: maybe even disable the button if the form isPristine as well
    if (!isEdit) {
      return formValues?.Values?.some(value => isEmpty(value) || !value) || !isEmpty(formErrors);
    }

    return !isEmpty(formErrors);
  }, [formErrors, formValues]);

  return (
    <form
      className="create-measurement-reading-form"
      onSubmit={e => {
        e?.preventDefault();
        e?.stopPropagation();
        onSubmit(formValues);
      }}
      noValidate
    >
      {activeStep === measurementReadingFormSteps.firstStep && (
        <MeasurementReadingFormDetails
          isEdit={readingValuesData ? !isEmpty(readingValuesData) : isEdit ? true : false}
          measurementPointId={measurementPointId}
          formValues={formValues}
          activeStepObj={activeStepObj}
          getDefectDetails={getDefectDetails}
          changeField={changeField}
        />
      )}
      {activeStep === measurementReadingFormSteps.secondStep && !isEmpty(readingValuesData) && (
        <MeasurementReadingFormFiles
          readingValuesData={readingValuesData}
          defaultReadingsAndGaugesUnassignedDMSFolderID={defaultReadingsAndGaugesUnassignedDMSFolderID}
          fetchMeasurementReadingUploadedFiles={getMeasurementReadingDMSFilesUploaded}
          setMeasurementReadingFilesUploaded={setMeasurementReadingFilesUploaded}
          measuredById={!isEdit ? user[fields.userId] : measuredById}
        />
      )}
      <div className="buttons" id="action-buttons">
        <Button
          onClick={e => {
            e.preventDefault();
            activeStep === measurementReadingFormSteps.secondStep ? setStep(false) : customCloseAction();
          }}
          variant="gray-outline"
          text={activeStep === measurementReadingFormSteps.secondStep ? t('BACK') : t('CANCEL')}
          height="md"
          width="sm"
          isCapitalized
        />
        <Button
          onClick={activeStep === measurementReadingFormSteps.firstStep ? () => onSubmit(formValues) : () => setStep(true)}
          text={activeStep === measurementReadingFormSteps.secondStep ? t('CONFIRM') : t('SAVE')}
          height="md"
          width="sm"
          isCapitalized
          disabled={isSubmitButtonDisabled || requestInProgress}
        />
      </div>
    </form>
  );
};

CreateMeasurementReadingForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default reduxForm({
  form: FORMS.measurementPointMeasurementReadingForm,
  enableReinitialize: true,
  touchOnChange: true,
  validate: measurementReadingValidate,
})(CreateMeasurementReadingForm);
