import PropTypes from 'prop-types';
import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { destroy, reset } from 'redux-form';

import { cloneDeep, debounce, find, isEqual, some, values } from 'lodash';

import Helpers from '../../../../common/helpers';

import DisplayStatus from '../../../../common/display-status/components/display-status';
import Icon from '../../../../common/icon/components/icon';
import SearchInput from '../../../../common/input/components/search-input';
import LoadMore from '../../../../common/load-more/components/load-more';
import Modal from '../../../../common/modal/components/modal';
import PageNavigation from '../../../../common/page-navigation/components/page-navigation';
import { ComponentPicker3D, ComponentPickerCH, ComponentPickerPDF } from '../../../../common/question-components/components/index';
import RenderIf from '../../../../common/render-if/components/render-if';
import TableComponent from '../../../../common/table/components/table-component';
import InfoTooltip from '../../../../common/tooltip/components/info-tooltip';
import MultiplePreviewPopup from '../../../../common/tooltip/components/multiple-preview-popover';
import CollapsibleToolbar from '../../../inspections/components/collapsible-toolbar';
import ComponentPDF from '../../../inspections/components/right-toolbar/component-pdf';
import UploadProjectAssetsModal from '../../../project/components/upload-assets-modal';
import CreateRegimeModal from './create-regime-modal';

import { notificationModalCustom } from '../../../../common/modal/actions/modal-actions';
import { placements } from '../../../inspections/constants/constants';
import {
  addComponentToRegime,
  changeStatus,
  clearRegimeModalData,
  createRegime,
  deleteRegime,
  duplicateRegime,
  generateAndDownloadRegimeChecklistTemplatePDF,
  getIntervalList,
  getRegimeChecklists,
  getRegimeChecklistTemplateSectionQuestions,
  getRegimeChecklistTemplateSections,
  getRegimeComponents,
  getRegimeDetails,
  getRegimeFiles,
  getRegimes,
  removeComponentFromRegime,
  saveChecklists,
  searchChecklists,
  searchComponents,
  unlinkRegimeFile,
  updateInterval,
  updateRegime,
} from '../../actions/regime-actions';

import { setSingleUploadItem, setUploadItems, uploadAsset } from '../../../upload/actions/upload-actions';

import { FORMS } from '../../../../common/constants';
import { PERMISSION_TYPES, PERMISSIONS } from '../../../../common/permissions-constants';
import { routes } from '../../../../common/routes-constants';
import { priorities } from '../../../inspections/constants/work-order-constants';
import { defaultFilter as checklistFilter } from '../../constants/checklist-constants';
import constants, { columnTypes } from '../../constants/constants';
import {
  componentFields,
  confirmationModalContent,
  confirmationModalTypes,
  defaultFilter,
  fileGroups,
  fileTypes,
  filterFields,
  formConstants,
  imagesValidation,
  sortDirection,
  statuses,
  steps,
  tableIntervalCombineKey,
} from '../../constants/regime-constants';

import { withRouter } from 'react-router';
import Button from '../../../../common/form/components/button';
import { setGenericNotification } from '../../../../common/notification/actions/action-creators';
import { useFileOpener } from '../../../../hooks/use-file-opener';
import { closeInspectionModal, maximizeInspectionModal, minimizeInspectionModal, openAsSeparatePageInspectionModal, toggleInspectionModal } from '../../../inspections/actions/inspection-actions';
import { getAdditionalWorkorderTypes, getWorkOrderChecklistSectionQuestionDetails, singleItemUploadDone } from '../../../inspections/actions/work-order-actions';
import AddCPTemplates from '../../../inspections/components/work-order/add-cp-templates';
import { checklistFields } from '../../../inspections/components/work-order/constants/constants';
import WorkOrderSectionChecklistDetails from '../../../inspections/components/work-order/work-order-section-checklist-details';
import { setPdfComponents } from '../../../pdf-tag/actions/action-creators';
import { changePDFPage, getPdfComponentsForPopup } from '../../../pdf-tag/actions/pdf-tag-actions';
import {
  addRegimeChecklist,
  linkRegimeCPTemplateToComponents,
  removeRegimeChecklistByProp,
  removeRegimeComponentFromCPTemplate,
  setRegimeChecklists,
  unlinkRegimeCPTemplateFromComponents,
} from '../../actions/action-creators';
import '../../styles/maintenance-regime-table.scss';
import MaintenanceRegimeRightSide from './maintenance-regime-right-side';

const withFileOpener = WrappedComponent => {
  const WithFileOpenerComponent = (props, context) => {
    const [modalData, setModalData] = useState({ isOpen: false });

    const { openFile } = useFileOpener({
      inspectionId: props.inspectionId,
      getPdfComponentsForPopup: props.getPdfComponentsForPopup,
      setPdfComponents: props.setPdfComponents,
      modalData,
      setModalData,
      t: context.t,
      toggleInspectionModal: props.toggleInspectionModal,
      inspectionModalData: props.inspectionModalData,
      closeInspectionModal: props.closeInspectionModal,
      minimizeInspectionModal: props.minimizeInspectionModal,
      maximizeInspectionModal: props.maximizeInspectionModal,
      openAsSeparatePageInspectionModal: props.openAsSeparatePageInspectionModal,
      changePDFPage: props.changePDFPage,
      isLoadedFromNonInspectionModule: true,
      pdfPageNumber: props.pdfPageNumber,
    });

    return <WrappedComponent {...props} openFile={openFile} fileOpenerModalData={modalData} />;
  };

  WithFileOpenerComponent.contextTypes = {
    t: PropTypes.func.isRequired,
  };

  const mapStateToProps = state => ({
    pdfPageNumber: state.pdfTagReducer.pdfPageNumber,
  });

  const mapDispatchToProps = {
    getPdfComponentsForPopup,
    setPdfComponents,
    toggleInspectionModal,
    closeInspectionModal,
    minimizeInspectionModal,
    maximizeInspectionModal,
    openAsSeparatePageInspectionModal,
    changePDFPage,
  };

  return connect(mapStateToProps, mapDispatchToProps)(WithFileOpenerComponent);
};
class RegimeTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalData: {
        isOpen: false,
      },
      uploadModalData: {
        isOpen: false,
      },
      filesModalData: {
        isOpen: false,
      },
      confirmModalData: {
        isOpen: false,
      },
      checklistModalData: {
        isOpen: false,
      },
      warningModalData: {
        isOpen: false,
      },
      advancedFiltersModalData: {
        isOpen: false,
        // closeAction: this.closeModal.bind(this),
      },
      filter: {
        ...defaultFilter,
        [filterFields.inspectionID]: props.inspectionId,
      },
      componentSelectionModal: {
        isOpen: false,
      },
      addCPTemplatesModal: {
        isOpen: false,
      },
      checklists: [],
      isInitialFetchDone: false,
      rightCollapsed: true,
      closeRegimeModalTriggered: false,
      additionalWorkorderTypes: [],
    };
    this.searchChangeDebounced = debounce(this.search, 500);
    this.searchChecklistsDebounced = debounce(this.searchChecklists, 500);
  }

  componentDidMount = () => {
    const { filter } = this.state;
    const { getRegimes, selected_item, clearRegimeModalData, location, getAdditionalWorkorderTypes } = this.props;
    const { query } = location;
    let additionalFilter = {};
    clearRegimeModalData();

    getAdditionalWorkorderTypes(additionalWorkorderTypes => this.setState({ additionalWorkorderTypes }));

    if (selected_item) {
      additionalFilter = {
        includedIDs: [selected_item],
      };
    }
    getRegimes({ ...filter, ...additionalFilter }, newFilter => {
      this.setState({ isInitialFetchDone: true, filter: { ...filter, ...newFilter, [filterFields.lastSeen]: defaultFilter[filterFields.lastSeen] } });
    });

    if (query.selected_item) {
      this.setState({ rightCollapsed: false });
    }
  };

  componentDidUpdate = prevProps => {
    const { selected_item } = this.props;
    const { modalData } = this.state;
    if (prevProps.selected_item !== selected_item && !selected_item && modalData.isOpen) {
      this.setState({
        modalData: {
          isOpen: false,
        },
      });
    }
  };

  toggleToolbar = () => {
    const { rightCollapsed } = this.state;
    const { clearRegimeModalData } = this.props;
    if (!rightCollapsed) {
      clearRegimeModalData();
    }
    this.setState({ rightCollapsed: !rightCollapsed });
  };

  componentWillUnmount() {
    this.searchChangeDebounced.cancel();
  }

  handleInputChange = e => {
    this.searchChangeDebounced(e.target.value);
  };

  onRowClick = (_e, item) => {
    const { getIntervalList } = this.props;
    const { query } = this.props.location;
    Helpers.goTo(routes.protectedRoutes.maintenanceRegime.fullPath, [{ project_id: query.project_id }, { inspection_id: query.inspection_id }, { selected_item: item.ID }]);
    getIntervalList();
    setTimeout(() => {
      this.setState({ rightCollapsed: false });
    }, 500);
  };

  search = SearchText => {
    const { filter } = this.state;
    const { getRegimes } = this.props;
    getRegimes({ ...filter, [filterFields.lastSeen]: defaultFilter[filterFields.lastSeen], SearchText }, newFilter => {
      this.setState({ filter: { ...filter, ...newFilter, [filterFields.lastSeen]: defaultFilter[filterFields.lastSeen], [filterFields.searchText]: SearchText } });
    });
  };

  onColumnSort = SortByColumn => {
    const { filter } = this.state;
    const { getRegimes } = this.props;

    const newParams = {
      ...filter,
      [filterFields.sortByColumn]: SortByColumn,
      [filterFields.sortByDirection]: filter[filterFields.sortByDirection] === sortDirection.asc ? sortDirection.desc : sortDirection.asc,
      [filterFields.lastSeen]: defaultFilter[filterFields.lastSeen],
    };

    getRegimes(newParams, newFilter => {
      this.setState({ filter: { ...filter, ...newParams, ...newFilter, [filterFields.lastSeen]: defaultFilter[filterFields.lastSeen] } });
    });
  };

  loadMore = () => {
    const { getRegimes, regimeList } = this.props;
    const { filter } = this.state;
    const newParams = { ...filter, LastSeen: filter[filterFields.lastSeen] + defaultFilter[filterFields.perPage] };

    getRegimes(
      newParams,
      incomingParams => {
        this.setState({ filter: { ...newParams, [filterFields.hasNext]: incomingParams[filterFields.hasNext] } });
        Helpers.scrollIntoView('maintenance-regime-table', `row-${regimeList.length - 1}`);
      },
      true
    );
  };

  openRegimeEditModal = (regime, previewMode, requestedStep) => {
    const { getRegimeDetails, clearRegimeModalData, severityColors, getIntervalList } = this.props;
    const { additionalWorkorderTypes } = this.state;
    const { t } = this.context;

    const closeAction = step => {
      const { resetForm, destroyForm, selectedRegime } = this.props;

      resetForm(FORMS.createRegimeForm);
      destroyForm(FORMS.createRegimeForm);

      this.setState({ modalData: { isOpen: false } });

      // clearRegimeModalData();
      if (step && typeof step === 'number' && parseInt(step, 10) > 1) {
        // close action is triggered after 1st step, force the re-render of maintenance-regime-right-side.js local state
        this.setState({
          closeRegimeModalTriggered: true,
        });
        setTimeout(() => {
          this.setState({
            closeRegimeModalTriggered: false,
          });
        }, 100);
        if (parseInt(step, 10) > steps.fifthStep) {
          // if form completed, force the opening of right slider
          this.onRowClick(null, selectedRegime);
        }
      }
    };

    const openAction = (isCreate = false) => {
      // if create new form action is triggered, clean the regime modal state
      if (isCreate) clearRegimeModalData();

      // TODO: refactor this, no need to fetch interval list on every opening of popup
      getIntervalList(() => {
        this.setState({
          modalData: {
            isOpen: true,
            title: isCreate ? t('REGIME_MODAL.CREATE_TITLE') : t(`CREATE_REGIME_POPUP.STEP_${requestedStep || 1}`),
            CustomContent: dynamicProps => (
              <CreateRegimeModal
                {...dynamicProps}
                isPreviewMode={previewMode}
                closeAction={closeAction}
                severityColors={severityColors}
                requestedStep={requestedStep}
                isCreate={isCreate}
                additionalWorkorderTypes={additionalWorkorderTypes}
              />
            ),
            customClassName: 'modal-no-max-height modal-large create-regime-modal',
            type: 'none',
            closeAction,
          },
        });
      });
    };
    //for edit mode fetch details
    if (regime && regime[formConstants.fields.id]) {
      getRegimeDetails(regime[formConstants.fields.id], Data => {
        if (Data && Data[formConstants.fields.id] > 0) {
          openAction(false);
        }
      });
    } else {
      //for create mode open instant
      openAction(true);
    }
  };

  duplicateRegime = async (data, cb) => {
    const { duplicateRegime, inspectionId, projectId } = this.props;

    await duplicateRegime(data[formConstants.fields.id], newRegime =>
      Helpers.goTo(routes.protectedRoutes.maintenanceRegime.fullPath, [{ project_id: projectId }, { inspection_id: inspectionId }, { selected_item: newRegime[formConstants.fields.id] }])
    );
    cb && cb();
  };

  isValid = (items, files) => {
    const { notificationModalCustom } = this.props;

    const allFiles = items.concat(files);

    if (allFiles && allFiles.length > imagesValidation.maxFiles) {
      notificationModalCustom(
        {
          key: 'WORK_ORDER_CREATE_VALIDATION.VALIDATION_MAX_FILES',
          props: { maxFiles: imagesValidation.maxFiles, current: files && files.length ? files.length : 0 },
        },
        'WORK_ORDER_CREATE_VALIDATION.VALIDATION_FILE_SIZE_TITLE'
      );

      return false;
    }

    const hasLargeFiles = some(items, item => item.size > imagesValidation.bytes);

    if (hasLargeFiles) {
      //open modal with message
      notificationModalCustom(
        {
          key: 'WORK_ORDER_CREATE_VALIDATION.VALIDATION_FILE_SIZE',
          props: { fileSize: `${imagesValidation.value}${imagesValidation.unit}` },
        },
        'WORK_ORDER_CREATE_VALIDATION.VALIDATION_FILE_SIZE_TITLE'
      );
      return false;
    }

    return true;
  };

  onDropAsset = (files, UploadGroup) => {
    const { uploadAsset, setUploadItems, setSingleUploadItem, singleItemUploadDone, inspectionId, projectId, selectedRegime, regimeFiles, getRegimeFiles } = this.props;

    if (!this.isValid(files, Object.assign([], regimeFiles && regimeFiles[UploadGroup] ? regimeFiles[UploadGroup] : []))) {
      return false;
    }

    const tmp_files = files.map((file, index) => {
      const tmp_file = {
        type: Helpers.getFileType(file.type),
        mimeType: file.type,
        title: '',
        description: '',
        real_name: Helpers.formatFileName(file.name),
      };
      file.timeStamp = Date.now();
      file.real_name = tmp_file.real_name;
      file.uploadType = fileTypes.regimeFiles;

      uploadAsset(file, { ProjectID: projectId, InspectionID: inspectionId, UploadGroup, MaintenanceRegimeID: selectedRegime[formConstants.fields.id] }, setSingleUploadItem, index, (i, url) => {
        singleItemUploadDone(url, _file => {
          getRegimeFiles(selectedRegime[formConstants.fields.id], UploadGroup);
        });
      });
      return { ...tmp_file, ...file };
    });
    setUploadItems(tmp_files);
  };

  handleFileOpen = (file, imageFiles) => {
    const { inspectionId } = this.props;

    this.props.openFile(file, imageFiles, undefined, props => (
      <ComponentPDF {...props} file={file} selectComponent={() => null} rightCollapsed={true} inspectionId={inspectionId} showOnlyConfirmedDrawings={true} />
    ));
  };

  openFilesModalData = (uploadGroup, onModalClose) => {
    const { t } = this.context;
    const { setUploadItems } = this.props;

    const confirmAction = () => {
      setUploadItems([]);
      onModalClose();
      this.setState({
        uploadModalData: {
          isOpen: false,
        },
      });
    };
    this.setState(prevState => ({
      uploadModalData: {
        ...prevState.uploadModalData,
        title: t('REGIME_FILES_UPLOAD_MODAL.TITLE'),
        customClassName: 'project-assets-modal modal-small',
        CustomContent: () => (
          <UploadProjectAssetsModal
            onDrop={files => this.onDropAsset(files, uploadGroup || fileGroups.otherFiles)}
            className="project-dropzone"
            labelWhite={t('REGIME_FILES_UPLOAD_MODAL.TITLE')}
            labelGreen={t('REGIME_FILES_UPLOAD_MODAL.BROWSE')}
          />
        ),
        isOpen: true,
        type: 'ok',
        confirmAction: confirmAction,
        closeAction: confirmAction,
      },
    }));
  };

  closeConfirmModal = () =>
    this.setState({
      confirmModalData: {
        isOpen: false,
      },
    });

  setConfirmModalData = (confirmAction, content) => {
    const { t } = this.context;
    this.setState({
      confirmModalData: {
        isOpen: true,
        title: t('CREATE_REGIME_FORM.DELETE_POPUP_TITLE'),
        content,
        type: 'yes-no',
        confirmAction: () => {
          confirmAction();
          this.closeConfirmModal();
        },
        closeAction: this.closeConfirmModal,
      },
    });
  };

  setAdvancedFiltersModalData = advancedFiltersModalData => {
    this.setState({ advancedFiltersModalData });
  };

  checklistDeleteCheck = (group, callback) => {
    const { t } = this.context;
    const { regimeFiles, regimeChecklists = [] } = this.props;
    if (group === fileGroups.cheklistFiles) {
      const checklistFiles = regimeFiles[fileGroups.cheklistFiles] || [];
      if (checklistFiles.length + regimeChecklists.length === 1) {
        const closeAction = () => this.setState({ warningModalData: { isOpen: false } });

        this.setState({
          warningModalData: {
            isOpen: true,
            type: 'ok',
            title: t('CHECKLIST_VALIDATION.MIN_CHECKLISTS_TITLE'),
            content: t('CHECKLIST_VALIDATION.MIN_CHECKLISTS'),
            closeAction,
            confirmAction: closeAction,
          },
        });
      } else {
        callback();
      }
    }
  };

  handleRemoveRegimeComponent = componentId => {
    const { selectedRegime, removeComponentFromRegime, regimeComponents } = this.props;
    const component = find(regimeComponents, { [componentFields.id]: componentId });

    if (component) {
      removeComponentFromRegime(selectedRegime, component, regimeComponents || []);
    }
  };

  handleComponentPicker3D = () => {
    const { inspectionId, selectedRegime, regimeComponents, addComponentToRegime } = this.props;
    const closeAction = () => {
      this.setState({ componentSelectionModal: { isOpen: false } });
    };

    this.setState({
      componentSelectionModal: {
        isOpen: true,
        CustomContent: dynamicProps => (
          <ComponentPicker3D
            {...dynamicProps}
            handleComponentSelected={(_moduleId, components) => addComponentToRegime(selectedRegime, components, regimeComponents || [])}
            handleDeleteComponent={(_moduleId, componentId) => this.handleRemoveRegimeComponent(componentId)}
            inspectionId={inspectionId}
            closeAction={closeAction}
          />
        ),
        customClassName: 'modal-no-max-height modal-large',
        type: 'none',
        closeAction,
      },
    });
  };

  handleComponentPickerPDF = () => {
    const { inspectionId, selectedRegime, regimeComponents, addComponentToRegime } = this.props;
    const closeAction = () => {
      this.setState({ componentSelectionModal: { isOpen: false } });
    };

    this.setState({
      componentSelectionModal: {
        isOpen: true,
        CustomContent: dynamicProps => (
          <ComponentPickerPDF
            {...dynamicProps}
            handleComponentSelected={(_moduleId, components) => addComponentToRegime(selectedRegime, components, regimeComponents || [])}
            handleDeleteComponent={(_moduleId, componentId) => this.handleRemoveRegimeComponent(componentId)}
            inspectionId={inspectionId}
            closeAction={closeAction}
          />
        ),
        customClassName: 'modal-no-max-height modal-large',
        type: 'none',
        closeAction,
      },
    });
  };

  handleComponentPickerCH = () => {
    const { inspectionId, selectedRegime, regimeComponents, addComponentToRegime } = this.props;
    const { t } = this.context;
    const closeAction = () => {
      this.setState({ componentSelectionModal: { isOpen: false } });
    };

    this.setState({
      componentSelectionModal: {
        isOpen: true,
        CustomContent: dynamicProps => (
          <ComponentPickerCH
            {...dynamicProps}
            handleComponentSelected={(_moduleId, components) => addComponentToRegime(selectedRegime, components, regimeComponents || [])}
            handleDeleteComponent={(_moduleId, componentId) => this.handleRemoveRegimeComponent(componentId)}
            inspectionId={inspectionId}
            closeAction={closeAction}
          />
        ),
        customClassName: 'modal-no-max-height modal-large',
        type: 'none',
        title: t('QUESTION_COMPONENT_PICKER.CHOOSE_COMPONENTS'),
        closeAction,
        customCloseAction: closeAction,
      },
    });
  };

  checkRegimeDetails = data => {
    const { t } = this.context;
    const { regimeList } = this.props;
    let result = {
      isValid: true,
      message: '',
    };

    const regime = find(regimeList, { [formConstants.fields.id]: data[formConstants.fields.id] });

    if (regime) {
      if (!regime[formConstants.fields.title]) {
        result = {
          isValid: false,
          message: t('MAINTENANCE_REGIME.MISSING_FIELD', { field: t('CREATE_REGIME_FORM.TITLE') }),
        };
      }

      if (regime[formConstants.fields.title] && regime[formConstants.fields.title].length < 3) {
        result = {
          isValid: false,
          message: t('MAINTENANCE_REGIME.REGIME_NAME_MIN'),
        };
      }

      if (!regime[formConstants.fields.startDate]) {
        result = {
          isValid: false,
          message: t('MAINTENANCE_REGIME.MISSING_FIELD', { field: t('CREATE_REGIME_FORM.START_DATE_LABEL') }),
        };
      }

      if (!regime[formConstants.fields.intervalTaskName] && !regime[formConstants.fields.intervalId]) {
        result = {
          isValid: false,
          message: t('MAINTENANCE_REGIME.MISSING_FIELD', { field: t('CREATE_REGIME_FORM.TASK_INTERVAL') }),
        };
      }

      if (!regime[formConstants.fields.taskIntervalNumber] && !regime[formConstants.fields.intervalNumber]) {
        result = {
          isValid: false,
          message: t('MAINTENANCE_REGIME.MISSING_FIELD', { field: t('CREATE_REGIME_FORM.TASK_DURATION') }),
        };
      }
    }

    return result;
  };

  handleConfirmModalAction = async (type, data, cb) => {
    switch (type) {
      case confirmationModalTypes.changeStatus:
        const { filter } = this.state;
        const { changeMaintenanceRegimeStatus, regimeList, getRegimes, notificationModalCustom } = this.props;
        const valid = data[formConstants.fields.status] === statuses.live.value ? this.checkRegimeDetails(data) : { isValid: true };

        if (valid.isValid) {
          await changeMaintenanceRegimeStatus(data, () => this.toggleConfirmationModal(false), Object.assign([], regimeList));
          cb && cb();
          if (data[formConstants.fields.status] === statuses.live.value) {
            const newParams = { ...filter, [filterFields.lastSeen]: defaultFilter[filterFields.lastSeen] };

            getRegimes(newParams, newFilter => {
              this.setState({ filter: { ...filter, ...newParams, ...newFilter, [filterFields.lastSeen]: defaultFilter[filterFields.lastSeen] } });
            });
          }
        } else {
          notificationModalCustom(
            {
              key: valid.message,
              props: {},
            },
            'MAINTENANCE_REGIME.WARNING'
          );
        }
        break;
      case confirmationModalTypes.duplicate:
        const regimeValid = data[formConstants.fields.status] === statuses.live.value ? this.checkRegimeDetails(data) : { isValid: true };
        if (regimeValid.isValid) {
          this.duplicateRegime(data, () => this.toggleConfirmationModal(false));
          cb && cb();
        } else {
          notificationModalCustom(
            {
              key: valid.message,
              props: {},
            },
            'MAINTENANCE_REGIME.WARNING'
          );
        }
        break;
      case confirmationModalTypes.delete:
        const { deleteRegime, location } = this.props;

        const isRegimeValid = data[formConstants.fields.status] === statuses.live.value ? this.checkRegimeDetails(data) : { isValid: true };
        if (isRegimeValid.isValid) {
          const onDone = () => {
            const queryParams = { ...location.query };
            delete queryParams.selected_item;
            const params = [];

            for (let key in queryParams) {
              params.push({
                [key]: queryParams[key],
              });
            }
            Helpers.goTo(location.pathname, params);
            this.toggleConfirmationModal(false);
          };
          deleteRegime(data, onDone);
          cb && cb();
        } else {
          notificationModalCustom(
            {
              key: valid.message,
              props: {},
            },
            'MAINTENANCE_REGIME.WARNING'
          );
        }
        break;
      default:
        break;
    }
  };

  toggleConfirmationModal = (isOpen, type, data, translationData, cb) => {
    const { t } = this.context;
    const confirmModalData = isOpen
      ? {
          isOpen,
          content: t(confirmationModalContent[type]?.content, translationData),
          type: 'yes-no',
          confirmAction: () => this.handleConfirmModalAction(type, data, cb),
          closeAction: () => this.toggleConfirmationModal(false),
        }
      : { isOpen: false };

    this.setState({ confirmModalData });
  };

  searchChecklists = SearchText => {
    const { projectId, searchChecklists } = this.props;
    searchChecklists({ ...checklistFilter, SearchText, [filterFields.projectID]: projectId, [filterFields.perPage]: 1000 }, checklists => {
      this.setState({ checklists: checklists || [] });
    });
  };

  openCPTemplatesModal = () => {
    const { t } = this.context;
    const {
      user,
      linkRegimeCPTemplateToComponents,
      unlinkRegimeCPTemplateFromComponents,
      deleteRegimeChecklist,
      regimeComponents,
      regimeChecklists,
      setRegimeChecklists,
      saveChecklists,
      addChecklistToTheRegime,
      selectedRegime,
      setGenericNotification,
      removeComponentFromCPTemplate,
    } = this.props;

    const previousChecklists = cloneDeep(regimeChecklists);
    if (
      !Helpers.hasAccess({
        user,
        visibleFor: [PERMISSIONS[PERMISSION_TYPES.maintenanceRegimes].regimeCPLink.name],
      })
    ) {
      return;
    }
    this.searchChecklists();

    const resetWorkOrderCPTemplates = () => {
      // reset state to previous if not saved
      setRegimeChecklists(previousChecklists);
    };

    const closeAction = currentCPTemplates => {
      if (isEqual(previousChecklists, currentCPTemplates)) {
        resetWorkOrderCPTemplates();
        this.setState({ addCPTemplatesModal: { isOpen: false } });
      } else {
        // show confirmation popup
        const { t } = this.context;
        const confirmModalData = {
          isOpen: true,
          title: t('WORK_ORDER.EDIT_CP_TEMPLATES.UNSAVED_CHANGES_CONFIRM'),
          content: t('WORK_ORDER.EDIT_CP_TEMPLATES.UNSAVED_CHANGES_CONFIRM_DESC'),
          type: 'yes-no',
          confirmAction: () => {
            this.toggleConfirmationModal(false);
            resetWorkOrderCPTemplates();
            this.setState({ addCPTemplatesModal: { isOpen: false } });
          },
          closeAction: () => this.toggleConfirmationModal(false),
        };

        this.setState({ confirmModalData });
      }
    };

    this.setState(prevState => ({
      addCPTemplatesModal: {
        ...prevState.addCPTemplatesModal,
        title: t('WORK_ORDER.EDIT_CP_TEMPLATES'),
        customClassName: 'work-order-default-modal create-work-order-modal',
        CustomContent: dynamicProps => (
          <div className="work-order-form">
            <AddCPTemplates
              handleSearchCPTemplates={this.searchChecklistsDebounced}
              addCPTemplateToWorkorder={addChecklistToTheRegime}
              removeCPTemplateFromWorkorder={deleteRegimeChecklist}
              user={user}
              linkToComponent={linkRegimeCPTemplateToComponents}
              unlinkFromComponent={unlinkRegimeCPTemplateFromComponents}
              workorderComponents={regimeComponents}
              removeComponentFromCPTemplate={removeComponentFromCPTemplate}
              {...dynamicProps}
            />
            <div className="work-order-default-modal-buttons">
              <Button type="button" variant="gray-outline" height="md" width="sm" onClick={() => closeAction(dynamicProps.workorderCPTemplates)} text={t('CANCEL')} />
              <Button
                className="modal-confirm-btn"
                height="md"
                width="sm"
                type="button"
                onClick={() => {
                  saveChecklists(
                    selectedRegime[formConstants.fields.id],
                    { ChecklistTemplateIDs: Helpers.removeStandaloneCPTemplatesIfLinked(dynamicProps.workorderCPTemplates) },
                    () => {
                      setGenericNotification({
                        isDisplayed: true,
                        type: 'success',
                        icon: 'checkmark-outline',
                        text: t('REGIME_TABLE.EDIT_CP_TEMPLATES.ON_SAVE_SUCCESS'),
                      });
                    },
                    e => resetWorkOrderCPTemplates()
                  );
                  this.setState({ addCPTemplatesModal: { isOpen: false } });
                }}
                text={t('SAVE')}
              />
            </div>
          </div>
        ),
        isOpen: true,
        type: 'none',
        closeAction,
      },
    }));
  };

  handleDownloadChecklist = checklist => {
    const { t } = this.context;
    const { generateAndDownloadRegimeChecklistTemplatePDF, setGenericNotification } = this.props;
    generateAndDownloadRegimeChecklistTemplatePDF(checklist[checklistFields.id], () => {
      this.setState({ isDownloadInProgress: false, confirmModalData: { isOpen: false } });
    });
    // now display the notification
    setGenericNotification({
      isDisplayed: true,
      type: 'info',
      icon: 'check-toast',
      text: t('ISOLATION_CERTIFICATE.DOWNLOAD_NOTIFICATION'),
      onClickClose: this.closeNotification,
    });
  };

  getChecklistSections = (checklist, filters, successCallback = () => null, failCallback = () => null) => {
    const { fetchChecklistProcedureTemplateSections } = this.props;

    fetchChecklistProcedureTemplateSections(
      { ChecklistAndProcedureTemplateID: checklist[checklistFields.id], ...filters },
      (requestInProgress, isLoading, sections, isSectionChanged, toggleAddQuestionButton, newFilters) => {
        if (requestInProgress || isLoading) {
          return;
        }
        if (sections) {
          // sucess
          successCallback({
            Sections: {
              Items: sections,
              ...newFilters,
            },
          });
        } else {
          // error
          failCallback();
        }
      }
    );
  };

  getChecklistSectionQuestions = (checklist, sectionId, filters, successCallback = () => null, failCallback = () => null) => {
    const { fetchChecklistProcedureTemplateSectionQuestions } = this.props;

    fetchChecklistProcedureTemplateSectionQuestions(checklist[checklistFields.id], sectionId, filters, (requestInProgress, isLoading, sectionId, questions, newFilters) => {
      if (requestInProgress || isLoading) {
        return;
      }
      if (questions) {
        successCallback({
          Items: questions,
          ...newFilters,
        });
      } else {
        failCallback();
      }
    });
  };

  openFillInChecklistModalData = checklist => {
    const { t } = this.context;
    const { user, severityColors, inspectionId, projectId, selectedRegime, additionalWorkorderTypes, getWorkOrderChecklistSectionQuestionDetails } = this.props;

    const closeAction = () => {
      this.setState({ checklistModalData: { isOpen: false } });
    };

    this.setState({
      checklistModalData: {
        isOpen: true,
        title: checklist[checklistFields.name],
        CustomContent: dynamicProps => (
          <WorkOrderSectionChecklistDetails
            checklistId={checklist[checklistFields.id]}
            module={Object.assign({}, selectedRegime)}
            handleAnswerQuestion={(vals, callback) => null}
            moduleId={selectedRegime[formConstants.fields.id]}
            statuses={statuses}
            createdById={selectedRegime[formConstants.fields.createdBy]}
            user={user}
            severityColors={severityColors}
            showEmptyVersion={true}
            showProgressBar={false}
            disabledForStatuses={[]}
            fetchChecklistSections={(filters, successCallback, failCallback) => this.getChecklistSections(checklist, filters, successCallback, failCallback)}
            fetchChecklistQuestions={(sectionId, filters, successCallback, failCallback) => this.getChecklistSectionQuestions(checklist, sectionId, filters, successCallback, failCallback)}
            handleSubmitChecklist={(successCallback, failCallback) => null}
            inspectionId={inspectionId}
            handleCancelChecklist={closeAction}
            projectId={projectId}
            handleFileOpen={this.handleFileOpen}
            additionalWorkorderTypes={additionalWorkorderTypes}
            getWorkOrderChecklistSectionQuestionDetails={getWorkOrderChecklistSectionQuestionDetails}
            {...dynamicProps}
          />
        ),
        actionItems: [
          {
            onClick: () => this.handleDownloadChecklist(checklist),
            title: t('WORK_ORDER.DOWNLOAD_ICON'),
            icon: 'download',
            iconProps: {
              // active: true,
            },
          },
        ],
        type: 'none',
        customClassName: 'checklist-details-modal',
        closeAction,
        customCloseAction: closeAction,
      },
    });
  };

  render() {
    const { t } = this.context;
    const {
      regimeList,
      selectedRegime,
      createRegime,
      inspectionId,
      projectId,
      updateRegime,
      getRegimeComponents,
      getRegimeFiles,
      regimeFiles,
      unlinkRegimeFile,
      uploadInProgress,
      regimeComponents,
      components,
      searchComponents,
      addComponentToRegime,
      removeComponentFromRegime,
      checklistList,
      getRegimeChecklists,
      regimeChecklists,
      addChecklistToTheRegime,
      deleteRegimeChecklist,
      intervalList,
      getIntervalList,
      updateInterval,
      formValues,
      severityColors,
      backButtonPath,
      location,
      linkRegimeCPTemplateToComponents,
      unlinkRegimeCPTemplateFromComponents,
      removeComponentFromCPTemplate,
      saveChecklists,
      user,
      fileOpenerModalData,
    } = this.props;
    const {
      modalData,
      closeRegimeModalTriggered,
      filter,
      uploadModalData,
      filesModalData,
      confirmModalData,
      checklistModalData,
      warningModalData,
      componentSelectionModal,
      isInitialFetchDone,
      rightCollapsed,
      advancedFiltersModalData,
      checklists,
      addCPTemplatesModal,
      additionalWorkorderTypes,
    } = this.state;
    const selected_item = (location && location.query.selected_item && parseInt(location.query.selected_item)) || null;
    const sidebarClosed = rightCollapsed || !selected_item;

    return (
      <div className={`maintenance-regime-wrapper ${!sidebarClosed && 'module-picker__half-width'}`}>
        <div className={`maintenance-regimes ${!sidebarClosed ? 'sidebar-open' : ''}`}>
          <PageNavigation
            backButtonPath={backButtonPath}
            title="PAGE_TITLE.MAINTENANCE_REGIME"
            icon="regime"
            withBottomBorder
            actionButtontext="CREATE_BUTTON_TEXT.NEW"
            handleActionButtonClick={this.openRegimeEditModal}
            actionButtonProps={{
              visibleFor: PERMISSIONS[PERMISSION_TYPES.maintenanceRegimes].create.name,
            }}
          />
          <div className="header">
            <SearchInput onChange={this.handleInputChange} placeholder={t('PROJECT.SEARCH.PLACEHOLDER')} stripped />
          </div>
          <div className="maintenance-regime-table">
            <RenderIf if={(!regimeList || !regimeList.length) && isInitialFetchDone}>
              <div className="empty-state">
                <p className="f-primary">{t('MAINTENANCE_REGIME.EMPTY_STATE')}</p>
              </div>
            </RenderIf>
            <RenderIf if={isInitialFetchDone && regimeList && regimeList.length > 0}>
              <TableComponent
                tableConfig={constants.regimeTableHeader}
                data={regimeList || []}
                tableCustomClass="regimes-table"
                onRowClick={this.onRowClick}
                onColumnSort={this.onColumnSort}
                sortingObj={filter}
                translationModule={t}
                formatCell={(value, type, index, item) => {
                  if (type === columnTypes.status) {
                    return <DisplayStatus statuses={values(statuses)} status={value} />;
                  } else if (type === tableIntervalCombineKey) {
                    return item[formConstants.fields.intervalNumber] && item[formConstants.fields.intervalName] && item[formConstants.fields.intervalNumber] > 0
                      ? `Every ${item[formConstants.fields.intervalNumber]} ${item[formConstants.fields.intervalName]}${item[formConstants.fields.intervalNumber] > 1 ? 's' : ''}`
                      : '-';
                  } else if (type === constants.dateShortType) {
                    return !isNaN(value) && value > 0 ? Helpers.getDateFromUnix(value, 'MMMM Do YYYY') : '-';
                  } else if (type === columnTypes.components) {
                    const componentsNumber = (value || []).length;

                    if (componentsNumber < 2) {
                      return componentsNumber && item[formConstants.fields.components] && item[formConstants.fields.components][0] ? item[formConstants.fields.components][0]['Name'] : '-';
                    } else {
                      return (
                        <div className="name">
                          <InfoTooltip
                            customComponent={<MultiplePreviewPopup title={item['Name']} subTitle={t('REGIME_TABLE.LINKED_COMPONENTS')} list={value} />}
                            offsetY={10}
                            offsetX={-20}
                            Component={() => (
                              <div className="regime-popover">
                                <p className="f-primary">{t('ISOLATION_CERTIFICATES.LINKED_PERMITS_PLACEHOLDER', { number: componentsNumber })}</p>
                                <Icon name="info" handleHover={false} size="xs" />
                              </div>
                            )}
                          />
                        </div>
                      );
                    }
                  } else if (type === columnTypes.checklists) {
                    const checklistsNumber = (value || []).length;

                    if (checklistsNumber < 2) {
                      return checklistsNumber && item[formConstants.fields.checklists] && item[formConstants.fields.checklists][0] ? item[formConstants.fields.checklists][0]['Name'] : '-';
                    } else {
                      return (
                        <div className="name">
                          <InfoTooltip
                            customComponent={<MultiplePreviewPopup title={item['Name']} subTitle={t('REGIME_TABLE.LINKED_CHECKLISTS')} list={value} />}
                            offsetY={10}
                            offsetX={-20}
                            Component={() => (
                              <div className="regime-popover">
                                <p className="f-primary">{t('ISOLATION_CERTIFICATES.LINKED_PERMITS_PLACEHOLDER', { number: checklistsNumber })}</p>
                                <Icon name="info" handleHover={false} size="xs" />
                              </div>
                            )}
                          />
                        </div>
                      );
                    }
                  } else if (type === columnTypes.priority) {
                    const workOrderPriorities = values(priorities);
                    const selected = find(workOrderPriorities, { value });

                    return (
                      <div className="regime-priority">
                        {selected?.color && <div className="regime-priority-color" style={{ backgroundColor: severityColors[selected?.color] }}></div>}
                        <p className="f-primary">{t(selected?.title ? selected.title : priorities.none.title)}</p>
                      </div>
                    );
                  }

                  return Helpers.formatCell(value, type, index, item);
                }}
                stickyHeader={true}
                sortDirectionProp={filterFields.sortByDirection}
              />
            </RenderIf>
            <LoadMore
              disabled={!filter[filterFields.hasNext]}
              loaded={(regimeList || []).length}
              total={filter[filterFields.totalItems]}
              totalPosition="center"
              isLoading={false}
              label="COMPONENT_HISTORY.LOAD_MORE"
              showButton
              showTotalUp
              buttonVariant="success-outline"
              resultsText="MAINTENANCE_REGIME_STATUS.SHOWING_TOTAL_ITEMS"
              onClick={this.loadMore}
            />
          </div>
        </div>
        {selected_item && (
          // no need to render MaintenanceRegimeRightSide until there is a selected_item, otherwise needlesly loads everything, including validation resulting in memory leak and performance issues
          <CollapsibleToolbar collapsed={sidebarClosed} toggleToolbar={this.toggleToolbar} placement={placements.right} resizable={false}>
            <MaintenanceRegimeRightSide
              selected_item={selected_item}
              location={location}
              queryItem={selected_item}
              intervalList={intervalList}
              regimeFiles={regimeFiles}
              regimeComponents={regimeComponents}
              regimeChecklists={regimeChecklists}
              openRegimeEditModal={this.openRegimeEditModal}
              openCPTemplatesModal={this.openCPTemplatesModal}
              openFillInChecklistModalData={this.openFillInChecklistModalData}
              projectId={projectId}
              inspectionId={inspectionId}
              setModalState={this.setAdvancedFiltersModalData}
              toggleConfirmationModal={this.toggleConfirmationModal}
              closeRegimeModalTriggered={closeRegimeModalTriggered}
              additionalWorkorderTypes={additionalWorkorderTypes}
            />
          </CollapsibleToolbar>
        )}
        <Modal
          {...{
            ...modalData,
            regime: Object.assign({}, selectedRegime),
            createRegime: (params, successCallback) =>
              createRegime({ ...params, [formConstants.fields.inspectionId]: inspectionId }, data => {
                successCallback(data);
                const { filter } = this.state;
                const newState = {
                  filter: {
                    ...filter,
                    [filterFields.lastSeen]: filter[filterFields.lastSeen] + 1,
                    [filterFields.totalItems]: filter[filterFields.totalItems] + 1,
                  },
                };
                this.setState(newState);

                Helpers.goTo(routes.protectedRoutes.maintenanceRegime.fullPath, [{ project_id: projectId }, { inspection_id: inspectionId }, { selected_item: data[formConstants.fields.id] }]);
              }),
            updateRegime: (params, successCallback) => updateRegime({ ...params, [formConstants.fields.inspectionId]: inspectionId }, successCallback),
            getRegimeFiles: (group, successCallback) => getRegimeFiles(selectedRegime[formConstants.fields.id], group, successCallback),
            getRegimeComponents,
            openFilesModalData: this.openFilesModalData,
            regimeFiles,
            unlinkRegimeFile: (fileid, group) => unlinkRegimeFile(selectedRegime[formConstants.fields.id], fileid, group),
            regimeComponents,
            components,
            searchComponents: searchText => searchComponents(inspectionId, searchText, { LastSeen: 0, PerPage: Number.MAX_SAFE_INTEGER }),
            addComponentToRegime: component => addComponentToRegime(selectedRegime, component, regimeComponents || []),
            removeComponentFromRegime: (component, successCallback) => removeComponentFromRegime(selectedRegime, component, regimeComponents || [], successCallback),
            getRegimeChecklists: successCallback => getRegimeChecklists(selectedRegime[formConstants.fields.id], successCallback),
            checklistList,
            checklists,
            searchChecklists: this.searchChecklistsDebounced,
            regimeChecklists,
            addChecklistToTheRegime: addChecklistToTheRegime,
            deleteRegimeChecklist: deleteRegimeChecklist,
            linkRegimeCPTemplateToComponents,
            unlinkRegimeCPTemplateFromComponents,
            removeComponentFromCPTemplate,
            saveChecklists,
            getIntervalList,
            intervalList,
            updateInterval: (params, successCallback) => updateInterval(selectedRegime, params, successCallback),
            setConfirmModalData: this.setConfirmModalData,
            closeConfirmModal: this.closeConfirmModal,
            formValues,
            handleComponentPicker3D: this.handleComponentPicker3D,
            handleComponentPickerPDF: this.handleComponentPickerPDF,
            handleComponentPickerCH: this.handleComponentPickerCH,
            user,
          }}
        />
        <Modal
          {...addCPTemplatesModal}
          cpTemplates={checklists}
          workorderCPTemplates={regimeChecklists}
          closeAction={() => addCPTemplatesModal?.closeAction && addCPTemplatesModal?.closeAction(regimeChecklists)}
        />
        <Modal {...uploadModalData} modalDisabled={uploadInProgress} />
        <Modal {...filesModalData} modalDisabled={uploadInProgress} />
        <Modal {...confirmModalData} modalDisabled={uploadInProgress} />
        <Modal
          {...checklistModalData}
          {...{
            workOrderChecklists: regimeChecklists,
          }}
        />
        <Modal {...warningModalData} />
        <Modal {...componentSelectionModal} components={components} question={{ ID: 1, QuestionsComponents: regimeComponents }} />
        <Modal {...advancedFiltersModalData} />
        {fileOpenerModalData && <Modal {...fileOpenerModalData} />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  regimeList: state.regimeReducer.regimeList,
  selectedRegime: state.regimeReducer.selectedRegime,
  regimeFiles: state.regimeReducer.regimeFiles,
  regimeComponents: state.regimeReducer.regimeComponents,
  uploadInProgress: state.uploadReducer.uploadInProgress,
  components: state.inspectionReducer.components,
  checklistList: state.checklistReducer.checklistList,
  regimeChecklists: state.regimeReducer.regimeChecklists,
  intervalList: state.regimeReducer.intervalList,
  selectedChecklistItems: state.checklistReducer.selectedChecklistItems,
  severityColors: state.themeReducer.severityColors,
  formValues: state.form[FORMS.createRegimeForm]?.values || {},
  user: state.userReducer,
  inspectionModalData: state.inspectionReducer.inspectionModalData,
});

const mapDispatchToProps = dispatch => ({
  getRegimes: (params, successCallback, loadMore) => dispatch(getRegimes(params, successCallback, loadMore)),
  getRegimeDetails: (id, successCallback) => dispatch(getRegimeDetails(id, successCallback)),
  createRegime: (params, successCallback) => dispatch(createRegime(params, successCallback)),
  deleteRegime: (regime, successCallback) => dispatch(deleteRegime(regime, successCallback)),
  updateRegime: (params, successCallback) => dispatch(updateRegime(params, successCallback)),
  clearRegimeModalData: () => dispatch(clearRegimeModalData()),
  getRegimeComponents: (params, successCallback) => dispatch(getRegimeComponents(params, successCallback)),
  getRegimeFiles: (regimeId, group, successCallback) => dispatch(getRegimeFiles(regimeId, group, successCallback)),
  uploadAsset: (file, IDs, callbackFunction, index, customDoneFunction, cbDocumentFinished) => dispatch(uploadAsset(file, IDs, callbackFunction, index, customDoneFunction, cbDocumentFinished)),
  setUploadItems: assets => dispatch(setUploadItems(assets)),
  setSingleUploadItem: (progress, index) => dispatch(setSingleUploadItem(progress, index)),
  singleItemUploadDone: (url, callback) => dispatch(singleItemUploadDone(url, callback)),
  unlinkRegimeFile: (regimeId, fileID, group) => dispatch(unlinkRegimeFile(regimeId, fileID, group)),
  searchComponents: (inspectionid, searchText, otherParams) => dispatch(searchComponents(inspectionid, searchText, otherParams)),
  addComponentToRegime: (regime, component, currentComponents) => dispatch(addComponentToRegime(regime, component, currentComponents)),
  removeComponentFromRegime: (regime, component, currentComponents, successCallback) => dispatch(removeComponentFromRegime(regime, component, currentComponents, successCallback)),
  getRegimeChecklists: (id, successCallback) => dispatch(getRegimeChecklists(id, successCallback)),
  searchChecklists: (params, successCallback) => dispatch(searchChecklists(params, successCallback)),
  addChecklistToTheRegime: checklist => dispatch(addRegimeChecklist(checklist)),
  deleteRegimeChecklist: checklist => dispatch(removeRegimeChecklistByProp(checklist)),
  linkRegimeCPTemplateToComponents: data => dispatch(linkRegimeCPTemplateToComponents(data)),
  unlinkRegimeCPTemplateFromComponents: data => dispatch(unlinkRegimeCPTemplateFromComponents(data)),
  removeComponentFromCPTemplate: (item, component) => dispatch(removeRegimeComponentFromCPTemplate(item, component)),
  saveChecklists: (regimeId, data, successCallback, failCallback) => dispatch(saveChecklists(regimeId, data, successCallback, failCallback)),
  duplicateRegime: (id, successCallback) => dispatch(duplicateRegime(id, successCallback)),
  getIntervalList: successCallback => dispatch(getIntervalList(successCallback)),
  updateInterval: (regimeId, params, successCallback) => dispatch(updateInterval(regimeId, params, successCallback)),
  notificationModalCustom: (errorMessage, title) => dispatch(notificationModalCustom(true, errorMessage, title)),
  changeMaintenanceRegimeStatus: (data, callback, checklists) => dispatch(changeStatus(data, callback, checklists)),
  setRegimeChecklists: data => dispatch(setRegimeChecklists(data)),
  setGenericNotification: data => dispatch(setGenericNotification(data)),
  generateAndDownloadRegimeChecklistTemplatePDF: (checklistTemplateId, callback) => dispatch(generateAndDownloadRegimeChecklistTemplatePDF(checklistTemplateId, callback)),
  resetForm: formName => dispatch(reset(formName)),
  destroyForm: formName => dispatch(destroy(formName)),
  setPdfComponents: selectedModuleItemDrawings => dispatch(setPdfComponents(selectedModuleItemDrawings)),
  getPdfComponentsForPopup: (inspectionID, fileId, filter, pageNumber, compId, callback) => dispatch(getPdfComponentsForPopup(inspectionID, fileId, filter, pageNumber, compId, callback)),
  getAdditionalWorkorderTypes: successCallback => dispatch(getAdditionalWorkorderTypes(successCallback)),
  fetchChecklistProcedureTemplateSections: (data, callback, questionId, areAdditionalFieldsExpanded) =>
    dispatch(getRegimeChecklistTemplateSections(data, callback, questionId, areAdditionalFieldsExpanded)),
  fetchChecklistProcedureTemplateSectionQuestions: (ChecklistAndProcedureTemplateID, SectionID, filters, callback) =>
    dispatch(getRegimeChecklistTemplateSectionQuestions(ChecklistAndProcedureTemplateID, SectionID, filters, callback)),
  getWorkOrderChecklistSectionQuestionDetails: (sectionId, questionId, successCallback, failCallback) =>
    dispatch(getWorkOrderChecklistSectionQuestionDetails(sectionId, questionId, successCallback, failCallback)),
  toggleInspectionModal: data => dispatch(toggleInspectionModal(data)),
  closeInspectionModal: () => dispatch(closeInspectionModal()),
  minimizeInspectionModal: () => dispatch(minimizeInspectionModal()),
  maximizeInspectionModal: () => dispatch(maximizeInspectionModal()),
  changePDFPage: (newPDFPageNumber, inspectionId, fileId, filter, selectedComponent, showOnlyConfirmedDrawings, callback) =>
    dispatch(changePDFPage(newPDFPageNumber, inspectionId, fileId, filter, selectedComponent, showOnlyConfirmedDrawings, callback)),
  openAsSeparatePageInspectionModal: () => dispatch(openAsSeparatePageInspectionModal()),
});

RegimeTable.contextTypes = {
  t: PropTypes.func.isRequired,
};

RegimeTable.defaultProps = {
  previewMode: false,
};

const ConnectedRegimeTable = connect(mapStateToProps, mapDispatchToProps)(RegimeTable);
const RegimeTableWithFileOpener = withFileOpener(ConnectedRegimeTable);
export default withRouter(RegimeTableWithFileOpener);
