export const MIN_STREAM_NAME_LENGTH = 1;
export const MAX_STREAM_NAME_LENGTH = 50;

export const augmentedRealityVideoListFields = {
  id: 'ID',
  inspectionID: 'InspectionID',
  name: 'Name',
  status: 'Status',
  isLive: 'IsLive',
  duration: 'Duration',
  startedAt: 'StartedAt',
  postProcessingStatus: 'PostProcessingStatus',
  playlistURL: 'PlaylistURL',
  isPostProcessed: 'IsPostProcessed',
};
