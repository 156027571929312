export const fields = {
  icon: 'icon',
  name: 'Name',
  createdAt: 'CreatedAt',
  status: 'Status',
  severity: 'Severity',
  id: 'ID',
  geometry: 'Geometry',
  camPosition: 'CameraPosition',
  dateCreated: 'DateCreated',
  type: 'Type',
  description: 'Description',
  mainType: 'MainType',
  defectType: 'DefectType',
  recommendation: 'Recommendation',
  colour: 'Colour',
  componentId: 'ComponentID',
};

//TODO: Adjust it to support full screen in future
export const tableConfig = [
  {
    title: '',
    key: fields.status,
    type: fields.icon,
    class: 'icon-column',
  },
  {
    title: 'OBSERVATIONS.TITLE',
    key: fields.name,
    type: fields.name,
    class: 'name-column__observations',
    isSort: true,
  },
  {
    title: 'COMPONENT_OBSERVATIONS.SEVERITY',
    key: fields.severity,
    type: fields.severity,
    class: 'severity-column',
    isSort: true,
  },
  {
    title: 'OBSERVATIONS.REPORTED_DATE',
    key: fields.dateCreated,
    type: fields.dateCreated,
    class: 'created-at-column',
    isSort: true,
  },
];

export const statuses = {
  open: {
    value: 'OPEN',
    title: 'OBSERVATION_STATUS.OPEN',
    icon: 'exclamation-mark',
    iconProps: {
      danger: true,
      handleHover: false,
    },
    skipPermissionCheck: true,
    isRegularStatus: true,
    modalTitle: 'ISOLATION_CERTIFICATE_STATUS.DRAFT_TITLE',
  },
  actioned: {
    value: 'ACTIONED',
    title: 'OBSERVATION_STATUS.ACTIONED',
    icon: 'exclamation-mark',
    iconProps: {
      warning: true,
      handleHover: false,
    },
    isRegularStatus: true,
  },
  closed: {
    value: 'CLOSED',
    title: 'OBSERVATION_STATUS.CLOSED',
    icon: 'checkmark',
    iconProps: {
      active: true,
    },
    iconFilledWhenActive: true,
    isRegularStatus: true,
  },
};

export const severityColors = {
  '-1': 'severity-green',
  0: 'severity-green',
  1: 'severity-yellow',
  2: 'severity-yellow',
  3: 'severity-yellow',
  4: 'severity-orange',
  5: 'severity-orange',
  6: 'severity-orange',
  7: 'severity-orange',
  8: 'severity-red',
  9: 'severity-red',
  10: 'severity-red',
};

export const filterProps = {
  componentID: 'ComponentID',
  searchText: 'SearchText',
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  severityFilter: 'SeverityFilter',
  statusFilter: 'StatusFilter',
  dateFrom: 'DateFrom',
  dateTo: 'DateTo',
  defectTypeFilter: 'DefectTypeFilter',
  defectMainTypeFilter: 'DefectMainTypeFilter',
  createdByFilter: 'CreatedByFilter',
  hasNotificationFilter: 'HasNotificationFilter',
  manufacturerFilter: 'ManufacturerFilter',
  assetTypeFilter: 'AssetTypeFilter',
  lastSeen: 'LastSeen',
  perPage: 'PerPage',
  hasNext: 'HasNext',
  actionedFrom: 'ActionedFrom',
  actionedTo: 'ActionedTo',
  closedFrom: 'ClosedFrom',
  closedTo: 'ClosedTo',
  componentTypeFilter: 'ComponentTypeFilter',
  componentIDsFilter: 'ComponentIDsFilter',
  totalItems: 'TotalNumber',
  assignees: 'Assignees',
  collaborators: 'Collaborators',
  linkedToMe: 'LinkedToMe',
  hasWorkOrder: 'HasWorkOrdersFilter',
  hasFiles: 'HasFilesFilter',
  has3DLocationFilter: 'Has3DLocationFilter',
  properties: 'Properties',
  componentsSearchFilter: 'ComponentSearchText',
  componentIDFilter: 'ComponentIDFilter',
};

// In future we should just have one sortDirection in main consts
export const sortDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const defaultFilters = {
  [filterProps.sortByColumn]: fields.dateCreated,
  [filterProps.sortDirection]: sortDirection.desc,
  [filterProps.perPage]: 10,
  [filterProps.lastSeen]: 0,
  [filterProps.searchText]: '',
  [filterProps.hasNext]: false,
  [filterProps.statusFilter]: [statuses.open.value, statuses.actioned.value],
};
