import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import { FORMS } from '../../../../common/constants';
import Button from '../../../../common/form/components/button';
import { modules } from '../../constants/constants';
import EditModuleItemFilesModal from '../right-toolbar/common/edit-module-item-files-modal';
import AddCPTemplates from './add-cp-templates';
import ChooseNotificationForm from './choose-notification-form';
import { steps } from './constants/constants';
import { workorderValidate } from './validators/workorder-validator';
import WorkOrderComponents from './work-order-components';
import WorkOrderDetailsForm from './work-order-details-form';

const CreateWorkOrderForm = (props, { t }) => {
  const searchChecklistSearchChangeDebounced = debounce(props.searchChecklists, 500);

  const handleChecklistSearch = value => {
    searchChecklistSearchChangeDebounced(value);
  };

  const {
    handleSubmit,
    activeStep,
    lastStep,
    stepAction,
    isPreviewMode,
    workorderComponents,
    components,
    removeComponentFromWorkorder,
    addComponentToWorkorder,
    invalid,
    closeConfirmModal,
    workorderTypes,
    additionalWorkOrderTypes,
    activeStepObj,
    startDate,
    dueDate,
    endDate,
    severityColors,
    handleComponentPicker3D,
    handleComponentPickerPDF,
    handleComponentPickerCH,
    closeCreateWorkOrderModal,
    checklistList,
    workorderChecklists,
    addChecklistToTheWorkOrder,
    deleteWorkorderChecklist,
    projectDMSCategories,
    allBatchesUploaded,
    linkWorkorderChecklist,
    unlinkWorkorderChecklist,
    removeComponentFromCPTemplate,
    createWorkOrderFormValues,
  } = props;

  return (
    <form className="work-order-form" onSubmit={handleSubmit} noValidate>
      <div className="work-order-form__content">
        {activeStep === steps.firstStep && <ChooseNotificationForm activeStepObj={activeStepObj} workorderTypes={workorderTypes} additionalWorkOrderTypes={additionalWorkOrderTypes} />}
        {activeStep === steps.secondStep && (
          <WorkOrderDetailsForm
            isPreviewMode={isPreviewMode}
            startDate={startDate}
            dueDate={dueDate}
            endDate={endDate}
            severityColors={severityColors}
            createWorkOrderFormValues={createWorkOrderFormValues}
          />
        )}
        {activeStep === steps.thirdStep && (
          <EditModuleItemFilesModal projectDMSCategories={projectDMSCategories} selectedModuleItem={null} moduleType={modules.workorders} overrideModuleTypeWithProp />
        )}
        {activeStep === steps.fourthStep && (
          <WorkOrderComponents
            components={components}
            workorderComponents={workorderComponents}
            addComponentToWorkorder={addComponentToWorkorder}
            removeComponentFromWorkorder={removeComponentFromWorkorder}
            closeConfirmModal={closeConfirmModal}
            handleComponentPicker3D={handleComponentPicker3D}
            handleComponentPickerPDF={handleComponentPickerPDF}
            handleComponentPickerCH={handleComponentPickerCH}
          />
        )}
        {activeStep === steps.fifthStep && (
          <AddCPTemplates
            cpTemplates={checklistList}
            workorderCPTemplates={workorderChecklists}
            handleSearchCPTemplates={handleChecklistSearch}
            addCPTemplateToWorkorder={addChecklistToTheWorkOrder}
            removeCPTemplateFromWorkorder={deleteWorkorderChecklist}
            linkToComponent={linkWorkorderChecklist}
            unlinkFromComponent={unlinkWorkorderChecklist}
            closeConfirmModal={closeConfirmModal}
            workorderComponents={workorderComponents}
            removeComponentFromCPTemplate={removeComponentFromCPTemplate}
          />
        )}
      </div>
      <div className="buttons">
        <Button type="button" variant="gray-outline" width="sm" height="md" text={t('CREATE_WORKORDER_FORM.CANCEL')} onClick={closeCreateWorkOrderModal} />
        <div className="right-buttons">
          <Button type="button" variant="gray-outline" width="sm" disabled={activeStep === steps.firstStep} height="md" text={t('CREATE_WORKORDER_FORM.BACK')} onClick={() => stepAction(false)} />
          <Button
            height="md"
            className={`button-submit ${invalid ? 'disabled' : ''}`}
            width="sm"
            disabled={(activeStep === lastStep && invalid) || (activeStep === steps.thirdStep && !allBatchesUploaded)}
            text={t(activeStep === lastStep ? 'CREATE_WORKORDER_FORM.SAVE' : 'CREATE_WORKORDER_FORM.NEXT')}
          />
        </div>
      </div>
    </form>
  );
};

CreateWorkOrderForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

CreateWorkOrderForm.propTypes = {
  // Define prop types here
};

export default reduxForm({
  form: FORMS.createWorkorderForm,
  validate: workorderValidate,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(CreateWorkOrderForm);
