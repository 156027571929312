import Helpers from '../../../common/helpers';
import Icon from '../../../common/icon/components/icon';
import SmallDropdown from '../../../common/small-dropdown/components/small-dropdown';
import { augmentedRealityVideoListFields as fields } from '../constants/augmented-reality-constant';

export const formatRoboticLiveStreamsTable = (value, type, _searchTerm, _row, _onDropdownClick, t) => {
  if (type === fields.name) {
    return (
      <div className="flex-center">
        <p className="f-primary" title={value}>
          {value}
        </p>
        <div className="chip">
          <div className="red-dot" />
          {t('LIVE')}
        </div>
      </div>
    );
  } else if (type === fields.startedAt) {
    return <p className="f-primary">{Helpers.getDateFromUnix(value, 'DD MMM YYYY, h:mm A')}</p>;
  } else if (type === fields.icon) {
    return (
      <div className="flex-center">
        <Icon name="play" size="sm" className="upload-progress-col__loader__icon" />
      </div>
    );
  } else {
    return value ? value : '-';
  }
};

export const formatStreamHistoryTable = (value, type, _searchTerm, _row, _onDropdownClick) => {
  if (type === fields.name) {
    return (
      <div className="flex-center">
        <Icon name="play" size="sm" className="upload-progress-col__loader__icon" />
        <p className="f-primary" title={value}>
          {value}
        </p>
      </div>
    );
  } else if (type === fields.startedAt) {
    return <p className="f-primary">{Helpers.getDateFromUnix(value, 'DD MMM YYYY, h:mm A')}</p>;
  } else if (type === fields.duration) {
    return <p className="f-primary">{Helpers.formatTime(value)}</p>;
  } else {
    return value ? value : '-';
  }
};

export const formatPostVideoRelocalisationTable = (
  value,
  type,
  _searchTerm,
  row,
  _onDropdownClick,
  t,
  _pointerDotID,
  _user,
  _onToggleClick,
  _onDeleteRow,
  _statusData,
  _handleChangeStatus,
  _onLinkFileClick,
  _selectedItem,
  _cell,
  _hasLinkDMSFilePermissions,
  _onRowClick,
  _toggleModalAction,
  menuOptions
) => {
  if (type === fields.name) {
    return (
      // TODO: Implement different states based on API response (processing, failed processing (reprocess CTA), disabled)
      <div className="flex-center">
        <Icon name="play" size="sm" className="upload-progress-col__loader__icon" />
        <p className="f-primary" title={value}>
          {value}
        </p>
      </div>
    );
  } else if (type === fields.startedAt) {
    return <p className="f-primary">{Helpers.getDateFromUnix(value, 'DD MMM YYYY, h:mm A')}</p>;
  } else if (type === fields.duration) {
    return <p className="f-primary">{Helpers.formatTime(value)}</p>;
  } else if (type === fields.actions) {
    const actionsMenu = menuOptions(row) || [];
    return (
      <SmallDropdown
        className="page-navigation__wrapper__title-container__menu__dropdown"
        actionsMenu={actionsMenu}
        getClickAction={(action, b, selfClose, e) => {
          e?.preventDefault && e.preventDefault();
          selfClose();
          if (action) {
            if (typeof action === 'function') {
              action(row);
            }
          }
        }}
        offsetX={10}
      />
    );
  } else {
    return value ? value : '-';
  }
};
