import Helpers from '../../../../../../common/helpers';
import Icon from '../../../../../../common/icon/components/icon';
import { PERMISSION_TYPES, PERMISSIONS } from '../../../../../../common/permissions-constants';
import { fields, severityColors, statuses } from '../../../observations/constants/constants';
import { formConstants } from '../constants/measurement-location-constants';
import { reorderDirection } from '../constants/measurement-point-obeservations-constant';

const statusesIconMap = {
  [statuses.open.value]: {
    icon: statuses.open.icon,
    props: statuses.open.iconProps,
  },
  [statuses.actioned.value]: {
    icon: statuses.actioned.icon,
    props: statuses.actioned.iconProps,
  },
  [statuses.closed.value]: {
    icon: statuses.closed.icon,
    props: statuses.closed.iconProps,
  },
};

export const formatObservationsTableCells = (value, type, searchTerm) => {
  if (type === fields.icon) {
    return <Icon name={statusesIconMap[value].icon} {...statusesIconMap[value].props} size="sm" />;
  } else if (type === fields.createdAt) {
    return <p className="f-primary">{Helpers.getDateFromUnix(value, 'D MMM, YYYY')}</p>;
  } else if (type === fields.severity) {
    return <div className={`severity-icon ${severityColors[value] || 'severity-green'}`}>{value}</div>;
  } else if (type === fields.name) {
    return (
      <p className="f-primary" title={value}>
        {Helpers.getSearchTermText(Helpers.getShortenedText(value, 25), searchTerm)}
      </p>
    );
  } else {
    return value ? value : '-';
  }
};

export const formatNotificationsTableCells = (value, type, searchTerm) => {
  if (type === fields.icon) {
    return <Icon name={statusesIconMap[value].icon} {...statusesIconMap[value].props} size="sm" />;
  } else if (type === fields.createdAt) {
    return <p className="f-primary">{Helpers.getDateFromUnix(value, 'D MMM, YYYY')}</p>;
  } else if (type === fields.name) {
    return (
      <p className="f-primary" title={value}>
        {Helpers.getSearchTermText(Helpers.getShortenedText(value, 25), searchTerm)}
      </p>
    );
  } else {
    return <p className="f-primary">{value ? value : '-'}</p>;
  }
};

export const formatMeasurementPointTableCells = (
  value,
  type,
  _searchInputValue,
  row,
  _onDropdownClick,
  _t,
  _pointerDotID,
  user,
  _onToggleClick,
  _onDeleteRow,
  _statusData,
  _handleChangeStatus,
  _onLinkFileClick,
  _selectedItem,
  _cell,
  _hasLinkDMSFilePermissions,
  _onRowClick,
  _toggleModalAction,
  onReorderClick,
  selectedMeasurementLocation
) => {
  const hasEdit = !Helpers.hasAccess({
    user,
    visibleFor: [PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].locationEdit.name],
    id: selectedMeasurementLocation?.[formConstants.fields.createdBy],
    ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].locationCreate.name,
  });

  if (type === formConstants.fields.createdAt) {
    return <p className="f-primary">{Helpers.getDateFromUnix(value, 'D MMM, YYYY')}</p>;
  } else if (type === formConstants.fields.sortUp) {
    return (
      <Icon
        size="sm"
        name="arrow-up"
        onClick={
          !hasEdit
            ? e => {
                e.stopPropagation();
                e.preventDefault();
                onReorderClick(reorderDirection.up, row.index, row[formConstants.fields.id]);
              }
            : () => null
        }
        disabled={row.index === 0 || hasEdit}
      />
    );
  } else if (type === formConstants.fields.sortDown) {
    return (
      <Icon
        size="sm"
        name="arrow-down"
        onClick={
          !hasEdit
            ? e => {
                e.stopPropagation();
                e.preventDefault();
                onReorderClick(reorderDirection.down, row.index, row[formConstants.fields.id]);
              }
            : () => null
        }
        disabled={hasEdit}
      />
    );
  } else {
    return <p className="f-primary">{value ? value : '-'}</p>;
  }
};
