import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
//components
import InspectionRenderer from '../../../left-toolbar/inspection-renderer';
import SingleRnGItemTable from '../single-item-table/single-item-table';
//constants
import { isEmpty } from 'lodash';
import AccessRenderer from '../../../../../../common/access-renderer/components/access-renderer';
import Helpers from '../../../../../../common/helpers';
import { PERMISSION_TYPES, PERMISSIONS } from '../../../../../../common/permissions-constants';
import { updateElementGeometry } from '../../../../actions/inspection-actions';
import { detailsPages, measurementTypes, modules } from '../../../../constants/constants';
import { deselectAllMeasurementLocations, selectAllMeasurementLocations, setSelectedMeasurementLocation, toggleMeasurementLocation } from '../../actions/action-creators';
import { fetchMeasurementLocations } from '../../actions/measurement-location-actions';
import { fields, MAX_SEARCH_LOCATION_CHARACTERS, viewOptions } from '../../constants/constants';
import { defaultFilter, filterParams } from '../../constants/measurement-location-constants';

const MeasurementLocationsInspectionView = (props, { t }) => {
  const {
    viewer,
    isFullScreen,
    projectID,
    measurementLocationsFilter,
    measurementLocations,
    measurementLocationsLoading,
    fetchMeasurementLocations,
    view,
    handlePathClick,
    handleBackClick,
    navigateToPath,
    path,
    measurementLocationObjectEditing,
    elementsClustered,
    queryItem,
    scrollToElement,
    selectedClusterElement,
    deselectAll,
    selectAll,
    toggleElement,
    updateGeometry,
    areAllMeasurementLocationsHidden,
    selectedMeasurementGroupId,
    setSelectedMeasurementLocation,
    user,
    handleCreateNewLocation,
    searchPlaceholder,
    defaultReadingGroupID,
    hierarchyLoading,
  } = props;

  const isLocationAndPointsView = useMemo(() => view === viewOptions.location_and_points, [view]);
  const isAllLocationsView = useMemo(() => view === viewOptions.location, [view]);
  const isSearchTextEmpty = isEmpty(measurementLocationsFilter[fields.searchText]);
  const noLocations = isEmpty(measurementLocations);
  const prevSelectedMeasurementGroupIdRef = useRef();

  useEffect(() => {
    return () => {
      setSelectedMeasurementLocation(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = useCallback(
    (filters, loadMore = false) => {
      fetchMeasurementLocations({ ...filters, [filterParams.projectID]: projectID, MeasurementGroupID: selectedMeasurementGroupId }, loadMore, ({ filters }) => {
        if (loadMore) {
          Helpers.scrollIntoView('measurement-location-table', null, -250);
        }
      });
    },
    [fetchMeasurementLocations, projectID, selectedMeasurementGroupId]
  );

  useEffect(() => {
    // Get the previous value from the ref
    const prevSelectedMeasurementGroupId = prevSelectedMeasurementGroupIdRef.current;
    // it will be triggered only on refresh of the browser since prevSelectedMeasurementGroupId has to exist in order to be able to compare it with selectedMeasurementGroupId
    if (prevSelectedMeasurementGroupId && selectedMeasurementGroupId !== prevSelectedMeasurementGroupId && selectedMeasurementGroupId !== defaultReadingGroupID) {
      fetchData(defaultFilter);
    }

    // Update the ref with the current value
    prevSelectedMeasurementGroupIdRef.current = selectedMeasurementGroupId;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMeasurementGroupId, hierarchyLoading]);

  const handleRowClick = row => {
    if (queryItem === row[fields.id]) {
      // if query item did not change
      return;
    }

    setSelectedMeasurementLocation(row);
    const newPathItem = {
      [fields.name]: row[fields.name],
      queryParams: {
        type: modules.readingsAndGauges,
        selected_item: row[fields.id],
        details: row.SystemType === measurementTypes.rgMeasurementPoint ? detailsPages.measurementPoint : detailsPages.measurementLocation,
        view: row.SystemType === measurementTypes.rgMeasurementPoint ? viewOptions.location_and_points : view,
      },
    };
    navigateToPath(newPathItem);
  };

  // do not show the button if the searchText entered
  const shouldShowButton = (isLocationAndPointsView && selectedMeasurementGroupId && noLocations && isSearchTextEmpty) || (isAllLocationsView && noLocations && isSearchTextEmpty);

  const emptyStateProps = {
    emptyStateText: t('READINGS_AND_GAUGES.MEASUREMENT_LOCATIONS.EMPTY_STATE.TITLE'),
    showButton: shouldShowButton,
    transparent: true,
    buttonText: shouldShowButton ? t('READINGS_AND_GAUGES.MEASUREMENT_LOCATIONS.EMPTY_STATE.ACTION_TEXT') : null,
    buttonAction: shouldShowButton ? () => handleCreateNewLocation(selectedMeasurementGroupId) : null,
    buttonDisabled: !Helpers.hasAccess({ user, visibleFor: [PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].locationCreate.name] }),
  };

  return (
    <AccessRenderer
      visibleFor={PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].locationEdit.name}
      id={selectedClusterElement && selectedClusterElement[fields.createdBy]}
      ownerRequiredPermission={PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].locationCreate.name}
    >
      {({ hasAccess: canEdit }) => {
        return (
          <>
            <InspectionRenderer
              deselectAll={deselectAll}
              selectAll={selectAll}
              toggleElement={toggleElement}
              deselectAllTemp={() => null}
              selectAllTemp={() => null}
              toggleElementTemp={() => null}
              updateElementGeometry={(...args) => {
                if (!canEdit) return;
                updateGeometry(...args);
              }}
              selectElement={(data, autoScroll) => {
                handleRowClick(data);
                if (autoScroll) {
                  scrollToElement(data);
                }
              }}
              elements={
                areAllMeasurementLocationsHidden && !queryItem
                  ? []
                  : elementsClustered?.map(item => {
                      if (item[fields.id] === queryItem) {
                        return { ...item, enableMove: measurementLocationObjectEditing };
                      } else {
                        return item;
                      }
                    }) || []
              }
              queryItem={queryItem}
              disableMove={!measurementLocationObjectEditing}
              viewer={viewer}
            >
              {({ elementShowHandler, elementHideHandler, elementClickHandler, selectAllHandler, deselectAllHandler }) => (
                <SingleRnGItemTable
                  searchPlaceholder={searchPlaceholder}
                  customTableClass="measurement-location-table"
                  isFullScreen={isFullScreen}
                  view={viewOptions.location}
                  handleRowClick={row => elementClickHandler(null, row)}
                  path={path}
                  navigateToPath={navigateToPath}
                  handleBackClick={handleBackClick}
                  handlePathClick={handlePathClick}
                  projectID={projectID}
                  fetchData={fetchData}
                  toggleVisibilityHandler={(e, row) => (row.visible ? elementHideHandler(e, row) : elementShowHandler(e, row))}
                  data={measurementLocations}
                  filters={measurementLocationsFilter}
                  defaultFilters={defaultFilter}
                  isDataLoading={measurementLocationsLoading}
                  selectedItem={queryItem}
                  allHidden={areAllMeasurementLocationsHidden}
                  toggleAll={() => (areAllMeasurementLocationsHidden ? selectAllHandler() : deselectAllHandler())}
                  emptyStateProps={emptyStateProps}
                  user={user}
                  maxSearchCharacters={MAX_SEARCH_LOCATION_CHARACTERS}
                />
              )}
            </InspectionRenderer>
          </>
        );
      }}
    </AccessRenderer>
  );
};

MeasurementLocationsInspectionView.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  measurementLocations: state.measurementLocationReducer.measurementLocations,
  measurementLocationsFilter: state.measurementLocationReducer.measurementLocationsFilter,
  measurementLocationsLoading: state.measurementLocationReducer.measurementLocationsLoading,
  measurementLocationObjectEditing: state.measurementLocationReducer.measurementLocationObjectEditing,
  areAllMeasurementLocationsHidden: state.measurementLocationReducer.areAllMeasurementLocationsHidden,
});

const mapDispatchToProps = dispatch => ({
  fetchMeasurementLocations: (filters, loadMore, successCallback) => dispatch(fetchMeasurementLocations(filters, loadMore, successCallback)),
  setSelectedMeasurementLocation: data => dispatch(setSelectedMeasurementLocation(data)),
  updateGeometry: data => dispatch(updateElementGeometry(data)),
  deselectAll: () => dispatch(deselectAllMeasurementLocations()),
  selectAll: () => dispatch(selectAllMeasurementLocations()),
  toggleElement: id => dispatch(toggleMeasurementLocation(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MeasurementLocationsInspectionView);
