import { debounce, get, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import TableTemplate from '../../../../../../../common/double-table/components/table-template';
import Helpers from '../../../../../../../common/helpers';
import {
  deleteMeasurementReading,
  getMeasurementReadingDMSFilesUploaded,
  getMeasurementReadings,
  saveMeasurementReading,
  updateMeasurementReading,
} from '../../../actions/measurement-readings-actions';
import { formatMeasurementReadingsList } from '../../../actions/table-formatting';
import { defaultFilter, fields, filterParams, formConstants as measurementReadingFields, tableConfig } from '../../../constants/time-series-graph-modal-constants';

import Modal from '../../../../../../../common/modal/components/modal';
import { sortDirections } from '../../../constants/measurement-location-constants';

import { withRouter } from 'react-router';
import { initialize, isDirty } from 'redux-form';
import { FORMS } from '../../../../../../../common/constants';
import EmptyState from '../../../../../../../common/empty-state-v2/components/empty-state';
import Button from '../../../../../../../common/form/components/button';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../../../../common/permissions-constants';
import ActionModal from '../../../../../../document-management/components/modals/action-modal/action-modal';
import { getDefectDetails } from '../../../../../actions/inspection-actions';
import { measurementTypes, modules } from '../../../../../constants/constants';
import EditModuleItemFilesModal from '../../../../right-toolbar/common/edit-module-item-files-modal';
import { formConstants } from '../../../../right-toolbar/readings-and-gauges/constants/measurement-point-constants';
import { updateMeasurementLocationInArray, updateMeasurementPointInArray } from '../../../actions/action-creators';
import { MAX_MEASUREMENT_READING_FILES } from '../../../constants/constants';
import { measurementReadingFormConstants } from '../../../constants/measurement-readings-constants';
import '../../../styles/measurement-reading-list.scss';
import CreateMeasurementReadingModal from '../../measurement-reading/create-measurement-reading-modal';

const MeasurementReadings = (
  {
    getMeasurementReadings,
    measurementPointID,
    selectedMeasurementPoint,
    saveMeasurementReading,
    updateMeasurementReading,
    deleteMeasurementReading,
    user,
    router,
    isMeasurementReadingFormDirty,
    getDefectDetails,
    updateMeasurementPointInArray,
    updateMeasurementLocationInArray,
    initializeMeasurementReadingForm,
    defaultReadingsAndGaugesUnassignedDMSFolderID,
    getMeasurementReadingDMSFilesUploaded,
    location,
  },
  { t }
) => {
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState({ isOpen: false });
  const [filesModalData, setFilesModalData] = useState({ isOpen: false });
  const [filters, setFilters] = useState({ ...defaultFilter, [filterParams.measurementPointID]: measurementPointID });
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [MRRequestInProgress, setMRRequestInProgress] = useState(false);
  const [confirmLeaveMRModal, setConfirmLeaveMRModal] = useState({ isOpen: false });
  const canCreate = useMemo(() => Helpers.hasAccess({ user, visibleFor: PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].readingCreate.name }), [user]);
  const { query } = location;
  const inspectionId = parseInt(get(query, 'inspection_id'), 10);
  const projectId = parseInt(get(query, 'project_id'), 10);

  useEffect(() => {
    fetchData(filters);
    //eslint-disable-next-line
  }, []);

  const fetchData = useCallback(
    (filters, loadMore = false) => {
      const setNewData = newData => {
        const dataToSet = loadMore ? uniqBy([...data, ...newData], fields.id) : newData;
        setData(dataToSet);
      };
      const setDataLoaded = isLoading => {
        setIsDataLoading(isLoading);
        if (loadMore && !isLoading) {
          Helpers.scrollIntoView('measurement-readings-table', `row-${filters[filterParams.lastSeen] - 1}`, -250);
        }
      };
      getMeasurementReadings(filters, setNewData, setFilters, null, setDataLoaded);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchTextChanged = useCallback(
    debounce(searchText => fetchData({ ...filters, [fields.lastSeen]: 0, [fields.searchText]: searchText }), 300),
    [fetchData]
  );

  const handleSearchInputChange = e => {
    const searchText = e.target.value;
    setSearchText(searchText);
    searchTextChanged(searchText);
  };

  const handleLoadMoreClick = () => fetchData(filters, true);

  const handleSortClick = sortByColumn => {
    fetchData({
      ...filters,
      [fields.lastSeen]: 0,
      [fields.sortDirection]: filters[fields.sortDirection] === sortDirections.ASC ? sortDirections.DESC : sortDirections.ASC,
      [fields.sortByColumn]: sortByColumn,
    });
  };

  const closeModal = () => {
    setModalData({ isOpen: false });
  };

  const updateTriggeredAlarms = measurementPoint => {
    getDefectDetails(
      { [formConstants.fields.id]: measurementPoint?.[formConstants.fields.id], SystemType: measurementTypes.rgMeasurementPoint },
      measurementPointDetails => {
        const updatedMeasurementPoint = Helpers.updateMeasurementPointAlarmStatus(measurementPointDetails);
        updateMeasurementPointInArray(updatedMeasurementPoint, 'update');
      },
      false
    );

    getDefectDetails(
      { [formConstants.fields.id]: selectedMeasurementPoint?.[formConstants.fields.measurementLocationID], SystemType: measurementTypes.rgMeasurementLocation },
      measurementLocationDetails => {
        updateMeasurementLocationInArray(measurementLocationDetails, 'update');
      },
      false
    );
  };

  const handleSaveMeasurementReading = (data, measurementReadingId, isEdit, stepCallback) => {
    const dateField = !isEdit ? [measurementReadingFormConstants.fields.date] : [measurementReadingFields.fields.measurementDate];
    const values = data?.[measurementReadingFormConstants.fields.values]?.map(str => parseFloat(str));
    const date = Helpers.dateToUnix(data[dateField]);
    let updatedMeasurementReadingId = measurementReadingId || data[fields.id];

    setMRRequestInProgress(true);
    if (isEdit) {
      updateMeasurementReading(
        values,
        date,
        selectedMeasurementPoint[formConstants.fields.id],
        selectedMeasurementPoint[formConstants.fields.measurementLocationID],
        updatedMeasurementReadingId,
        measurementReadingValuesData => {
          setMRRequestInProgress(false);
          stepCallback && typeof stepCallback === 'function' && stepCallback(measurementReadingValuesData);
          initializeMeasurementReadingForm(data); // initialize the form with the data, makes the form pristine again
        },
        () => {
          setMRRequestInProgress(false);
        }
      );
    } else {
      saveMeasurementReading(
        values,
        date,
        selectedMeasurementPoint[formConstants.fields.id],
        selectedMeasurementPoint[formConstants.fields.measurementLocationID],
        updatedMeasurementReadingId,
        measurementReadingValuesData => {
          setMRRequestInProgress(false);
          stepCallback && typeof stepCallback === 'function' && stepCallback(measurementReadingValuesData);
          initializeMeasurementReadingForm(data); // initialize the form with the data, makes the form pristine again
        },
        () => {
          setMRRequestInProgress(false);
        }
      );
    }
  };

  const handleOpenAddModal = () => {
    setModalData({
      isOpen: true,
      isEdit: false,
      customClassName: 'alarms-edit-modal modal-large',
      customClassWrapperName: 'alarms-edit-modal__picker',
      type: '',
      CustomContent: dynamicProps => <CreateMeasurementReadingModal {...dynamicProps} />,
      closeAction: closeModal,
      title: t('READINGS_AND_GAUGES.MEASUREMENT_READINGS.ADD_MEASUREMENT_READING'),
      customCloseAction: closeModal,
      onSubmit: (data, isEdit, stepCallback) => handleSaveMeasurementReading(data, null, isEdit, stepCallback),
      measurementPointId: measurementPointID,
      router,
    });
  };

  const emptyStateProps = {
    emptyStateText: t('MP.TIME_SERIES_GRAPH_MODAL_TAB.MEASUREMENT_READINGS.EMPTY_STATE'),
    showButton: true,
    transparent: true,
    buttonText: t('READINGS_AND_GAUGES.MEASUREMENT_READINGS.ADD_MEASUREMENT_READING'),
    buttonAction: handleOpenAddModal,
    buttonDisabled: !canCreate,
  };

  const handleOpenEditModal = measurementReading => {
    setModalData({
      isOpen: true,
      customClassName: 'alarms-edit-modal modal-large',
      customClassWrapperName: 'alarms-edit-modal__picker',
      type: '',
      CustomContent: dynamicProps => <CreateMeasurementReadingModal {...dynamicProps} />,
      closeAction: closeModal,
      title: t('READINGS_AND_GAUGES.MEASUREMENT_READINGS.EDIT_MEASUREMENT_READING'),
      customCloseAction: closeModal,
      onSubmit: (data, _isEdit, stepCallback) => handleSaveMeasurementReading(data, measurementReading[fields.id], true, stepCallback),
      measurementPointId: measurementPointID,
      initialValues: measurementReading,
      isEdit: true,
      router,
    });
  };

  const closeConfirmLeaveModalFetchDetails = () => {
    setConfirmLeaveMRModal({ isOpen: false });
    fetchData({
      ...filters,
      [fields.lastSeen]: 0,
    });
  };

  const closeAllLeaveModalsFetchDetails = () => {
    setConfirmLeaveMRModal({ isOpen: false });
    setModalData({ isOpen: false });
    fetchData({
      ...filters,
      [fields.lastSeen]: 0,
    });
    updateTriggeredAlarms(selectedMeasurementPoint);
  };

  const handleCloseMeasurementReading = (isMeasurementReadingFormDirty, isEdit) => {
    if (isMeasurementReadingFormDirty) {
      const title = isEdit ? 'READINGS_AND_GAUGES.MEASUREMENT_READINGS_FORM_EDIT.LEAVE' : 'READINGS_AND_GAUGES.MEASUREMENT_READINGS_FORM_ADD.LEAVE';
      const firstParagraph = isEdit ? 'READINGS_AND_GAUGES.MEASUREMENT_READINGS_FORM_EDIT.UNSAVED_CHANGES' : 'READINGS_AND_GAUGES.MEASUREMENT_READINGS_FORM_ADD.UNSAVED_CHANGES';
      setConfirmLeaveMRModal({
        isOpen: true,
        CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
        closeAction: () => closeConfirmLeaveModalFetchDetails(),
        type: '',
        title: t(title),
        confirmButtonText: 'LEAVE',
        closeButtonText: 'CANCEL',
        firstParagraph: firstParagraph,
        secondParagraph: 'READINGS_AND_GAUGES.EDIT_GROUP_UNSAVED_CHANGES_PARAGRAPH_2',
        customCloseAction: () => setConfirmLeaveMRModal({ isOpen: false }),
        customConfirmAction: () => closeAllLeaveModalsFetchDetails(),
        customClassName: 'confirm-leave-modal modal-large',
        customClassWrapperName: 'confirm-leave-modal__picker',
      });
    } else {
      setModalData({ isOpen: false });
    }
  };

  const toggleConfirmationModal = (isOpen, title, firstParagraph, confirmAction) => {
    if (isOpen) {
      setModalData({
        isOpen: true,
        type: '',
        firstParagraph: firstParagraph,
        title: title,
        CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
        customClassName: 'delete-reading-modal modal-large',
        customClassWrapperName: 'delete-reading-modal__picker',
        closeButtonText: t('CANCEL'),
        customConfirmAction: () => {
          confirmAction();
        },
        confirmButtonText: title,
        customCloseAction: () => toggleConfirmationModal(false),
      });
    } else {
      setModalData({ isOpen: false });
    }
  };

  const handleOpenDeleteModal = group => {
    const handleDelete = () => {
      deleteMeasurementReading(group[fields.id], () => {
        fetchData({
          ...filters,
          [fields.lastSeen]: 0,
        });
        toggleConfirmationModal(false);
      });
    };

    const openConfirmModal = () => {
      setModalData({
        isOpen: true,
        customClassName: 'delete-reading-modal modal-large',
        customClassWrapperName: 'delete-reading-modal__picker',
        type: '',
        CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
        closeAction: closeModal,
        title: t('READINGS_AND_GAUGES.CONFIRM_DELETE'),
        firstParagraph: 'READINGS_AND_GAUGES.CONFIRM_DELETE_WARNING',
        customCloseAction: closeModal,
        confirmButtonText: t('CONFIRM'),
        closeButtonText: t('CANCEL'),
        customConfirmAction: handleDelete,
      });
    };

    toggleConfirmationModal(
      true,
      t('READINGS_AND_GAUGES.MEASUREMENT_READINGS.DELETE_MEASUREMENT_READING'),
      t('READINGS_AND_GAUGES.MEASUREMENT_READINGS.DELETE_MODAL.DESC', { name: group[measurementReadingFields.fields.averageValue] }),
      openConfirmModal
    );
  };

  const handleOpenFilesModal = async row => {
    if (!row) return;
    await getMeasurementReadingDMSFilesUploaded(row[fields.id]);
    setFilesModalData({
      isOpen: true,
      customClassName: 'reading-files-modal modal-large',
      customClassWrapperName: 'reading-files-modal__picker',
      type: '',
      CustomContent: dynamicProps => (
        <>
          <EditModuleItemFilesModal
            projectDMSCategories={[
              { [fields.id]: defaultReadingsAndGaugesUnassignedDMSFolderID, [fields.name]: 'MEASUREMENT_READING.READINGS_FILES', [fields.description]: 'MEASUREMENT_READING.READINGS_FILES.MAX' },
            ]}
            selectedModuleItem={row}
            moduleType={modules.readingsAndGauges}
            maxFiles={MAX_MEASUREMENT_READING_FILES}
            overrideModuleTypeWithProp
            {...dynamicProps}
          />
          <div className="buttons-wrapper">
            <Button onClick={() => setFilesModalData({ isOpen: false })} type="button" text={t('CLOSE')} width="xs" variant="gray-outline" className="modal-button-custom h-md" />
          </div>
        </>
      ),
      title: t('MP.TIME_SERIES_GRAPH_MODAL_TAB.MEASUREMENT_READINGS.FILES'),
      onSubmit: () => null,
      customCloseAction: () => setFilesModalData({ isOpen: false }),
      closeAction: () => setFilesModalData({ isOpen: false }),
    });
  };

  const menuOptions = item => {
    return [
      {
        title: 'EDIT',
        action: group => handleOpenEditModal(group),
        access: {
          visibleFor: PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].readingEdit.name,
          id: item[measurementReadingFields.fields.measuredByID],
          ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].readingCreate.name,
        },
      },
      {
        title: 'READINGS_AND_GAUGES.MEASUREMENT_READINGS.DELETE_MEASUREMENT_READING',
        isHighlighted: true,
        action: group => handleOpenDeleteModal(group),
        access: {
          visibleFor: PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].readingDelete.name,
        },
      },
    ];
  };

  const withMenuOptions =
    (formatFunction, menuOptions) =>
    (
      value,
      type,
      searchInputValue,
      row,
      onDropdownClick,
      t,
      pointerDotID,
      user,
      onToggleClick,
      onDeleteRow,
      statusData,
      handleChangeStatus,
      onLinkFileClick,
      selectedItem,
      cell,
      hasLinkDMSFilePermissions,
      onRowClick,
      toggleModalAction,
      rowIndex,
      defaultUnassignedDMSFolderID
    ) => {
      return formatFunction(
        value,
        type,
        searchInputValue,
        row,
        onDropdownClick,
        t,
        pointerDotID,
        user,
        onToggleClick,
        onDeleteRow,
        statusData,
        handleChangeStatus,
        onLinkFileClick,
        selectedItem,
        cell,
        hasLinkDMSFilePermissions,
        onRowClick,
        toggleModalAction,
        menuOptions,
        rowIndex,
        defaultUnassignedDMSFolderID,
        inspectionId,
        projectId
      );
    };

  const handleFormatCells = withMenuOptions(formatMeasurementReadingsList, menuOptions);

  return (
    <div className="measurement-readings">
      <TableTemplate
        data={data}
        handleFormatCells={handleFormatCells}
        tableConfig={tableConfig}
        filters={filters}
        sortByColumn={filterParams.sortByColumn}
        sortDirection={filterParams.sortDirection}
        searchPlaceholder={t('SEARCH')}
        loadMoreOnClick={handleLoadMoreClick}
        onSortClick={handleSortClick}
        searchInputOnChange={handleSearchInputChange}
        isLoading={isDataLoading}
        searchTerm={searchText}
        hasNext={filters[filterParams.hasNext]}
        TotalItems={filters[filterParams.totalItems]}
        customTableClass="measurement-readings-table"
        addButtonDisabled={!canCreate}
        addButtonText={t('MP.TIME_SERIES_GRAPH_MODAL_TAB.NEW_MEASUREMENT_READING')}
        addButtonAction={handleOpenAddModal}
        onDropdownClick={() => null}
        onRowClick={() => null}
        emptyStateComponent={() => <EmptyState {...emptyStateProps} />}
        toggleModalAction={handleOpenFilesModal}
        user={user}
      />
      <Modal
        {...modalData}
        requestInProgress={MRRequestInProgress}
        closeAction={() => handleCloseMeasurementReading(isMeasurementReadingFormDirty, modalData?.isEdit)}
        customCloseAction={() => handleCloseMeasurementReading(isMeasurementReadingFormDirty, modalData?.isEdit)}
        closeConfirmModalAction={() => closeAllLeaveModalsFetchDetails()}
        dirty={isMeasurementReadingFormDirty}
      />
      <Modal {...confirmLeaveMRModal} />
      <Modal {...filesModalData} />
    </div>
  );
};

MeasurementReadings.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  selectedMeasurementPoint: state.inspectionReducer.selectedDefect,
  user: state.userReducer,
  isMeasurementReadingFormDirty: isDirty(FORMS.measurementPointMeasurementReadingForm)(state),
  defaultReadingsAndGaugesUnassignedDMSFolderID: state.projectDetailsReducer.DefaultReadingsAndGaugesUnassignedDMSFolderID,
});

const mapDispatchToProps = dispatch => ({
  getMeasurementReadings: (filters, callback, filtersCallback, errorCallback, loadingCallback) => dispatch(getMeasurementReadings(filters, callback, filtersCallback, errorCallback, loadingCallback)),
  saveMeasurementReading: (values, date, MeasurementPointID, MeasurementLocationID, MeasurementReadingID, successCallback, errorCallback) =>
    dispatch(saveMeasurementReading(values, date, MeasurementPointID, MeasurementLocationID, MeasurementReadingID, successCallback, errorCallback)),
  updateMeasurementReading: (values, date, MeasurementPointID, MeasurementLocationID, MeasurementReadingID, successCallback, errorCallback) =>
    dispatch(updateMeasurementReading(values, date, MeasurementPointID, MeasurementLocationID, MeasurementReadingID, successCallback, errorCallback)),
  deleteMeasurementReading: (MeasurementReadingID, successCallback, errorCallback) => dispatch(deleteMeasurementReading(MeasurementReadingID, successCallback, errorCallback)),
  getDefectDetails: (defect, callback, saveItem) => dispatch(getDefectDetails(defect, callback, {}, false, saveItem)),
  updateMeasurementPointInArray: (data, method) => dispatch(updateMeasurementPointInArray(data, method)),
  updateMeasurementLocationInArray: (data, method) => dispatch(updateMeasurementLocationInArray(data, method)),
  initializeMeasurementReadingForm: data => dispatch(initialize(FORMS.measurementPointMeasurementReadingForm, data)),
  getMeasurementReadingDMSFilesUploaded: id => dispatch(getMeasurementReadingDMSFilesUploaded(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MeasurementReadings));
