import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Tools } from '@tarik.djurdjevic/react-sketch';
import PDFWithDrawing from '../../../../common/pdf-viewer/components/pdf-with-drawing';

import { get, isEmpty } from 'lodash';
import SimpleLoader from '../../../../common/global-loader/components/simple-loader';
import Helpers from '../../../../common/helpers';

import { setPdfPageNumber } from '../../../pdf-tag/actions/action-creators';
import { changePDFPage } from '../../../pdf-tag/actions/pdf-tag-actions';
import { componentFields, componentFilterValues, defaultRectangle, filterValues, getSelectedComponentProps, getSelectedRectangleProps } from '../../../pdf-tag/constants/constants';

import '../../styles/component-pdf.scss';

const objectPropsToInclude = ['strokeUniform', 'noScaleCache', 'angle', 'transparentCorners', 'hasRotatingPoint'];

class ComponentPDF extends Component {
  constructor(props) {
    super(props);
    this.sketchRef = null;
  }

  setSketchRef = ref => {
    if (ref) {
      this.sketchRef = ref;
      this.updateDrawings();
    }
  };

  componentWillUnmount() {
    const { setPdfPageNumber } = this.props;
    setPdfPageNumber(1);
  }

  updateDrawings = () => {
    const {
      selectedComponent,
      components,
      userReducer: { Theme },
      selectedDrawing,
      isEditingDrawing,
    } = this.props;

    if (this.sketchRef !== null) {
      let canvas = this.sketchRef._fc;
      // objects are all drawings rendered on canvas
      let objects = canvas.getObjects();

      const hasCv = selectedComponent && selectedComponent[componentFields.cvFoundID] > 0;
      const hasId = selectedComponent && selectedComponent[componentFields.componentId] > 0;
      const selectedComponentDrawings = selectedComponent
        ? (components || []).find(item => {
            if (hasCv && hasId) {
              return item[componentFields.cvFoundID] === selectedComponent[componentFields.cvFoundID] && item[componentFields.componentId] === selectedComponent[componentFields.componentId];
            } else if (hasCv) {
              return item[componentFields.cvFoundID] === selectedComponent[componentFields.cvFoundID];
            }
            return item[componentFields.componentId] === selectedComponent[componentFields.componentId];
          })
        : null;

      const selectedObjects =
        selectedComponentDrawings && selectedComponentDrawings[componentFields.drawings] && selectedComponentDrawings[componentFields.drawings].length > 0
          ? selectedComponentDrawings[componentFields.drawings]
          : null;

      if (!selectedObjects && (!objects || isEmpty(objects))) return;

      let selectedDrawingObject = null;
      if (selectedDrawing && !isEmpty(selectedDrawing) && selectedDrawing.ID) {
        if (typeof selectedDrawing.Drawing === 'string') {
          selectedDrawingObject = JSON.parse(selectedDrawing);
        } else selectedDrawingObject = selectedDrawing;
      }

      for (let i = 0; i < objects.length; i++) {
        const element = objects[i];
        /* The code is checking if the current element in the loop is one of the selected objects. It
        does this by iterating over the `selectedObjects` array and using the `find` method to check
        if any of the objects in `selectedObjects` have a `ID` property that matches the `ID` of
        the current element. */
        if (selectedObjects && selectedObjects.find(i => i.ID === element.ID)) {
          if (element.ID === selectedDrawingObject?.ID) {
            // component is selected and its drawing
            if (isEditingDrawing) {
              element.set({
                ...defaultRectangle,
                ...getSelectedRectangleProps(true, Theme),
              });
            } else {
              element.set({
                ...defaultRectangle,
                ...getSelectedRectangleProps(false, Theme),
              });
            }
          } else {
            // component is selected not this drawing
            element.set({
              ...defaultRectangle,
              ...getSelectedComponentProps(true, Theme),
            });
          }
        } else {
          element.set({
            ...defaultRectangle,
            ...getSelectedComponentProps(false, Theme),
          });
        }
      }

      canvas.requestRenderAll();
    }
  };

  componentDidUpdate(prevProps) {
    const { selectedComponent, selectedDrawing, componentsLoading } = this.props;

    if (prevProps.selectedComponent !== selectedComponent || prevProps.selectedDrawing !== selectedDrawing || componentsLoading !== prevProps.componentsLoading) {
      this.updateDrawings();
    }
  }

  onDrawingAdded = () => {
    const { handleDrawingAdd, selectedComponent, components } = this.props;
    const { sketchRef } = this;
    const selectedComponentDrawings = selectedComponent ? components.find(item => item[componentFields.componentId] === selectedComponent[componentFields.componentId]) : null;
    const savedSketch = Object.assign({}, sketchRef.toJSON(objectPropsToInclude));

    if (sketchRef && sketchRef._container && selectedComponentDrawings) {
      let { offsetWidth, clientHeight } = sketchRef._container;
      const { pageNumber } = sketchRef.props;

      savedSketch.canvasWidth = offsetWidth;
      savedSketch.canvasHeight = clientHeight;
      if (handleDrawingAdd && selectedComponentDrawings[componentFields.drawings]) {
        let newObjects = [];
        // previous objects
        (selectedComponentDrawings[componentFields.drawings] || []).forEach(drawing => {
          newObjects.push(drawing.Drawing);
        });
        // newly created object
        newObjects.push({
          ...savedSketch.objects[savedSketch.objects.length - 1],
          uuid: Helpers.uuid4(),
          componentId: selectedComponent.ID,
          selectable: false,
          pageNumber: pageNumber,
        });
        const latestDrawingObject = newObjects[newObjects.length - 1];
        handleDrawingAdd({ ...savedSketch, objects: [latestDrawingObject] }, pageNumber);
      }
    }
  };

  onDrawingEdited = newObject => {
    const { handleDrawingEdit, selectedComponent, components, selectedDrawing } = this.props;
    const { sketchRef } = this;

    if (!newObject) return;

    let objectToSave = null;
    const selectedComponentDrawings = selectedComponent ? components.find(item => item[componentFields.componentId] === selectedComponent[componentFields.componentId]) : null;
    const savedSketch = Object.assign({}, sketchRef.toJSON(objectPropsToInclude));

    if (sketchRef && sketchRef._container && selectedComponentDrawings) {
      let { offsetWidth, clientHeight } = sketchRef._container;
      const { pageNumber } = sketchRef.props;

      savedSketch.canvasWidth = offsetWidth;
      savedSketch.canvasHeight = clientHeight;
      if (handleDrawingEdit && selectedComponentDrawings[componentFields.drawings]) {
        let newObjects = [];

        (selectedComponentDrawings[componentFields.drawings] || []).forEach(object => {
          const parsedDrawing = JSON.parse(object.Drawing);
          const drawingObject = get(parsedDrawing, 'objects[0]');
          if (!drawingObject || !drawingObject.uuid) return;
          drawingObject.top = newObject.top;
          drawingObject.left = newObject.left;
          drawingObject.scaleX = newObject.scaleX;
          drawingObject.scaleY = newObject.scaleY;
          drawingObject.fill = '';
          drawingObject.pageNumber = pageNumber;
          drawingObject.uuid = newObject.uuid;
          drawingObject.ID = object.ID;

          if (selectedDrawing && selectedDrawing.ID === drawingObject.ID && newObject.ID === selectedDrawing.ID) {
            objectToSave = {
              objects: [drawingObject],
              ID: object.ID,
              top: newObject.top,
              left: newObject.left,
              scaleX: newObject.scaleX,
              scaleY: newObject.scaleY,
              fill: '',
              pageNumber: pageNumber,
              uuid: newObject.uuid,
              canvasWidth: savedSketch.canvasWidth,
              canvasHeight: savedSketch.canvasHeight,
            };
            newObjects.push(objectToSave);
          }
        });

        if (!isEmpty(objectToSave)) {
          handleDrawingEdit({ ...savedSketch, objects: newObjects }, objectToSave);
        }
      }
    }
  };

  changePageHandler = offset => {
    const { inspectionId, inspectionIdReducer, fileId, filter, selectedComponent, pdfPageNumber, file, showOnlyConfirmedDrawings = false, changePDFPage } = this.props;
    const { FileID } = file;
    const newPageNumber = pdfPageNumber + offset;
    // checks if fileId comes from SmartDrawing module or from other module
    const activeFileId = fileId ? fileId : FileID;
    if (!activeFileId) return;
    // checks if inspectionId is propagated from HOC, if not takes it from reducer
    const activeInspectionId = inspectionId ? inspectionId : inspectionIdReducer;
    const defaultFilter = {
      [filterValues.searchText]: '',
      [filterValues.componentFilter]: componentFilterValues.cvLinked,
    };
    const newFilter = {
      ...defaultFilter,
      ...filter,
    };

    changePDFPage(newPageNumber, activeInspectionId, activeFileId, newFilter, selectedComponent, showOnlyConfirmedDrawings);
  };

  render() {
    const {
      severityColors,
      selectedComponent,
      selectComponent,
      selectDrawing,
      file,
      components,
      selectedTool,
      enableDrawing,
      componentsLoading,
      pdfPopupLoading,
      isEditingDrawing,
      toggleEditMode,
      editEnabled,
      pdfPageNumber,
      changePageHandler,
      alignPDFLeft,
      filter,
      isLoadingTags,
      ...restProps
    } = this.props;
    const { t } = this.context;

    const sketchProps = enableDrawing
      ? {
          onMouseUp: () => {
            if (selectedTool === Tools.Rectangle) {
              this.onDrawingAdded();
            }
          },
          onObjectMoving: () => null,
          onObjectModified: e => {
            if (selectedTool === Tools.Select) {
              this.onDrawingEdited(e.target);
            }
          },
          onObjectAdded: () => null,
          ref: componentsLoading ? null : this.setSketchRef,
        }
      : {
          ref: componentsLoading ? null : this.setSketchRef,
        };

    return (
      <div>
        {isLoadingTags && (
          <div className="loading-tags-overlay">
            <SimpleLoader isLoading={true} className="pdf-with-drawing__loader" customText={t('LOADING_TAGS')} />
          </div>
        )}
        {!isLoadingTags && (
          <PDFWithDrawing
            severityColors={severityColors}
            drawings={components}
            selectedTool={selectedTool}
            sketchProps={sketchProps}
            drawingsLoading={componentsLoading || pdfPopupLoading}
            onClick={(_e, _sketchRef, clickedObject) => {
              selectComponent && selectComponent(clickedObject);
              selectDrawing && selectDrawing(clickedObject);
            }}
            pdfViewerProps={{ className: 'full-size', file: file.URL }}
            isEditingDrawing={isEditingDrawing}
            toggleEditMode={() =>
              toggleEditMode(() => {
                if (this.sketchRef !== null) {
                  let canvas = this.sketchRef._fc;
                  let objects = canvas.getObjects();

                  for (let i = 0; i < objects.length; i++) {
                    const element = objects[i];
                    element.evented = true;
                  }
                }
              })
            }
            editEnabled={editEnabled}
            includeLoader
            pageNumber={pdfPageNumber}
            changePage={this.changePageHandler}
            alignPDFLeft={alignPDFLeft}
            filter={filter}
            {...restProps}
          />
        )}
      </div>
    );
  }
}

ComponentPDF.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  severityColors: state.themeReducer.severityColors,
  selectedComponent: state.inspectionReducer.selectedDefect,
  viewer: state.potreeReducer.viewerInstance,
  userReducer: state.userReducer,
  inspectionIdReducer: state.inspectionReducer.inspectionID,
  pdfPageNumber: state.pdfTagReducer.pdfPageNumber,
  components: state.pdfTagReducer.pdfComponents,
  pdfPopupLoading: state.pdfTagReducer.pdfPopupLoading,
});

const mapDispatchToProps = dispatch => ({
  setPdfPageNumber: pageNumber => dispatch(setPdfPageNumber(pageNumber)),
  changePDFPage: (newPDFPageNumber, inspectionId, fileId, filter, selectedComponent, showOnlyConfirmedDrawings, callback) =>
    dispatch(changePDFPage(newPDFPageNumber, inspectionId, fileId, filter, selectedComponent, showOnlyConfirmedDrawings, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComponentPDF);
