import { augmentedRealityVideoListFields } from './augmented-reality-constant';

export const roboticLiveStreamsTableConfig = [
  {
    title: '',
    key: augmentedRealityVideoListFields.icon,
    type: augmentedRealityVideoListFields.icon,
    class: 'icon-column',
    isSort: false,
  },
  {
    title: 'ROBOTIC_LIVE_STREAMS.TABLE.NAME',
    key: augmentedRealityVideoListFields.name,
    type: augmentedRealityVideoListFields.name,
    class: 'name-column',
    isSort: true,
  },
  {
    title: 'ROBOTIC_LIVE_STREAMS.TABLE.STARTED_AT',
    key: augmentedRealityVideoListFields.startedAt,
    type: augmentedRealityVideoListFields.startedAt,
    class: 'started-at-column',
    isSort: true,
  },
];

export const filterProps = {
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  perPage: 'PerPage',
  lastSeen: 'LastSeen',
  searchText: 'SearchText',
  hasNext: 'HasNext',
  totalItems: 'TotalItems',
};

export const sortDirections = {
  asc: 'ASC',
  desc: 'DESC',
};

export const defaultFilters = {
  [filterProps.sortByColumn]: augmentedRealityVideoListFields.createdAt,
  [filterProps.sortDirection]: sortDirections.desc,
  [filterProps.perPage]: 20,
  [filterProps.lastSeen]: 0,
  [filterProps.searchText]: '',
  [filterProps.hasNext]: false,
  [augmentedRealityVideoListFields.isPostProcessed]: false,
  [augmentedRealityVideoListFields.isLive]: true,
};
