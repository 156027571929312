import { formConstants, observationNameMaxChars, observationNameMinChars, textAreaMaxChars } from '../../../constants/defect-constants';

export const validate = values => {
  const errors = {};

  if (!values[formConstants.fields.name] || values[formConstants.fields.name].length < observationNameMinChars) {
    errors[formConstants.fields.name] = {
      string: 'DEFECT_FORM.ERROR.NAME_LENGTH_MIN',
      params: { systemType: 'Observation' },
    };
  }
  if (values[formConstants.fields.name] && values[formConstants.fields.name].length > observationNameMaxChars) {
    // 250 max char is on the Database level
    errors[formConstants.fields.name] = {
      string: 'DEFECT_FORM.ERROR.NAME_LENGTH_MAX_CHARACTERS',
      params: { systemType: 'Observation', maximumCharacters: observationNameMaxChars },
    };
  }
  if (values[formConstants.fields.description] && values[formConstants.fields.description].length > textAreaMaxChars) {
    errors[formConstants.fields.description] = 'DEFECT_FORM.ERROR.DESCRIPTION_TOO_LONG';
  }
  if (values[formConstants.fields.recommendation] && values[formConstants.fields.recommendation].length > textAreaMaxChars) {
    errors[formConstants.fields.recommendation] = 'DEFECT_FORM.ERROR.RECOMMENDATION_TOO_LONG';
  }
  if (values[formConstants.fields.resolvedComment] && values[formConstants.fields.resolvedComment].length > textAreaMaxChars) {
    errors[formConstants.fields.resolvedComment] = 'DEFECT_FORM.ERROR.RECOMMENDATION_TOO_LONG';
  }

  return errors;
};
