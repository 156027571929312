import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
//components
import InspectionRenderer from '../../../left-toolbar/inspection-renderer';
import SingleRnGItemTable from '../single-item-table/single-item-table';
//constants
import { isEmpty } from 'lodash';
import { initialize, isDirty } from 'redux-form';
import AccessRenderer from '../../../../../../common/access-renderer/components/access-renderer';
import { FORMS } from '../../../../../../common/constants';
import EmptyState from '../../../../../../common/empty-state-v2/components/empty-state';
import Helpers from '../../../../../../common/helpers';
import Modal from '../../../../../../common/modal/components/modal';
import { PERMISSION_TYPES, PERMISSIONS } from '../../../../../../common/permissions-constants';
import ActionModal from '../../../../../document-management/components/modals/action-modal/action-modal';
import { getDefectDetails, updateElementGeometry } from '../../../../actions/inspection-actions';
import { detailsPages, measurementTypes, modules } from '../../../../constants/constants';
import {
  deselectAllMeasurementPoints,
  selectAllMeasurementPoints,
  setMeasurementPoints,
  toggleMeasurementPoint,
  updateMeasurementLocationInArray,
  updateMeasurementPointInArray,
} from '../../actions/action-creators';
import { fetchMeasurementPoints } from '../../actions/measurement-point-actions';
import { saveMeasurementReading, updateMeasurementReading } from '../../actions/measurement-readings-actions';
import { fields, MAX_SEARCH_POINT_CHARACTERS, viewOptions } from '../../constants/constants';
import { formConstants } from '../../constants/measurement-location-constants';
import { defaultFilter, filterParams } from '../../constants/measurement-point-constants';
import { measurementReadingFormConstants } from '../../constants/measurement-readings-constants';
import { formConstants as measurementReadingFields } from '../../constants/time-series-graph-modal-constants';
import CreateMeasurementReadingModal from '../measurement-reading/create-measurement-reading-modal';

const MeasurementPointsInspectionView = (props, { t }) => {
  const {
    viewer,
    isFullScreen,
    projectID,
    measurementPointsFilter,
    measurementPoints,
    measurementPointsLoading,
    fetchMeasurementPoints,
    view,
    handlePathClick,
    handleBackClick,
    navigateToPath,
    path,
    measurementPointLocationObjectEditing,
    elementsClustered,
    queryItem,
    scrollToElement,
    selectedClusterElement,
    deselectAll,
    selectAll,
    toggleElement,
    updateGeometry,
    areAllMeasurementPointsHidden,
    selectedMeasurementLocation,
    showPath = true,
    user,
    handleOpenCreateMeasurementPointModal,
    saveMeasurementReading,
    searchPlaceholder,
    setMeasurementPoints,
    router,
    isMeasurementReadingFormDirty,
    updateMeasurementPointInArray,
    getDefectDetails,
    updateMeasurementLocationInArray,
    selectedMeasurementPoint,
    initializeMeasurementReadingForm,
  } = props;
  const [modalData, setModalData] = useState({
    isOpen: false,
  });
  const [confirmationModalData, setConfirmationModalData] = useState({ isOpen: false });

  const [MRRequestInProgress, setMRRequestInProgress] = useState(false);
  const isLocationAndPointsView = useMemo(() => view === viewOptions.location_and_points, [view]);

  const fetchData = useCallback(
    (filters, loadMore = false) => {
      if (isLocationAndPointsView && !selectedMeasurementLocation) {
        return;
      }
      fetchMeasurementPoints({ ...filters, [filterParams.projectID]: projectID, MeasurementLocationID: selectedMeasurementLocation?.[formConstants.fields.id] }, loadMore, ({ filters }) => {
        if (loadMore) {
          Helpers.scrollIntoView('measurement-points-table', null, -250);
        }
      });
    },
    [fetchMeasurementPoints, projectID, selectedMeasurementLocation, isLocationAndPointsView]
  );

  useEffect(() => {
    if (selectedMeasurementLocation && isLocationAndPointsView) {
      fetchData({ ...measurementPointsFilter, [fields.lastSeen]: 0, [fields.searchText]: '' });
    } else {
      setMeasurementPoints([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMeasurementLocation]);

  const handleRowClick = row => {
    if (queryItem === row[fields.id]) {
      return;
    }
    const newPathItem = {
      [fields.name]: row[fields.name],
      queryParams: {
        type: modules.readingsAndGauges,
        selected_item: row[fields.id],
        details: row.SystemType === measurementTypes.rgMeasurementLocation ? detailsPages.measurementLocation : detailsPages.measurementPoint,
        view,
      },
    };
    navigateToPath(newPathItem);
  };

  if (isLocationAndPointsView && isEmpty(selectedMeasurementLocation)) {
    return <EmptyState emptyStateText={t('READINGS_AND_GAUGES.MEASUREMENT_POINTS.NO_SELECTED_ML_EMPTY_STATE.TITLE')} showButton={false} transparent />;
  }

  // do not show the button if the searchText entered
  const shouldShowButton = !isEmpty(selectedMeasurementLocation) && isLocationAndPointsView && isEmpty(measurementPoints) && measurementPointsFilter[fields.searchText] === '';
  const emptyStateText = t('READINGS_AND_GAUGES.MEASUREMENT_POINTS.EMPTY_STATE.TITLE');
  const emptyStateProps = {
    emptyStateText: emptyStateText,
    showButton: shouldShowButton,
    transparent: true,
    buttonText: shouldShowButton ? t('READINGS_AND_GAUGES.MEASUREMENT_POINTS.EMPTY_STATE.ACTION_TEXT') : null,
    buttonAction: shouldShowButton ? () => handleOpenCreateMeasurementPointModal(selectedMeasurementLocation) : null,
    buttonDisabled: !Helpers.hasAccess({ user, visibleFor: [PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].pointCreate.name] }),
  };

  const handleSubmitCreateMeasurementReading = (data, row, isEdit, stepCallback) => {
    if (!row) return;
    const dateField = !isEdit ? [measurementReadingFormConstants.fields.date] : [measurementReadingFields.fields.measurementDate];
    const values = data?.[measurementReadingFormConstants.fields.values].map(str => parseFloat(str));
    const date = Helpers.dateToUnix(data[dateField]);
    const measurementReadingId = data[fields.id];
    setMRRequestInProgress(true);
    if (isEdit) {
      updateMeasurementReading(
        values,
        date,
        selectedMeasurementPoint[formConstants.fields.id],
        selectedMeasurementPoint ? selectedMeasurementPoint[formConstants.fields.measurementLocationID] : row[formConstants.fields.measurementLocationID],
        measurementReadingId,
        measurementReadingValuesData => {
          setMRRequestInProgress(false);
          stepCallback && typeof stepCallback === 'function' && stepCallback(measurementReadingValuesData);
          initializeMeasurementReadingForm(data); // initialize the form with the data, makes the form pristine again
        },
        () => {
          setMRRequestInProgress(false);
        }
      );
    } else {
      saveMeasurementReading(
        values,
        date,
        row[formConstants.fields.id],
        // propagate from reducer ML.ID in case MP is not selected and + icon is clicked
        selectedMeasurementPoint?.[measurementReadingFormConstants.fields.measurementLocationID] ||
          selectedMeasurementLocation?.[formConstants.fields.id] ||
          row[formConstants.fields.measurementLocationID],
        null,
        measurementReadingValuesData => {
          getDefectDetails(
            { [formConstants.fields.id]: row?.[formConstants.fields.id], SystemType: measurementTypes.rgMeasurementPoint },
            measurementPointDetails => {
              const updatedMeasurementPoint = Helpers.updateMeasurementPointAlarmStatus(measurementPointDetails);
              updateMeasurementPointInArray(updatedMeasurementPoint, 'update');
            },
            false
          );
          if (selectedMeasurementLocation) {
            getDefectDetails(
              { [formConstants.fields.id]: selectedMeasurementLocation?.[fields.id], SystemType: measurementTypes.rgMeasurementLocation },
              measurementLocationDetails => {
                updateMeasurementLocationInArray(measurementLocationDetails, 'update');
              },
              false
            );
          }

          setMRRequestInProgress(false);
          stepCallback && typeof stepCallback === 'function' && stepCallback(measurementReadingValuesData);
          initializeMeasurementReadingForm(data); // initialize the form with the data, makes the form pristine again
        },
        () => {
          setMRRequestInProgress(false);
        }
      );
    }
  };

  const handleCloseMeasurementReading = isMeasurementReadingFormDirty => {
    const closeModalAndConfirmModal = () => {
      setConfirmationModalData({ isOpen: false });
      setModalData({ isOpen: false });
    };
    if (isMeasurementReadingFormDirty) {
      const title = 'READINGS_AND_GAUGES.MEASUREMENT_READINGS_FORM_ADD.LEAVE';
      const firstParagraph = 'READINGS_AND_GAUGES.MEASUREMENT_READINGS_FORM_ADD.UNSAVED_CHANGES';
      setConfirmationModalData({
        isOpen: true,
        CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
        closeAction: () => setConfirmationModalData({ isOpen: false }),
        type: '',
        title: t(title),
        confirmButtonText: 'LEAVE',
        closeButtonText: 'CANCEL',
        firstParagraph: firstParagraph,
        secondParagraph: 'READINGS_AND_GAUGES.EDIT_GROUP_UNSAVED_CHANGES_PARAGRAPH_2',
        customCloseAction: () => setConfirmationModalData({ isOpen: false }),
        customConfirmAction: closeModalAndConfirmModal,
        customClassName: 'confirm-leave-modal modal-large',
        customClassWrapperName: 'confirm-leave-modal__picker',
      });
    } else {
      setModalData({ isOpen: false });
    }
  };

  const handleAddMeasurementReading = row => {
    if (!row) return;
    setModalData({
      isOpen: true,
      customClassName: 'alarms-edit-modal modal-large',
      customClassWrapperName: 'alarms-edit-modal__picker',
      type: '',
      CustomContent: dynamicProps => <CreateMeasurementReadingModal {...dynamicProps} />,
      title: t('READINGS_AND_GAUGES.MEASUREMENT_READINGS.ADD_MEASUREMENT_READING'),
      onSubmit: (data, isEdit, stepCallback) => handleSubmitCreateMeasurementReading(data, row, isEdit, stepCallback),
      measurementPointId: row[(formConstants, fields.id)],
      router,
    });
  };

  return (
    <AccessRenderer
      visibleFor={PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].pointEdit.name}
      id={selectedClusterElement && selectedClusterElement[fields.createdBy]}
      ownerRequiredPermission={PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].pointCreate.name}
    >
      {({ hasAccess: canEdit }) => {
        return (
          <>
            <InspectionRenderer
              deselectAll={deselectAll}
              selectAll={selectAll}
              toggleElement={toggleElement}
              deselectAllTemp={() => null}
              selectAllTemp={() => null}
              toggleElementTemp={() => null}
              updateElementGeometry={(...args) => {
                if (!canEdit) return;
                updateGeometry(...args);
              }}
              selectElement={(data, autoScroll) => {
                handleRowClick(data);
                if (autoScroll) {
                  scrollToElement(data);
                }
              }}
              elements={
                areAllMeasurementPointsHidden
                  ? []
                  : elementsClustered?.map(item => {
                      if (item[fields.id] === queryItem) {
                        return { ...item, enableMove: measurementPointLocationObjectEditing };
                      } else {
                        return item;
                      }
                    })
              }
              queryItem={queryItem}
              disableMove={!measurementPointLocationObjectEditing}
              viewer={viewer}
            >
              {({ elementShowHandler, elementHideHandler, elementClickHandler, selectAllHandler, deselectAllHandler }) => (
                <SingleRnGItemTable
                  searchPlaceholder={searchPlaceholder}
                  customTableClass={'measurement-points-table'}
                  isFullScreen={isFullScreen}
                  view={viewOptions.points}
                  handleRowClick={row => elementClickHandler(null, row)}
                  path={path}
                  navigateToPath={navigateToPath}
                  handleBackClick={handleBackClick}
                  handlePathClick={handlePathClick}
                  projectID={projectID}
                  fetchData={fetchData}
                  toggleVisibilityHandler={(e, row) => (row.visible ? elementHideHandler(e, row) : elementShowHandler(e, row))}
                  data={measurementPoints}
                  filters={measurementPointsFilter}
                  defaultFilters={defaultFilter}
                  isDataLoading={measurementPointsLoading}
                  selectedItem={queryItem}
                  allHidden={areAllMeasurementPointsHidden}
                  toggleAll={() => (areAllMeasurementPointsHidden ? selectAllHandler() : deselectAllHandler())}
                  showPath={showPath}
                  showEmptyState={isEmpty(measurementPoints) && isLocationAndPointsView}
                  emptyStateProps={emptyStateProps}
                  toggleModalAction={handleAddMeasurementReading}
                  user={user}
                  maxSearchCharacters={MAX_SEARCH_POINT_CHARACTERS}
                />
              )}
            </InspectionRenderer>
            <Modal
              requestInProgress={MRRequestInProgress}
              {...modalData}
              closeAction={() => handleCloseMeasurementReading(isMeasurementReadingFormDirty)}
              customCloseAction={() => handleCloseMeasurementReading(isMeasurementReadingFormDirty)}
              closeConfirmModalAction={() => setModalData({ isOpen: false })}
              dirty={isMeasurementReadingFormDirty}
            />
            <Modal {...confirmationModalData} />
          </>
        );
      }}
    </AccessRenderer>
  );
};

MeasurementPointsInspectionView.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  measurementPoints: state.measurementPointReducer.measurementPoints,
  measurementPointsFilter: state.measurementPointReducer.measurementPointsFilter,
  measurementPointsLoading: state.measurementPointReducer.measurementPointsLoading,
  measurementPointLocationObjectEditing: state.measurementPointReducer.measurementPointLocationObjectEditing,
  areAllMeasurementPointsHidden: state.measurementPointReducer.areAllMeasurementPointsHidden,
  selectedMeasurementPoint: state.inspectionReducer.selectedDefect,
  isMeasurementReadingFormDirty: isDirty(FORMS.measurementPointMeasurementReadingForm)(state),
  measurementLocations: state.measurementLocationReducer.measurementLocations,
});

const mapDispatchToProps = dispatch => ({
  fetchMeasurementPoints: (filters, loadMore, successCallback) => dispatch(fetchMeasurementPoints(filters, loadMore, successCallback)),
  setMeasurementPoints: data => dispatch(setMeasurementPoints(data)),
  updateGeometry: data => dispatch(updateElementGeometry(data)),
  deselectAll: () => dispatch(deselectAllMeasurementPoints()),
  selectAll: () => dispatch(selectAllMeasurementPoints()),
  toggleElement: id => dispatch(toggleMeasurementPoint(id)),
  saveMeasurementReading: (values, date, MeasurementPointID, MeasurementLocationID, MeasurementReadingID, successCallback, errorCallback) =>
    dispatch(saveMeasurementReading(values, date, MeasurementPointID, MeasurementLocationID, MeasurementReadingID, successCallback, errorCallback)),
  updateMeasurementReading: (values, date, MeasurementPointID, MeasurementLocationID, MeasurementReadingID, successCallback, errorCallback) =>
    dispatch(updateMeasurementReading(values, date, MeasurementPointID, MeasurementLocationID, MeasurementReadingID, successCallback, errorCallback)),
  updateMeasurementPointInArray: (data, method) => dispatch(updateMeasurementPointInArray(data, method)),
  getDefectDetails: (defect, callback, saveItem) => dispatch(getDefectDetails(defect, callback, saveItem)),
  updateMeasurementLocationInArray: (data, method) => dispatch(updateMeasurementLocationInArray(data, method)),
  initializeMeasurementReadingForm: data => dispatch(initialize(FORMS.measurementPointMeasurementReadingForm, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MeasurementPointsInspectionView);
