import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import EmptyState from '../../../../../common/empty-state/components/empty-state';
import Helpers from '../../../../../common/helpers';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../../common/permissions-constants';
import RenderIf from '../../../../../common/render-if/components/render-if';
import routesConstants from '../../../../../common/routes-constants';
import { getInspectionDefects } from '../../../actions/inspection-actions';
import { modules } from '../../../constants/constants';
import { defaultFilters, fields, filterProps } from '../constants/constants';
import '../styles/component-defect-tab.scss';
import ObservationsWrapper from './observations-wrapper';

const ComponentsObservationsTab = ({ location, getInspectionDefects, user, selectedComponent, inspectionId }, { t }) => {
  const { query } = location;
  const componentID = parseInt(get(query, 'selected_item')) || selectedComponent[fields.id];
  const [filters, setFilters] = useState({ ComponentID: componentID, ...defaultFilters });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const userHasAccess = Helpers.hasAccess({ user, visibleFor: PERMISSIONS[PERMISSION_TYPES.observations].view.name });

  useEffect(() => {
    userHasAccess && componentID && componentID > 0 && fetchObservations(filters);
    //eslint-disable-next-line
  }, []);

  const fetchObservations = (filters, loadMore) => {
    if (!componentID || componentID < 1) return;

    const onSuccess = (items, newFilters) => {
      setLoading(false);
      setData(loadMore ? [...data, ...items] : items);
      setFilters(prev => ({ ...prev, ...newFilters }));
      if (loadMore) {
        Helpers.scrollIntoView('observations-table', `row-${filters[filterProps.lastSeen]}`, -525);
      }
    };
    if (typeof filters[filterProps.statusFilter] !== 'string') {
      filters[filterProps.statusFilter] = filters[filterProps.statusFilter].join(',');
    }
    filters[filterProps.componentIDFilter] = componentID;
    getInspectionDefects(inspectionId, modules.defects, '', filters, onSuccess, setLoading);
  };

  const handleOnRowClick = row => {
    if (!row.ID) return;
    const path = user?.FullScreenEnabled ? routesConstants.routes.protectedRoutes.fullScreen.fullPath : routesConstants.routes.protectedRoutes.inspections.fullPath;
    const { query } = location;
    const newQuery = { ...query, type: modules.defects, selected_item: row?.ID };
    const params = Object.keys(newQuery).map(key => {
      const obj = {};
      obj[key] = newQuery[key];
      return obj;
    });
    Helpers.goTo(path, params);
  };

  return (
    <>
      <RenderIf if={userHasAccess}>
        <ObservationsWrapper
          data={data || []}
          filters={filters}
          wrapperClassName="component-defect-tab"
          loading={loading}
          fetchObservations={fetchObservations}
          customTableClassName="observations-table"
          onRowClick={handleOnRowClick}
          inspectionId={inspectionId}
        />
      </RenderIf>
      <RenderIf if={!userHasAccess}>
        <EmptyState icon="crossed-out-circle" iconSize="xs" message="COMPONENTS_TAB.NO_PERMISSION" title="COMPONENTS_TAB.NO_PERMISSION_TITLE" />
      </RenderIf>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  getInspectionDefects: (
    inspectionId,
    activeLeftSidebar,
    searchText = '',
    optionalParams,
    callback,
    loadMore = false,
    isLite = true,
    isHierarchy = false,
    isUnassignedLevel = false,
    loadingCallback
  ) => dispatch(getInspectionDefects(inspectionId, activeLeftSidebar, searchText, optionalParams, callback, loadMore, isLite, isHierarchy, isUnassignedLevel, loadingCallback)),
});

const mapStateToProps = state => ({
  user: state.userReducer,
});

ComponentsObservationsTab.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ComponentsObservationsTab));
