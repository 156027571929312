import PropTypes from 'prop-types';
import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Icon from '../../icon/components/icon';
import '../style/pdf-viewer.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfViewer = (
  {
    className,
    nextPageIconClass = '',
    previousPageIconClass = 'invert-rotate',
    renderMode = 'svg',
    file,
    pageProps,
    onDocumentLoadSuccess,
    pageNumber,
    onDocumentLoadError,
    renderNavButtons,
    numPages,
    changePage,
    calculatedScale,
  },
  { t }
) => {
  return (
    <>
      <Document file={file} onLoadError={onDocumentLoadError} onLoadSuccess={onDocumentLoadSuccess} className={`pdf-viewer ${className}`} renderMode={renderMode}>
        <Page
          pageNumber={pageNumber}
          {...pageProps}
          size={'A4'}
          // Whether annotations (e.g. links) should be rendered. (default: true)
          renderAnnotationLayer={false}
          // Whether a text layer should be rendered. (default: true)
          renderTextLayer={false}
          scale={calculatedScale}
          devicePixelRatio={2}
        >
          {renderNavButtons && (
            <div className="pdf-with-drawing__page-footer">
              <div className="pdf-with-drawing__page-footer--page-number" style={{ transform: `translateY(-150px) scale(${calculatedScale})` }}>
                {pageNumber} / {numPages}
              </div>
              <div className="pdf-with-drawing__page-footer--page-navigation-buttons" style={{ transform: `translateY(-100px) scale(${calculatedScale})` }}>
                {pageNumber > 1 && <Icon name="chevron" className={previousPageIconClass} disabled={pageNumber <= 1} onClick={() => changePage(-1)} />}
                <span>
                  {t('PAGE')} {pageNumber} / {numPages}
                </span>
                {pageNumber !== numPages && <Icon name="chevron" className={nextPageIconClass} disabled={pageNumber === numPages} onClick={() => changePage(+1)} />}
              </div>
            </div>
          )}
        </Page>
      </Document>
    </>
  );
};

PdfViewer.propTypes = {
  pageNumber: PropTypes.number,
  numPages: PropTypes.number,
  className: PropTypes.string,
  renderMode: PropTypes.string,
  file: PropTypes.string.isRequired,
  pageProps: PropTypes.object,
  onDocumentLoadSuccess: PropTypes.func,
  onDocumentLoadError: PropTypes.func,
  renderNavButtons: PropTypes.bool,
  changePage: PropTypes.func,
};

PdfViewer.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default PdfViewer;
