import React, { Component } from 'react';

import HeaderSlider from './header-slider';

import { formConstants } from '../../inspections/constants/defect-constants';

import { Tools } from '@tarik.djurdjevic/react-sketch';
import { AMAZON_IMAGE_SIZES } from '../../../common/constants';
import DrawingHelpers from '../../../common/drawing-helpers';
import orientationConstants from '../../start-workflow/constants/orientation-constants';

import { isEmpty } from 'lodash';
import Helpers from '../../../common/helpers';
import Icon from '../../../common/icon/components/icon';
import SmallDropdown from '../../../common/small-dropdown/components/small-dropdown';
import '../styles/linked-images-slider.scss';
const objectPropsToInclude = ['strokeUniform', 'noScaleCache', 'angle', 'transparentCorners', 'hasRotatingPoint'];

class LinkedImagesSliderSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTool: Tools.DefaultTool,
      orientation: orientationConstants.orientation.full,
      currentSlideIndex: props.currentSlideIndex,
      imageType: AMAZON_IMAGE_SIZES.small.name,
      drawings: null,
      isFullScreen: false,
      drawingsLoading: false,
      sketchValid: true,
    };
    this.node = React.createRef();
    this.sketchRef = null;
  }

  updateFullScreen = isFullScreen => {
    this.setState({ isFullScreen: isFullScreen || false });
  };

  previousClick = () => {
    const { current } = this.node;
    current.slideLeft();
  };
  nextClick = () => {
    const { current } = this.node;
    current.slideRight();
  };

  selectDrawingTool = val => {
    this.setState({ selectedTool: val });
  };

  handleImageActive = index => {
    this.setOrientation(orientationConstants.orientation.full);
    setTimeout(() => {
      this.node.current.slideToIndex(index);
    }, 0);
  };

  setOrientation = orientation => {
    this.setState({ orientation });
  };
  onSlide = currentIndex => {
    const { onSlide } = this.props;
    this.setState({ currentSlideIndex: currentIndex });
    if (onSlide) onSlide(currentIndex);
  };

  setSketchRef = ref => (this.sketchRef = ref);

  onDrawingEdited = () => {
    const { onDrawingEdited } = this.props;

    const savedSketch = this.fetchCurrentSketch();
    if (savedSketch) {
      onDrawingEdited(savedSketch);
    }
  };

  renderCustomControls = () => {
    const { node } = this;
    const { currentSlideIndex } = this.state;
    const { linkedImages, openDeleteFileModal, hasUnlinkAccess } = this.props;

    const file = linkedImages[currentSlideIndex];

    let toggleGalleryFullScreen = () => null,
      isGalleryFullScreen = false;
    if (node.current) {
      const { toggleFullScreen, state } = node.current;
      const { isFullscreen } = state;
      toggleGalleryFullScreen = toggleFullScreen;
      isGalleryFullScreen = isFullscreen;
    }
    const menuOptions = [
      {
        title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_2',
        action: () => {
          Helpers.getFileExtensionAndDownload(file);
        },
      },
      {
        title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_5',
        action: () => Helpers.downloadImageWithDrawing(file),
        isDisabled: () => {
          return !file.isImage || isEmpty(file.Drawings);
        },
      },
    ];

    if (openDeleteFileModal && typeof openDeleteFileModal === 'function') {
      menuOptions.push({
        title: 'UNLINK_IMAGE',
        action: () => {
          isGalleryFullScreen && toggleGalleryFullScreen();
          openDeleteFileModal(file.FileID, file.FileName, file.CategoryID, file);
        },
        separator: true,
        isHighlighted: true,
        hasAccess: Helpers.hasAccess(hasUnlinkAccess),
      });
    }
    return (
      <span className="image-slider-custom-render-button" id="images-slider">
        <SmallDropdown
          actionsMenu={menuOptions}
          offsetX={-100}
          InfoIcon={() => <Icon size="md" name="more" />}
          getClickAction={(action, b, selfClose, e) => {
            e?.preventDefault && e.preventDefault();
            selfClose();
            if (action) {
              if (typeof action === 'function') {
                action();
              }
            }
          }}
          portalProps={{
            id: 'images-slider',
          }}
        />
      </span>
    );
  };

  fetchCurrentSketch = () => {
    const { sketchRef } = this;

    if (sketchRef && sketchRef._container) {
      const savedSketch = Object.assign({}, sketchRef.toJSON(objectPropsToInclude));

      let { offsetWidth, clientHeight } = sketchRef._container;
      savedSketch.canvasWidth = offsetWidth;
      savedSketch.canvasHeight = clientHeight;

      //validate sketch size
      if (!DrawingHelpers.isValid(savedSketch)) {
        this.setState({ sketchValid: false });
        return null;
      }
      this.setState({ sketchValid: true });

      return savedSketch;
    }
    console.warn(sketchRef, 'sketch is not present');
    return null;
  };

  render() {
    const { selectedTool, orientation, currentSlideIndex, imageType, isFullScreen, drawingsLoading } = this.state;
    const { linkedImages, disabledOrientations, CustomToolsComponent, isPreviewModal, drawingToolsEnabled, hideToolsBar } = this.props;
    const { node, previousClick, nextClick, handleImageActive, onSlide, selectDrawingTool, setOrientation, updateFullScreen, onDrawingEdited, sketchRef, setSketchRef, renderCustomControls } = this;

    const currentImage = linkedImages[currentSlideIndex];

    const headerProps = {
      workflowImagesSelected: linkedImages,
      disableColorPicker: true,
      ref: node,
      onDrawingEdited,
      selectDrawingTool: selectDrawingTool,
      selectedTool: selectedTool,
      orientation: orientation,
      drawings: linkedImages?.[currentSlideIndex]?.[formConstants.fields.drawings],
      drawingsLoading: drawingsLoading,
      setSelectedDefect: null,
      currentSlideIndex,
      renderLeftNav: () => null,
      renderRightNav: () => null,
      renderCustomControls,
      previousClick,
      nextClick,
      handleImageActive,
      setOrientation,
      currentImage,
      onSlide,
      drawingToolsEnabled,
      imageType,
      updateFullScreen,
      sliderImageType: isFullScreen ? AMAZON_IMAGE_SIZES.large.name : AMAZON_IMAGE_SIZES.medium.name,
      isFullScreen,
      disabledOrientations,
      CustomToolsComponent,
      sketchRef,
      setSketchRef,
      hideToolsBar,
    };

    return (
      <div className={`linked-images-slider ${isPreviewModal ? 'image-preview-modal' : ''}`}>
        <HeaderSlider {...headerProps} />
      </div>
    );
  }
}

export default LinkedImagesSliderSlider;
