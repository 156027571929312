import PropTypes from 'prop-types';
import { SPOT_ELEVATION_UNIT, SPOT_SPEED_UNIT, SPOT_TOTAL_DISTANCE_UNIT } from '../../constants';
import Icon from '../../icon/components/icon';
import '../styles/spot-statistics.scss';

export const SpotStatistics = ({ className, elevation = 0, speed = 0, totalDistance = 0, x = 0, y = 0, z = 0 }, { t }) => (
  <div className={`spot-statistics ${className}`}>
    <div className="spot-statistics__title">
      <span>{t('STATISTICS')}</span>
    </div>

    <div className="spot-statistics__info">
      <div className="flex-center">
        <Icon name="arrow-up" size="sm" />
        <p className="f-secondary-dark font-500">{!isNaN(elevation) ? `${elevation} ${SPOT_ELEVATION_UNIT}` : '-'}</p>
      </div>

      <div className="flex-center">
        <p className="f-secondary-dark">{t('SPEED')}:</p>
        <p className="f-secondary-dark font-500">{!isNaN(speed) ? `${speed} ${SPOT_SPEED_UNIT}` : '-'}</p>
      </div>

      <div className="flex-center">
        <p className="f-secondary-dark">{t('TOT.DISTANCE')}:</p>
        <p className="f-secondary-dark font-500">{!isNaN(totalDistance) ? `${totalDistance} ${SPOT_TOTAL_DISTANCE_UNIT}` : '-'}</p>
      </div>
    </div>

    <div className="spot-statistics__xyz">
      <div className="flex-center">
        <p className="f-secondary-dark">X:</p>
        <p className="f-secondary-dark font-500">{!isNaN(x) ? x : '-'}</p>
      </div>
      <div className="flex-center">
        <p className="f-secondary-dark">Y:</p>
        <p className="f-secondary-dark font-500">{!isNaN(y) ? y : '-'}</p>
      </div>
      <div className="flex-center">
        <p className="f-secondary-dark">Z:</p>
        <p className="f-secondary-dark font-500">{!isNaN(z) ? z : '-'}</p>
      </div>
    </div>
  </div>
);

SpotStatistics.contextTypes = {
  t: PropTypes.func.isRequired,
};
