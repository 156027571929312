import augmentedRealityApi from '../../../api/augmented-reality-api/actions';

// live stream
export const fetchAugmentedRealityVideoList = (filters, dataCallback, filtersCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      const res = await augmentedRealityApi.fetchAugmentedRealityVideoList(filters);
      const { Data } = res?.data;
      const { Items, ...rest } = Data;
      dataCallback && typeof dataCallback === 'function' && dataCallback(Items);
      filtersCallback && typeof filtersCallback === 'function' && filtersCallback({ ...filters, ...rest });

      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};
