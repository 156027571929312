import { head, takeRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../icon/components/icon';
import RenderIf from '../../render-if/components/render-if';
import { pathViewerFields } from '../constants/path-viewer-constants';
import '../styles/path-viewer.scss';
import BreadcrumbsPopover from './breadcrumb-popover';
const MAX_LEVELS_TO_SHOW = 4; // standardised default value in DMS, CH, etc.
const LEVELS_TO_SHOW_COUNT = 3; // standardised default value in DMS, CH, etc.

const renderPath = (levels, onClick, propInPath = [pathViewerFields.code], maxLevels = MAX_LEVELS_TO_SHOW, levelsToShowCount = LEVELS_TO_SHOW_COUNT, t) => {
  let renderLevels = levels;
  // used to determine if the path should be truncated and displayed with a breadcrumb popover.
  const isLarger = renderLevels.length > maxLevels;
  if (isLarger) {
    const rootLevel = head(renderLevels);
    // to get the last few elements from the renderLevels array, which are then displayed in the path viewer.
    const levelsToShow = takeRight(renderLevels, levelsToShowCount);
    renderLevels = [rootLevel, { levels: [...renderLevels], isBreadcrumbs: true }, ...levelsToShow];
  }
  return renderLevels.map((level, i) => {
    // translating the NO_GROUP level name to the correct language done mostly for R&G as the NO_GROUP level name is not translated in the backend
    if (level[pathViewerFields.name] === 'NO_GROUP') {
      level[pathViewerFields.name] = t('NO_GROUP');
    }
    if (level.levels && level.levels.length > 0) {
      level.levels.forEach(subLevel => {
        if (subLevel[pathViewerFields.name] === 'NO_GROUP') {
          subLevel[pathViewerFields.name] = t('NO_GROUP');
        }
      });
    }
    return (
      <div key={i} className="path-viewer__path__level ">
        {level.isBreadcrumbs ? (
          <BreadcrumbsPopover levels={level.levels} propInPath={propInPath} />
        ) : (
          // Truncating to 16 characters due to size limit and text breaking on UI - as per agreement
          <p className={`path-viewer__path__level__title ${renderLevels.length - 1 === i ? 'last' : 'button'}`} onClick={() => onClick(level[pathViewerFields.id], level)} title={level[propInPath]}>
            {level[propInPath].length > 16 ? `${level[propInPath].slice(0, 16)}...` : level[propInPath]}
          </p>
        )}
        <p>{renderLevels.length - 1 === i ? null : '/'}</p>
      </div>
    );
  });
};

const PathViewer = ({ levels, onBackClick, onPathClick, propInPath, maxLevels, levelsToShowCount }, { t }) => {
  const displayCode = levels[levels.length - 1][pathViewerFields.code] ? levels[levels.length - 1][pathViewerFields.code] : '';
  const displayName = levels[levels.length - 1][pathViewerFields.name] ? levels[levels.length - 1][pathViewerFields.name] : '';

  return (
    <div className="path-viewer">
      <div className="path-viewer__info">
        <RenderIf if={levels.length > 1}>
          <div onClick={onBackClick}>
            <Icon name="chevron" size="md" />
          </div>
        </RenderIf>
        <h5>{`${displayCode} ${displayName}`}</h5>
      </div>
      <div className={`path-viewer__path ${levels.length > 1 ? 'has-chevron' : ''}`}>{renderPath(levels, onPathClick, propInPath, maxLevels, levelsToShowCount, t)}</div>
    </div>
  );
};

PathViewer.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default PathViewer;
