import { cloneDeep, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import 'react-input-range/lib/css/index.css';

import { AMAZON_IMAGE_SIZES, FEATURES, FORMS } from '../../../../common/constants';
import Button from '../../../../common/form/components/button';
import CustomInput from '../../../../common/form/components/input';
import Textarea from '../../../../common/form/components/text-area';
import Icon from '../../../../common/icon/components/icon';
import FieldUpload from './component-assets';

import Helpers from '../../../../common/helpers';
import {
  clearSubTypesSuggestions,
  clearUnsavedChangesDirty,
  setComponentDetailsData,
  setDefectLocationAdding,
  setDefectLocationEditing,
  setObservationFormState,
  setUnsavedChangesDirty,
} from '../../actions/action-creators';
import {
  addDefectProperty,
  deleteDefectProperty,
  fetchSubTypesSuggestions,
  getDefectProperties,
  getDefectPropertyNames,
  setComponentDetails,
  updateDefectProperty,
  updateElementGeometry,
} from '../../actions/inspection-actions';
import { validate } from './validators/defect-validator';

import CustomProperties from '../../../../common/custom-property/components/custom-properties';
import ExpandableSection from '../../../../common/expandable-section/components/expandable-section';
import Modal from '../../../../common/modal/components/modal';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import RenderIf from '../../../../common/render-if/components/render-if';
import { preventedFieldsPriorComponentIsCreated } from '../../constants/component-constants';
import { modules, objectTools } from '../../constants/constants';
import { defectStatus, formConstants, observationTypeFields, observationsTypesIconsMap, statuses, textAreaMaxChars, uploadGroups } from '../../constants/defect-constants';
import CustomInputRange from '../common/input/input-range';
import LocationSection from './location-section';

import AccessRenderer from '../../../../common/access-renderer/components/access-renderer';

import EmptyState from '../../../../common/empty-state-v2/components/empty-state';
import CustomSelect from '../../../../common/form/components/select';
import UneditableInfo from '../../../../common/form/components/uneditable-info';
import LocationExpandableSection from '../../../../common/location-expandable-section/components/location-expandable-section';
import { setGenericNotification } from '../../../../common/notification/actions/action-creators';
import '../../../../common/slider/styles/image-slider.scss';
import ContributorsList from '../../../../common/user-team/components/contributors-list';
import WarningIndicator from '../../../../common/warning-indicator/components/warning-indicator';
import { unlinkDMSFileFromObservation } from '../../../document-management/actions/dm-api-calls';
import { expandableSectionInfoTypes } from '../../constants/notification-constants';
import '../../styles/component-files-modal.scss';
import '../../styles/defect-form.scss';
import AutoComplete from '../common/input/autocomplete';
import { getObservationDMSFilesUploaded } from '../observations/actions/observations-api-calls';
import { fields, filterProps } from '../observations/constants/constants';
import ChangeStatusDropdown from './common/change-status-dropdown';
import CriticalEquipmentInfo from './common/critical-equipment-info';
import EditModuleItemFilesModal from './common/edit-module-item-files-modal';
import AddWorkOrdersToObservations from './defect-details/components/add-work-order-to-defect/add-work-orders-to-defect';
import DefectComponentPicker from './defect-details/components/defect-component-picker';
import ObservationWorkOrdersSection from './defect-details/components/defect-sections-work-orders';
import NotificationsExpandableSectionInfo from './notification-details/expandable-section-info';

class DefectForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageType: AMAZON_IMAGE_SIZES.small.name,
      modalData: {
        isOpen: false,
      },
      editFilesModal: { isOpen: false },
    };
  }

  componentDidUpdate(prevProps) {
    const { componentID, setComponentDetails, setComponentDetailsData } = this.props;
    if (componentID !== prevProps.componentID) {
      if (componentID) {
        setComponentDetails(componentID);
      } else {
        setComponentDetailsData(null);
      }
    }
  }

  handleSeverityChange = val => {
    const { changeField, defectFiles, changeLinkedImagesColor } = this.props;
    const color = Helpers.fetchReviewedClass(val);

    changeLinkedImagesColor(defectFiles, val);

    changeField(formConstants.fields.color, color);
  };

  formatDate = input => {
    if (!input) return;

    return Helpers.getDateFromUnix(input);
  };

  closeModal = () => {
    this.setState({ modalData: { isOpen: false } });
  };

  toggleConfirmationModal = (isOpen, title, content, confirmAction) => {
    const { t } = this.context;

    if (isOpen) {
      const handleConfirmAction = () => {
        if (typeof confirmAction === 'function') {
          confirmAction();
        }
      };

      this.setState({
        modalData: {
          isOpen: true,
          content: t(content),
          title: t(title),
          type: 'cancel-confirm',
          customClassName: 'component-confirmation-modal',
          confirmAction: handleConfirmAction,
          closeAction: () => this.setState({ modalData: { isOpen: false } }),
        },
      });
    } else {
      this.setState({ modalData: { isOpen: false } });
    }
  };

  handleDeleteLocation = newCoordinates => {
    const { selectedDefect, updateGeometry, locationObject } = this.props;
    const element = cloneDeep({ ...selectedDefect, ...locationObject });
    element.Geometry.coordinates = newCoordinates;

    updateGeometry(element);
    this.toggleConfirmationModal(false);
  };

  openDeleteFileModal = (fileID, selectedDefectID, fileName, categoryID) => {
    const { t } = this.context;
    const { unlinkDMSFileFromObservation, getObservationDMSFilesUploaded } = this.props;

    const modalData = {
      isOpen: true,
      content: t('COMPONENT_DETAILS.DELETE_FILE_DESC', { fileName }),
      title: t('COMPONENT_DETAILS.DELETE_FILE_TITLE'),
      type: 'yes-no',
      confirmAction: () =>
        unlinkDMSFileFromObservation(
          fileID,
          selectedDefectID,
          categoryID,
          () => getObservationDMSFilesUploaded(selectedDefectID),
          () => this.setState({ modalData: { isOpen: false } })
        ),
      closeAction: () => this.setState({ modalData: { isOpen: false } }),
      customClassName: 'modal-small',
    };

    this.setState({ modalData });
  };

  renderFilesSection = (ownerID, readonly, defectFiles) => {
    const { selectedDefect, projectDMSCategories, openFile } = this.props;
    const { imageType } = this.state;
    const { t } = this.context;

    const filesByCategory = {};

    if (isEmpty(defectFiles)) {
      return <EmptyState emptyStateText={t('NO_FILES_ADDED')} showButton transparent buttonText={t('CREATE_REGIME_FORM.ADD_FILES')} buttonAction={this.openEditFilesModal} buttonDisabled={readonly} />;
    }

    if (!isEmpty(defectFiles)) {
      projectDMSCategories.forEach(c => {
        const category = c[fields.id];
        if (!category) return null;

        const categoryFiles = defectFiles.filter(cf => cf.CategoryID === category) || [];
        filesByCategory[c.Name] = categoryFiles;
      });
    }
    return projectDMSCategories.map(category => {
      return (
        <FieldUpload
          disabled={readonly}
          label={t(category.Name)}
          labelClass="f-secondary-dark defect-form__label"
          noFileText={t('NO_FILES_IN_CATEGORY')}
          files={filesByCategory[category.Name]}
          imageType={imageType}
          showUploadSvg={false}
          actionsMenu={[
            {
              title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_1',
              action: data => {
                openFile(data);
              },
            },
            {
              title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_2',
              action: data => {
                Helpers.getFileExtensionAndDownload(data);
              },
            },
            {
              title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_5',
              action: data => Helpers.downloadImageWithDrawing(data),
              isDisabled: item => {
                return !item.isImage || isEmpty(item.Drawings);
              },
            },
            {
              title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.UNLINK_FILE',
              action: ({ FileName, SourceID }) => this.openDeleteFileModal(SourceID, selectedDefect[fields.id], Helpers.decodeIfStringEncoded(FileName), category[fields.id]),
              access: {
                id: ownerID,
                ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.observations].create.name,
                visibleFor: [PERMISSIONS[PERMISSION_TYPES.observations].edit.name],
              },
            },
          ]}
        />
      );
    });
  };

  openEditFilesModal = () => {
    const { t } = this.context;
    const { projectDMSCategories, selectedDefect, defaultDMSCategoryID } = this.props;
    this.setState({
      editFilesModal: {
        isOpen: true,
        type: '',
        title: t('EDIT_FILES'),
        CustomContent: dynamicProps => (
          <EditModuleItemFilesModal {...dynamicProps} defaultDMSCategoryID={defaultDMSCategoryID} projectDMSCategories={projectDMSCategories} selectedModuleItem={selectedDefect} />
        ),
        customClassName: 'modal-no-max-height modal-large',
        closeAction: () => this.setState({ editFilesModal: { isOpen: false } }),
        customCloseAction: () => this.setState({ editFilesModal: { isOpen: false } }),
      },
    });
  };

  handleChangedComponent = components => {
    const { changeField, handleComponentChange } = this.props;
    const idToBeSent = components[0] ? components[0][formConstants.fields.id] : null;
    changeField(formConstants.fields.componentID, idToBeSent);
    handleComponentChange(idToBeSent);
    this.setState({ modalData: { isOpen: false } });
  };

  openComponentPickerModal = e => {
    e?.stopPropagation();
    const { defectComponentDetails, setGenericNotification } = this.props;
    const { t } = this.context;
    this.setState({
      modalData: {
        isOpen: true,
        type: '',
        customClassName: 'pick-and-link-components-modal modal-large',
        customClassWrapperName: 'pick-and-link-components-modal__picker',
        closeAction: () => this.setState({ modalData: { isOpen: false } }),
        customCloseAction: () => this.setState({ modalData: { isOpen: false } }),
        CustomContent: dynamicProps => <DefectComponentPicker {...dynamicProps} />,
        title: t('DEFECTS.EDIT_COMPONENTS_MODAL_TITLE'),
        setGenericNotification: setGenericNotification,
        component: {
          ...defectComponentDetails,
          // TODO: questionable fields passed since fields.componentName is overriden with fields.Name every time. Why do we send it?
          [formConstants.fields.componentName]: defectComponentDetails[formConstants.fields.name],
          [formConstants.fields.componentID]: defectComponentDetails[formConstants.fields.id],
        },
        customConfirmAction: this.handleChangedComponent,
      },
    });
  };

  openObservationWorkOrdersModal = e => {
    e?.stopPropagation();
    const { inspectionID, selectedDefect } = this.props;
    const { t } = this.context;
    this.setState({
      modalData: {
        isOpen: true,
        type: 'none',
        CustomContent: dynamicProps => <AddWorkOrdersToObservations {...dynamicProps} />,
        title: t('OBSERVATIONS.EDIT_WORK_ORDERS'),
        customCloseAction: this.closeModal,
        closeAction: this.closeModal,
        defectID: selectedDefect[formConstants.fields.id],
        inspectionID: inspectionID,
      },
    });
  };

  handleComponentClick = component => {
    const { handleActivePage, defaultComponent } = this.props;
    if (component[formConstants.fields.id] === defaultComponent[formConstants.fields.id]) return;
    handleActivePage(modules.components, component[formConstants.fields.id]);
  };

  handleWorkOrderClick = workOrder => {
    const { handleActivePage } = this.props;
    handleActivePage(modules.workorders, workOrder[formConstants.fields.id]);
  };

  handleCustomPropertyUpdate = property => {
    const { unsavedCustomProps, setObservationFormState, setUnsavedChangesDirty } = this.props;
    const newUnsavedCustomProps = unsavedCustomProps || [];
    const positionOfProperty = newUnsavedCustomProps.findIndex(prop => prop[formConstants.fields.id] === property[formConstants.fields.id]);
    if (newUnsavedCustomProps[positionOfProperty]) {
      newUnsavedCustomProps[positionOfProperty] = property;
    } else {
      newUnsavedCustomProps.push(property);
    }
    setObservationFormState({ unsavedCustomProps: newUnsavedCustomProps, hasUnsavedCustomProps: true, hasUnsavedChanges: true });
    setUnsavedChangesDirty(true);
  };

  render() {
    const { t } = this.context;
    const { modalData, editFilesModal } = this.state;
    const {
      user,
      defectComponentDetails,
      changeField,
      selectedDefect,
      defectFiles,
      loggedByUserID,
      observationTypes,
      generalStatus,
      properties,
      getDefectProperties,
      deleteDefectProperty,
      getDefectPropertyNames,
      handleFieldChange,
      handleSubmit,
      viewer,
      showGeometryWarning = true,
      objectToolClick,
      mainType,
      defectLocationAdding,
      defectLocationEditing,
      setDefectLocationAdding,
      setDefectLocationEditing,
      images360Ref,
      handleInputDisabled,
      subTypesSuggestions,
      fetchSubTypesSuggestions,
      clearSubTypesSuggestions,
      openAssigneesModal,
      addedAssignees,
      addedCollaborators,
      handleStatusChange,
      formHasUnsavedChanges,
      observationWorkOrders,
      locationObject,
      updateGeometry,
      defaultComponent,
      setObservationFormState,
      clearUnsavedChangesDirty,
      toggleElement,
      formValues,
      formErrors,
    } = this.props;

    const linkedImages = !isEmpty(defectFiles) ? defectFiles[uploadGroups.linkedImages] || [] : [];
    const isDisabled = !selectedDefect || !selectedDefect[fields.id] || selectedDefect[fields.id] < 0;
    const containsCriticalEquipment = defectComponentDetails && defectComponentDetails.Critical === true;

    return (
      <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.observations].edit.name} id={loggedByUserID} ownerRequiredPermission={PERMISSIONS[PERMISSION_TYPES.observations].create.name}>
        {({ hasAccess }) => {
          const readonly = !hasAccess;

          return (
            <form className="defect-form overflow-inherit" noValidate onSubmit={handleSubmit}>
              <RenderIf if={containsCriticalEquipment}>
                <CriticalEquipmentInfo title={'CRITICAL_EQUIPMENT.TITLE'} paragraph={'CRITICAL_EQUIPMENT_OBSERVATIONS.PARAGRAPH'} />
              </RenderIf>
              <RenderIf if={showGeometryWarning}>
                <LocationExpandableSection
                  customExpanded={locationObject?.visible}
                  title={t('SECTION_TITLE.COMPONENT_LOCATION')}
                  onToggleLocationSection={() => toggleElement(selectedDefect[fields.id])}
                >
                  <LocationSection
                    viewer={viewer}
                    coordinates={locationObject?.Geometry?.coordinates || []}
                    invalid={false}
                    readonlyCamPos={readonly}
                    locationIsAdding={defectLocationAdding}
                    locationIsEditing={defectLocationEditing}
                    setLocationIsAdding={setDefectLocationAdding}
                    setLocationIsEditing={isEditing => {
                      setDefectLocationEditing(isEditing);
                      if (images360Ref) {
                        // Show/Hide all footprints
                        images360Ref.footprintsVisible = isEditing ? false : true;
                      }
                    }}
                    handleAddLocation={() => {
                      setDefectLocationAdding(true);
                      objectToolClick(objectTools.link, mainType, false, () => {
                        setDefectLocationAdding(false);
                        setObservationFormState({ hasUnsavedChanges: false });
                        clearUnsavedChangesDirty();
                      });
                    }}
                    handleCameraChange={newValue => {
                      const element = cloneDeep({ ...selectedDefect, ...locationObject });
                      element.CameraPosition.coordinates = newValue;

                      updateGeometry(element);
                    }}
                    handleDeleteLocation={newCoordinates => {
                      this.toggleConfirmationModal(true, 'DEFECT_DETAILS.DELETE_LOCATION_MODAL.TITLE', 'DEFECT_DETAILS.DELETE_LOCATION_MODAL.DESC', () => this.handleDeleteLocation(newCoordinates));
                    }}
                    addingLocationDescription="DEFECT_DETAILS.MARK_ON_3D.IN_PROGRESS.TEXT"
                    missingLocationDescription="DEFECT_DETAILS.WARNING.MISSING_PIN.DESCRIPTION"
                    missingLocationErrorMessage="DEFECT_DETAILS.MISSING_PIN.FORM_INVALID.ERROR_MESSAGE"
                    editLocationDescription="DEFECT_DETAILS.MARK_ON_3D.EDIT.TEXT"
                  />
                </LocationExpandableSection>
              </RenderIf>
              <ExpandableSection title={t('DETAILS')} expanded={true} className="observations-details-section">
                <div className="defect-form__container" id={'defect-form'}>
                  <ChangeStatusDropdown statuses={statuses} module={Object.assign({}, formValues)} user={user} handleChangeStatus={handleStatusChange} />
                  <RenderIf if={generalStatus === defectStatus.actioned && FEATURES.notifications?.visible}>
                    <WarningIndicator title={t('DEFECT_DETAILS.WARNING_ACTIONED.TITLE')} description={t('DEFECT_DETAILS.WARNING_ACTIONED.DESCRIPTION')} />
                  </RenderIf>
                  <Field
                    id={formConstants.fields.name}
                    disabled={readonly}
                    name={formConstants.fields.name}
                    component={CustomInput}
                    placeholder={t('DEFECT_DETAILS.FORM_NAME')}
                    label={t('DEFECT_DETAILS.FORM_NAME')}
                    labelClass="f-primary defect-form__label"
                    type="text"
                    size="lg"
                  />
                  <div className="defect-type-select">
                    <Field
                      id={formConstants.fields.mainType}
                      name={formConstants.fields.mainType}
                      disabled={readonly}
                      disabledItems={readonly ? observationTypes : []}
                      component={CustomSelect}
                      isHidden={false}
                      placeholder={t('DEFECT_DETAILS.FORM_MAIN_TYPE')}
                      label={'DEFECT_DETAILS.FORM_MAIN_TYPE'}
                      labelClass="f-primary defect-form__label"
                      type="text"
                      data={observationTypes}
                      valueField={observationTypeFields.value}
                      textField={observationTypeFields.name}
                      onChange={newValue => {
                        changeField(formConstants.fields.mainType, newValue[observationTypeFields.value]);
                      }}
                      size="lg"
                      valueComponent={selected => {
                        return (
                          <p className="observations-details-section__select-option">
                            <Icon name={observationsTypesIconsMap[selected?.item?.Value]} size="sm" handleHover={false} />
                            {selected.text}
                          </p>
                        );
                      }}
                      optionComponent={({ dataItem, children, onSelect, ...e }) => {
                        return (
                          <p onClick={e => onSelect(dataItem, e)} className="observations-details-section__select-options">
                            <Icon name={observationsTypesIconsMap[dataItem?.Value]} size="sm" handleHover={false} />
                            {children}
                          </p>
                        );
                      }}
                    />
                  </div>

                  <Field
                    id={formConstants.fields.type}
                    name={formConstants.fields.type}
                    disabled={readonly}
                    component={AutoComplete}
                    suggestions={Helpers.convertSuggestionArray(subTypesSuggestions)}
                    fetchSuggestions={fetchSubTypesSuggestions}
                    clearSuggestions={clearSubTypesSuggestions}
                    placeholder={t('DEFECT_DETAILS.FORM_TYPE')}
                    label={t('DEFECT_DETAILS.FORM_TYPE')}
                    labelClass="f-primary defect-form__label"
                    onChange={e => handleFieldChange(e.currentTarget ? e.currentTarget.value : e, formConstants.fields.type)}
                    type="text"
                    inputProps={{ isEnhancedDesignCustomProp: true }}
                  />

                  <Field
                    id={formConstants.fields.severity}
                    disabled={readonly}
                    name={formConstants.fields.severity}
                    minValue={1}
                    maxValue={10}
                    isHidden={false}
                    inputProps={{ onChange: this.handleSeverityChange }}
                    component={CustomInputRange}
                    placeholder={t('DEFECT_DETAILS.FORM_SEVERITY')}
                    label={t('DEFECT_DETAILS.FORM_SEVERITY')}
                    labelClass="f-primary defect-form__label defect-form__label--range"
                    hideBorder
                    type="range"
                    onChange={val => handleFieldChange(val, formConstants.fields.severity)}
                  />
                  <div className="comments-container">
                    <Field
                      id={formConstants.fields.description}
                      disabled={readonly}
                      name={formConstants.fields.description}
                      component={Textarea}
                      placeholder={'DEFECT_DETAILS.FORM_DESCRIPTION_PLACEHOLDER'}
                      label={'DEFECT_DETAILS.FORM_DESCRIPTION'}
                      labelClass="f-primary defect-form__label"
                      maxChars={textAreaMaxChars}
                      enableAutoResize={false}
                      className="input-wrapper__input text-area"
                      onChange={e => handleFieldChange(e.currentTarget.value, formConstants.fields.description)}
                    />
                  </div>
                  <div className="comments-container">
                    <Field
                      id={formConstants.fields.recommendation}
                      disabled={readonly}
                      name={formConstants.fields.recommendation}
                      component={Textarea}
                      placeholder={'DEFECT_DETAILS.FORM_RECOMMENDATION_PLACEHOLDER'}
                      label={'DEFECT_DETAILS.FORM_RECOMMENDATION'}
                      labelClass="f-primary defect-form__label"
                      maxChars={textAreaMaxChars}
                      enableAutoResize={false}
                      className="input-wrapper__input text-area"
                      onChange={e => handleFieldChange(e.currentTarget.value, formConstants.fields.recommendation)}
                    />
                  </div>
                  <CustomProperties
                    isDisabled={readonly}
                    formName={FORMS.dynamicDefect}
                    properties={properties}
                    updateProperty={property => this.handleCustomPropertyUpdate(property)}
                    getProperties={() => getDefectProperties(selectedDefect[fields.id])}
                    addProperty={data => this.handleCustomPropertyUpdate(data)}
                    restProps={{ DefectID: selectedDefect[fields.id] }}
                    deleteProperty={(data, calllback) => deleteDefectProperty(data, calllback)}
                    getSuggestions={getDefectPropertyNames}
                    isEnhancedDesignCustomProp
                    noDebounceOnForm
                    triggerDeleteCallback={false}
                  />
                  <Field
                    id={formConstants.fields.date}
                    disabled={true}
                    name={formConstants.fields.date}
                    component={UneditableInfo}
                    format={this.formatDate}
                    placeholder={t('DEFECT_DETAILS.FORM_DATE')}
                    label={t('DEFECT_DETAILS.FORM_DATE')}
                    labelClass="f-secondary-dark defect-form__label"
                    type="text"
                  />
                  <Field
                    id={formConstants.fields.loggedBy}
                    disabled={true}
                    name={formConstants.fields.loggedBy}
                    component={UneditableInfo}
                    placeholder={t('DEFECT_DETAILS.FORM_LOGGED_BY')}
                    label={t('DEFECT_DETAILS.FORM_LOGGED_BY')}
                    labelClass="f-secondary-dark defect-form__label"
                    type="text"
                  />
                </div>
                <RenderIf if={generalStatus === defectStatus.actioned || generalStatus === defectStatus.closed}>
                  <div className="defect-form__container">
                    <Field
                      id={formConstants.fields.actionedByUser}
                      name={formConstants.fields.actionedByUser}
                      component={UneditableInfo}
                      disabled={true}
                      placeholder={t('DEFECT_DETAILS.ACTIONED_BY_PLACEHOLDER')}
                      label={t('DEFECT_DETAILS.ACTIONED_BY')}
                      labelClass="f-secondary-dark defect-form__label"
                      type="text"
                    />

                    <Field
                      id={formConstants.fields.actionedDate}
                      name={formConstants.fields.actionedDate}
                      disabled={true}
                      component={UneditableInfo}
                      format={this.formatDate}
                      placeholder={t('DEFECT_DETAILS.ACTIONED_DATE_PLACEHOLDER')}
                      label={t('DEFECT_DETAILS.ACTIONED_DATE')}
                      labelClass="f-secondary-dark defect-form__label"
                      type="text"
                    />
                  </div>
                </RenderIf>
                <RenderIf if={generalStatus === defectStatus.closed}>
                  <div className="defect-form__container">
                    <Field
                      id={formConstants.fields.resolvedComment}
                      name={formConstants.fields.resolvedComment}
                      component={Textarea}
                      maxChars={textAreaMaxChars}
                      disabled={readonly}
                      placeholder={'DEFECT_DETAILS.RESOLVED_COMMENT_PLACEHOLDER'}
                      label={'DEFECT_DETAILS.RESOLVED_COMMENT'}
                      labelClass="f-secondary-dark defect-form__label"
                      className="input-wrapper__input text-area"
                      onChange={e => handleFieldChange(e.currentTarget.value, formConstants.fields.resolvedComment)}
                    />
                    <Field
                      id={formConstants.fields.resolvedByUser}
                      name={formConstants.fields.resolvedByUser}
                      component={UneditableInfo}
                      placeholder={t('DEFECT_DETAILS.RESOLVED_BY_PLACEHOLDER')}
                      label={t('DEFECT_DETAILS.RESOLVED_BY')}
                      labelClass="f-secondary-dark defect-form__label"
                      type="text"
                    />

                    <Field
                      id={formConstants.fields.resolvedDate}
                      name={formConstants.fields.resolvedDate}
                      disabled={true}
                      component={UneditableInfo}
                      format={this.formatDate}
                      placeholderText={t('DEFECT_DETAILS.RESOLVED_DATE_PLACEHOLDER')}
                      label={t('DEFECT_DETAILS.RESOLVED_DATE')}
                      labelClass="f-secondary-dark defect-form__label"
                      type="text"
                    />
                  </div>
                </RenderIf>
              </ExpandableSection>
              <ExpandableSection title={t('INSPECTION_COMPONENTS')} className="source-section" hideEditAction={readonly} onEditClick={this.openComponentPickerModal} expanded={true}>
                <NotificationsExpandableSectionInfo
                  data={defectComponentDetails}
                  type={expandableSectionInfoTypes.components}
                  onClick={this.handleComponentClick}
                  defaultComponent={defaultComponent}
                  emptyStateProps={{
                    buttonText: t('WORK_ORDER.ADD_COMPONENTS'),
                    emptyStateText: t('NO_EQUIPMENT_EMPTY_STATE'),
                    showButton: !readonly,
                    buttonAction: this.openComponentPickerModal,
                  }}
                />
              </ExpandableSection>
              <RenderIf if={true}>
                <ExpandableSection
                  title={t('INSPECTION_WORKORDERS')}
                  className="work-orders-section"
                  hideEditAction={!Helpers.hasAccess({ user, visibleFor: PERMISSIONS[PERMISSION_TYPES.observations].linkWorkOrders.name })}
                  onEditClick={this.openObservationWorkOrdersModal}
                  expanded={true}
                >
                  <ObservationWorkOrdersSection
                    workOrders={observationWorkOrders}
                    onWorkOrderClick={this.handleWorkOrderClick}
                    rowClickDisabled={!Helpers.hasAccess({ user, visibleFor: PERMISSIONS[PERMISSION_TYPES.workOrders].view.name })}
                    user={user}
                  />
                </ExpandableSection>
              </RenderIf>
              <ExpandableSection
                title={t('SECTION_TITLE.COMPONENT_FILES')}
                handlePreventClick={isDisabled ? () => handleInputDisabled(preventedFieldsPriorComponentIsCreated.upload) : null}
                onEditClick={e => {
                  e.stopPropagation();
                  this.openEditFilesModal();
                }}
                hideEditAction={false}
                expanded={true}
                className="files-section"
              >
                {this.renderFilesSection(loggedByUserID, readonly, defectFiles)}
              </ExpandableSection>
              <ExpandableSection
                t
                title={t('DEFECT_DETAILS.SECTION_ASSIGNEES')}
                handlePreventClick={isDisabled ? () => handleInputDisabled(preventedFieldsPriorComponentIsCreated.upload) : null}
                onEditClick={e => {
                  e.stopPropagation();
                  openAssigneesModal();
                }}
                hideEditAction={false}
                expanded={true}
              >
                <ContributorsList
                  contributors={{ [filterProps.assignees]: addedAssignees, [filterProps.collaborators]: addedCollaborators }}
                  handleCustomAction={openAssigneesModal}
                  sectionAction={null}
                  disabled={readonly}
                />
              </ExpandableSection>
              <div className="defect-form__save-cta-wrapper default-background">
                <Button type="submit" width="sm" height="md" text={t('SAVE')} disabled={!formHasUnsavedChanges || !isEmpty(formErrors)} />
              </div>
              <Modal
                {...modalData}
                linkedImages={(linkedImages || []).map(item => {
                  item.src = Helpers.getUploadImageSource(item.FileName, item.URL);
                  return { ...item, ID: item.FileID };
                })}
              />
              <Modal {...editFilesModal} />
            </form>
          );
        }}
      </AccessRenderer>
    );
  }
}

DefectForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

DefectForm = reduxForm({
  form: FORMS.defect,
  validate,
  touchOnChange: true,
  enableReinitialize: true,
})(DefectForm);

const selector = formValueSelector(FORMS.defect);
const mapStateToProps = (state, props) => {
  const loggedByUserID = selector(state, formConstants.fields.loggedByUserID),
    SystemType = selector(state, formConstants.fields.systemType),
    componentID = selector(state, formConstants.fields.componentID),
    generalStatus = selector(state, formConstants.fields.status),
    mainType = selector(state, formConstants.fields.mainType);

  return {
    user: state.userReducer,
    // defectComponentDetails are actually components linked to the observation
    defectComponentDetails: state.inspectionReducer.componentDetails,
    viewer: state.potreeReducer.viewerInstance,
    severityColors: state.themeReducer.severityColors,
    properties: state.inspectionReducer.defectProperties,
    componentID,
    SystemType,
    loggedByUserID,
    generalStatus,
    mainType,
    defectLocationAdding: state.inspectionReducer.defectLocationAdding,
    defectLocationEditing: state.inspectionReducer.defectLocationEditing,
    images360Ref: state.inspectionReducer.images360Ref,
    projectDMSCategories: state.projectDetailsReducer.projectDMSCategories,
    defaultDMSCategoryID: state.projectDetailsReducer.DefaultObservationDMSCategoryID,
    subTypesSuggestions: state.inspectionReducer.observationSubTypesSuggestions,
    unsavedCustomProps: state.inspectionReducer.observationFormState.unsavedCustomProps,
  };
};
const mapDispatchToProps = dispatch => ({
  setComponentDetails: id => dispatch(setComponentDetails(id)),
  setComponentDetailsData: data => dispatch(setComponentDetailsData(data)),
  unlinkDMSFileFromObservation: (sourceId, defectId, categoryID, callback, modalCallback) => dispatch(unlinkDMSFileFromObservation(sourceId, defectId, categoryID, callback, modalCallback)),
  getObservationDMSFilesUploaded: id => dispatch(getObservationDMSFilesUploaded(id)),
  //properties
  updateDefectProperty: property => dispatch(updateDefectProperty(property)),
  getDefectProperties: id => dispatch(getDefectProperties(id)),
  addDefectProperty: data => dispatch(addDefectProperty(data)),
  deleteDefectProperty: (data, calllback) => dispatch(deleteDefectProperty(data, calllback)),
  getDefectPropertyNames: (value, callback) => dispatch(getDefectPropertyNames(value, callback)),
  setDefectLocationAdding: val => dispatch(setDefectLocationAdding(val)),
  setDefectLocationEditing: val => dispatch(setDefectLocationEditing(val)),
  updateGeometry: data => dispatch(updateElementGeometry(data)),
  fetchSubTypesSuggestions: val => dispatch(fetchSubTypesSuggestions(val)),
  clearSubTypesSuggestions: () => dispatch(clearSubTypesSuggestions()),
  setGenericNotification: data => dispatch(setGenericNotification(data)),
  setObservationFormState: data => dispatch(setObservationFormState(data)),
  clearUnsavedChangesDirty: () => dispatch(clearUnsavedChangesDirty()),
  setUnsavedChangesDirty: data => dispatch(setUnsavedChangesDirty(data)),
});

DefectForm = connect(mapStateToProps, mapDispatchToProps)(DefectForm);
export default DefectForm;
