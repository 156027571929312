import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
// import Highcharts from 'highcharts/highstock';
import { isEmpty } from 'lodash';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { alarmColorPalette, formConstants as alarmConstants, ALARMS, descriptors } from '../../../app/inspections/components/readings-and-gauges/constants/alarm-constants';
import { formConstants as mpFormConstants } from '../../../app/inspections/components/readings-and-gauges/constants/measurement-point-constants';
import { aggregationFields, formConstants } from '../../../app/inspections/components/readings-and-gauges/constants/time-series-graph-constants';
import { filterParams } from '../../../app/inspections/components/readings-and-gauges/constants/time-series-graph-modal-constants';
import { THEMES } from '../../constants';
import Loader from '../../global-loader/components/simple-loader';
import '../styles/time-series-graph.scss';
import CustomLegend from './custom-legend';

highchartsMore(Highcharts);

let TimeSeriesGraph = ({ colors, unit = '-', data = [], isLoading = false, showLegend = true, showAlarms = false, forwardRef, visibleAggregations = [], isGraphPreview = false }, { t }) => {
  const [chartInstance, setChartInstance] = useState(null);
  const [extremes, setExtremes] = useState({ min: null, max: null });
  const [selectedMeasurementPoint, setSelectedMeasurementPoint] = useState(null);
  const [plotLines, setPlotLines] = useState([]);

  const mappedChartData = useMemo(() => {
    const mergedSeries = [];
    const availableColors = [
      colors.colorGreen,
      colors.colorYellow,
      colors.colorOrange,
      colors.colorRed,
      colors.colorPink,
      colors.colorPurple,
      colors.colorBlue,
      colors.colorGreenDark,
      colors.colorYellowDark,
      colors.colorOrangeDark,
      colors.colorRedDark,
      colors.colorPinkDark,
      colors.colorPurpleDark,
      colors.colorBlueDark,
      colors.colorGreenLight,
      colors.colorYellowLight,
      colors.colorOrangeLight,
      colors.colorRedLight,
      colors.colorPinkLight,
      colors.colorPurpleLight,
      colors.colorBlueLight,
    ];
    const lineOptions = {
      type: 'line',
    };
    const areaRangeOptions = {
      type: 'arearange',
    };

    const getAlarmColor = alarm => {
      const color = alarmColorPalette.hasOwnProperty(alarm[alarmConstants.fields.colour.name]) ? alarmColorPalette[alarm[alarmConstants.fields.colour.name]] : null;
      const isObject = typeof color === 'object';

      return isObject ? color.value : color;
    };

    const constructSeriesObjectByVisibleAggregations = (seriesItem, parent, parentIndex) => {
      const staticProps = {
        measurementPointName: parent?.[filterParams.measurementPointName] || `Measurement Point Name ${parentIndex}`,
        measurementPointID: parent?.[filterParams.measurementPointID],
        alarms: (parent?.[mpFormConstants.fields.alarms] || []).map(alarm => ({
          name: alarm[alarmConstants.fields.name.name],
          value: alarm[alarmConstants.fields.alarmLevel.name],
          color: getAlarmColor(alarm),
          descriptor: alarm[alarmConstants.fields.descriptor.name],
          ID: alarm.ID,
        })),
        scaleFactor: parent?.[mpFormConstants.fields.scaleFactor] && parent?.[mpFormConstants.fields.scaleFactor] !== 0 ? parent?.[mpFormConstants.fields.scaleFactor] : 1,
        inverted: parent?.[mpFormConstants.fields.inverted],
        dashStyle: seriesItem[mpFormConstants.fields.name] === 'Median' || seriesItem[mpFormConstants.fields.name] === 'Min/Max' ? 'Dash' : 'Solid',
        color: availableColors[parentIndex] || colors.primaryFontColor,
      };
      let dynamicProps = {
        data: seriesItem.Data,
        name: `${seriesItem[mpFormConstants.fields.name]}`,
      };

      if (seriesItem[mpFormConstants.fields.name] === 'Min/Max' || seriesItem[mpFormConstants.fields.name] === 'MinMin/MaxMax') {
        // Min/Max or MinMin/MaxMax clustered
        const isMinMax = seriesItem[mpFormConstants.fields.name] === 'Min/Max';
        const isMinMinMaxMax = seriesItem[mpFormConstants.fields.name] === 'MinMin/MaxMax';

        if (isMinMax || isMinMinMaxMax) {
          const minKey = isMinMax ? 'MINIMUM' : 'MINMIN';
          const maxKey = isMinMax ? 'MAXIMUM' : 'MAXMAX';

          if (visibleAggregations.some(aggregation => aggregation[aggregationFields.key] === maxKey) && visibleAggregations.some(aggregation => aggregation[aggregationFields.key] === minKey)) {
            // show min and max on the chart in form of area range
            dynamicProps = { ...dynamicProps, ...areaRangeOptions };
          } else {
            dynamicProps = { ...dynamicProps, ...lineOptions };
            if (visibleAggregations.some(aggregation => aggregation[aggregationFields.key] === minKey)) {
              dynamicProps = { ...dynamicProps, data: (seriesItem.Data || []).map(values => [values[0], values[1]]), name: isMinMax ? 'Min' : 'MinMin' };
            } else if (visibleAggregations.some(aggregation => aggregation[aggregationFields.key] === maxKey)) {
              dynamicProps = { ...dynamicProps, data: (seriesItem.Data || []).map(values => [values[0], values[2]]), name: isMinMax ? 'Max' : 'MaxMax' };
            } else {
              // Series should not be visible on graph, aggregation is not visible
              return null;
            }
          }
        }
      } else {
        if (visibleAggregations.some(aggregation => aggregation[aggregationFields.value] === seriesItem[formConstants.name])) {
          dynamicProps = { ...dynamicProps, ...lineOptions };
        } else {
          // Series should not be visible on graph, aggregation is not visible
          return null;
        }
      }

      return { ...staticProps, ...dynamicProps };
    };

    data.forEach((parent, i) => {
      parent.Series?.forEach(seriesItem => {
        if (!isEmpty(seriesItem.Data)) {
          const seriesObject = constructSeriesObjectByVisibleAggregations(seriesItem, parent, i);
          if (seriesObject) {
            mergedSeries.push(seriesObject);
          }
        }
      });
    });

    return mergedSeries;
  }, [data, visibleAggregations, colors]);

  const calculateNewExtremes = useCallback(
    ({ min: currentMin, max: currentMax }, plotlines) => {
      if (showAlarms) {
        const alarmValues = plotlines.map(p => p.value);
        const nextMin = Math.min(...alarmValues, currentMin);
        const nextMax = Math.max(...alarmValues, currentMax);

        if (currentMin !== nextMin || currentMax !== nextMax) {
          setExtremes({ min: nextMin, max: nextMax });
        }
      }
    },
    [showAlarms]
  );

  // Debounce chart updates to prevent excessive re-renders
  const debouncedChartUpdate = useMemo(
    () =>
      debounce((chart, config) => {
        if (chart) chart.update(config);
      }, 100),
    []
  );

  const handleSeriesMouseOver = debounce(function (event) {
    const chart = this.chart;
    if (!chart) return;

    const measurementPointName = this.userOptions?.measurementPointName;
    if (!measurementPointName) return;

    // Clear previously highlighted points
    chart.series?.forEach(s => {
      if (!s) return;
      s?.setState('normal');
    });

    // Highlight points and series based on proximity to mouse
    chart.series?.forEach(s => {
      if (s?.userOptions?.measurementPointName === measurementPointName) {
        s?.setState('normal');
      } else {
        s?.setState('inactive');
      }
    });
  }, 100);

  const handleSeriesMouseOut = debounce(function () {
    const chart = this?.chart;
    if (!chart) return;

    chart.series?.forEach(s => {
      s?.setState('normal');
    });
  }, 100);

  const handleSeriesClick = debounce(function (event) {
    if (!showAlarms) return;

    const clickedMeasurementPointName = this.userOptions?.measurementPointName;
    if (!clickedMeasurementPointName) return;

    if (selectedMeasurementPoint?.measurementPointName === clickedMeasurementPointName) {
      setSelectedMeasurementPoint(null);
      setPlotLines([]);
      setExtremes({ min: null, max: null });
    } else {
      setSelectedMeasurementPoint(this.userOptions);

      const alarmsData = this.userOptions?.alarms || [];

      const newPlotLines = alarmsData.map(alarm => {
        const plotLine = {
          color: alarm.color,
          dashStyle: 'Dash',
          value: alarm.value,
          width: 2,
          label: { text: alarm.name },
          scaleFactor: this.userOptions?.scaleFactor,
          inverted: this.userOptions?.inverted,
          descriptor: alarm.descriptor,
        };
        return plotLine;
      });

      setPlotLines(newPlotLines);
      calculateNewExtremes({ min: this.yAxis.min, max: this.yAxis.max }, newPlotLines);
    }

    if (this.chart) {
      debouncedChartUpdate(this.chart, {
        tooltip: {
          enabled: true,
        },
      });
    }
  }, 100);

  // Cleanup debounced functions on unmount
  useEffect(() => {
    return () => {
      handleSeriesMouseOver.cancel();
      handleSeriesMouseOut.cancel();
      handleSeriesClick.cancel();
      debouncedChartUpdate.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Loader height="100%" isLoading={true} divStyle={{ margin: 'auto' }} />;
  }

  function getTooltipFormatter(isAlarmLine, clickedAlarmLine) {
    return function () {
      if (!this) return '';

      // For alarm lines
      if ((isAlarmLine || this.isAlarmLine) && clickedAlarmLine) {
        // Get the specific alarm that was interacted with
        const plotLine = this;

        const alarmDescriptor = plotLine.series?.options?.alarms?.find(a => a.name === clickedAlarmLine.name)?.descriptor;
        const isInverted = plotLine.series?.options?.inverted || false;
        const scaleFactor = plotLine.series?.options?.scaleFactor || 1;
        const alarmTitle = ALARMS.find(a => a.id === clickedAlarmLine.name)?.displayName;

        const alarmDescriptors = descriptors(t);
        // Convert descriptor value to string and find matching descriptor
        const descriptorValue = String(alarmDescriptor);
        const alarmDescriptorValue = Object.values(alarmDescriptors).find(d => d.value === descriptorValue)?.title;

        return `
          <div class="time-series-graph__tooltip-container">
            <h6 class="f-primary time-series-graph__tooltip-container__title">
              ${t(alarmTitle) || t('ALARM')}
            </h6>
            <div class="time-series-graph__tooltip-container__content">
              <p class="f-secondary-dark time-series-graph__tooltip-container__label">
                  ${alarmDescriptorValue || t('ALARMS.FORM_DESCRIPTOR.DROPDOWN.LESS_THAN')}
              </p>
              <p class="f-primary time-series-graph__tooltip-container__value">
                ${clickedAlarmLine.value}
              </p>
              <p class="f-secondary-dark time-series-graph__tooltip-container__label">
                ${t('GRAPHING_GROUPS.ADD_MEASUREMENT_POINT.SCALE_FACTOR')}
              </p>
              <p class="f-primary time-series-graph__tooltip-container__value">
                ${scaleFactor}
              </p>
              <p class="f-secondary-dark time-series-graph__tooltip-container__label">
                ${t('GRAPHING_GROUPS.ADD_MEASUREMENT_POINT.INVERTED_LABEL')}
              </p>
              <p class="f-primary time-series-graph__tooltip-container__value">
                ${isInverted ? t('TABLE_COLUMN_DATA.YES') : t('TABLE_COLUMN_DATA.NO')}
              </p>
            </div>
          </div>`;
      }

      // Regular measurement point tooltip
      const hoveredX = this.x;
      const measurementPointName = this.series?.userOptions?.measurementPointName;

      if (isEmpty(measurementPointName)) return;

      let tooltipHtml = `
        <div class="time-series-graph__tooltip-container">
          <h6 class="f-primary time-series-graph__tooltip-container__title">
            <span style="color: ${this.series.color}">&#9632;</span>${measurementPointName}
          </h6>
          <p class="f-primary time-series-graph__tooltip-container__date">
            ${Highcharts.dateFormat('%A, %b %e, %Y', hoveredX)}
          </p>`;

      // Add measurement point values
      this.series.chart.series?.forEach(series => {
        if (series?.userOptions?.measurementPointName === measurementPointName) {
          series.points.forEach(point => {
            if (point.x === hoveredX && series.visible) {
              const isMinMax = series.name.toLowerCase().includes('min/max');
              if (isMinMax && point.low !== null && point.high !== null) {
                tooltipHtml += `
                  <p class="f-secondary-dark time-series-graph__tooltip-container__label">Min</p>
                  <p class="f-primary time-series-graph__tooltip-container__value">${point.low}</p>
                  <p class="f-secondary-dark time-series-graph__tooltip-container__label">Max</p>
                  <p class="f-primary time-series-graph__tooltip-container__value">${point.high}</p>`;
              } else if (isMinMax && point.low !== null) {
                tooltipHtml += `
                  <p class="f-secondary-dark time-series-graph__tooltip-container__label">Min</p>
                  <p class="f-primary time-series-graph__tooltip-container__value">${point.low}</p>`;
              } else if (isMinMax && point.high !== null) {
                tooltipHtml += `
                  <p class="f-secondary-dark time-series-graph__tooltip-container__label">Max</p>
                  <p class="f-primary time-series-graph__tooltip-container__value">${point.high}</p>`;
              } else if (point.y !== null) {
                tooltipHtml += `
                  <p class="f-secondary-dark time-series-graph__tooltip-container__label">${series.name}</p>
                  <p class="f-primary time-series-graph__tooltip-container__value">${point.y}</p>`;
              }
            }
          });
        }
      });

      // Add scale factor if present
      if (this.series?.userOptions?.scaleFactor !== 1) {
        tooltipHtml += `
          <p class="f-secondary-dark time-series-graph__tooltip-container__label">
            ${t('GRAPHING_GROUPS.ADD_MEASUREMENT_POINT.SCALE_FACTOR')}
          </p>
          <p class="f-primary time-series-graph__tooltip-container__value">
            ${this.series?.userOptions?.scaleFactor}
          </p>`;
      }

      // Add inverted status if true
      if (this.series?.userOptions?.inverted) {
        tooltipHtml += `
          <p class="f-secondary-dark time-series-graph__tooltip-container__label">
            ${t('GRAPHING_GROUPS.ADD_MEASUREMENT_POINT.INVERTED')}
          </p>
          <p class="f-primary time-series-graph__tooltip-container__value">
            ${t('TABLE_COLUMN_DATA.YES')}
          </p>`;
      }

      tooltipHtml += '</div>';
      return tooltipHtml;
    };
  }

  const options = {
    legend: {
      itemStyle: {
        color: colors.primaryFontColor,
      },
      itemHiddenStyle: { color: '#cccccc' },
      itemHoverStyle: { color: colors.secondaryFontColorLight },
    },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
      description: 'Time series graph showing measurement points and alarms',
      point: {
        valueDescriptionFormat: '{value} {unit}',
      },
    },
    title: {
      text: '',
    },
    plotOptions: {
      series: {
        showInLegend: false,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
        events: {
          mouseOver: handleSeriesMouseOver,
          mouseOut: handleSeriesMouseOut,
          click: handleSeriesClick,
        },
        // graph point events
        point: {
          events: {
            // updates the tooltip configuration of a chart in a series by enabling it
            click: function () {
              debouncedChartUpdate(this.series.chart, {
                tooltip: {
                  enabled: true,
                },
              });
            },
            mouseOut: function () {
              // remove the below condition if we want to show tooltip on click in the fullscreen view of the graph
              if (!isGraphPreview) return;

              // check if this.series.chart is defined before updating the tooltip configuration
              if (this.series.chart) {
                debouncedChartUpdate(this.series.chart, {
                  tooltip: {
                    enabled: false,
                  },
                });
              }
            },
          },
        },
      },
      line: {
        zIndex: 1,
        findNearestPointBy: 'x',
        cursor: 'pointer',
      },
      arearange: {
        lineWidth: 0,
        fillOpacity: 0.4,
        findNearestPointBy: 'x',
      },
    },
    series: mappedChartData,
    tooltip: {
      crosshairs: true,
      useHTML: true,
      // controlled by isGraphPreview property to prevent auto-showing tooltip on hover over points in graph preview mode
      enabled: !isGraphPreview,
      formatter: getTooltipFormatter(false),
      positioner: function (labelWidth, labelHeight, point) {
        // More info: https://api.highcharts.com/highcharts/tooltip.positioner
        const chart = this.chart;
        const chartContainer = chart.container.parentElement;
        const chartRect = chartContainer.getBoundingClientRect();
        const tooltipX = point.plotX + chart.plotLeft;
        const tooltipY = point.plotY + chart.plotTop;

        // Get the absolute position relative to the viewport
        const absoluteX = chartRect.left + tooltipX;
        const absoluteY = chartRect.top + tooltipY;

        // Calculate the best position for the tooltip
        let x = absoluteX - labelWidth / 2;
        let y = absoluteY - labelHeight - 10; // 10px above the point

        // Adjust if tooltip would go outside viewport
        if (x + labelWidth > window.innerWidth) {
          x = window.innerWidth - labelWidth - 10;
        }
        if (x < 0) {
          x = 10;
        }
        if (y < 0) {
          y = absoluteY + 10; // Show below point if not enough space above
        }

        return {
          x: x - chartRect.left, // Convert back to chart-relative coordinates
          y: y - chartRect.top,
        };
      },
      style: {
        pointerEvents: 'auto', // Allow interaction with the tooltip
        zIndex: 1000,
      },
      backgroundColor: 'var(--dropdown-bg)',
      borderWidth: 3,
      borderRadius: 8,
      outside: true, // Render the tooltip outside the chart
      className: 'time-series-graph-tooltip', // Added this class for custom styling (absolute positioning)
    },
    xAxis: {
      gridLineColor: colors.boxItemsSeparatorColor,
      minorGridLineColor: colors.boxItemsThemeColor,
      baselineColor: colors.boxItemsSeparatorColor,
      gridLineWidth: 1,
      tickWidth: 0,
      labels: {
        style: {
          color: colors.primaryFontColor,
        },
      },
      type: 'datetime',
    },
    yAxis: {
      gridLineColor: colors.boxItemsSeparatorColor,
      minorGridLineColor: colors.boxItemsThemeColor,
      baselineColor: colors.boxItemsSeparatorColor,
      gridLineWidth: 1,
      tickWidth: 0,
      title: {
        text: ``,
        style: {
          color: colors.primaryFontColor,
        },
      },
      labels: {
        style: {
          color: colors.primaryFontColor,
        },
      },
      min: extremes.min,
      max: extremes.max,
      plotLines: (() => {
        if (!showAlarms) return [];

        return plotLines.map(line => ({
          color: line.color,
          dashStyle: 'Dash',
          value: line.value,
          width: 2,
          label: { text: line.label?.text },
          isAlarmLine: true,
          zIndex: 5,
          className: 'alarm-plot-line',
          alarms: [
            {
              name: line.label?.text || 'Alarm',
              value: line.value,
              scaleFactor: line.scaleFactor || 100,
              inverted: line.inverted || false,
              color: line.color,
            },
          ],
          events: {
            mouseover: function (e) {
              const chart = this.axis.chart;
              if (!chart) return;

              // Enable tooltip and set it as an alarm tooltip
              debouncedChartUpdate(chart, {
                tooltip: {
                  enabled: true,
                  formatter: getTooltipFormatter(true),
                },
              });
            },
            mouseout: function () {
              const chart = this.axis.chart;
              if (!chart) return;

              // Reset tooltip to default state
              debouncedChartUpdate(chart, {
                tooltip: {
                  enabled: !isGraphPreview,
                  formatter: getTooltipFormatter(false),
                },
              });
            },
            click: function (e) {
              const chart = this.axis.chart;
              const clickedAlarmLine = this.options?.alarms?.[0];
              if (!chart || !clickedAlarmLine) return;

              // Ensure tooltip stays visible on click
              debouncedChartUpdate(chart, {
                tooltip: {
                  enabled: true,
                  formatter: getTooltipFormatter(true, clickedAlarmLine),
                },
              });
            },
          },
        }));
      })(),
      events: {
        afterSetExtremes: e => calculateNewExtremes(e, plotLines),
      },
    },
    rangeSelector: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    chart: {
      panning: {
        enabled: true,
        type: 'x',
      },
      panKey: 'alt',
      zoomType: 'x',
      backgroundColor: 'transparent',
      events: {
        load() {
          setChartInstance(this);

          // Add event listeners to plot lines after chart loads
          this.yAxis[0].plotLinesAndBands.forEach(plotLine => {
            if (plotLine.svgElem) {
              plotLine.svgElem
                .css({ cursor: 'pointer' })
                .on('mouseover', function () {
                  const options = plotLine.options;
                  if (options.events?.mouseOver) {
                    options.events.mouseOver.call(plotLine);
                  }
                })
                .on('mouseout', function () {
                  const options = plotLine.options;
                  if (options.events?.mouseOut) {
                    options.events.mouseOut.call(plotLine);
                  }
                })
                .on('click', function () {
                  const options = plotLine.options;
                  if (options.events?.click) {
                    options.events.click.call(plotLine);
                  }
                });
            }
          });
        },
      },
    },
  };

  const isDataEmpty = data?.every(parent => parent.Series?.every(seriesItem => isEmpty(seriesItem.Data)));

  return (
    <div className="time-series-graph">
      {!isDataEmpty ? (
        <>
          <HighchartsReact ref={forwardRef} highcharts={Highcharts} options={options} containerProps={{ style: { flex: '1 0 0' } }} />
          {showLegend && <CustomLegend chart={chartInstance} />}
        </>
      ) : (
        <div style={{ width: '100%', height: '100%' }}>
          <div className={`empty-state-active`}>
            <h4 className="empty-state-title f-secondary-light"> {t('GRAPH.NO_DATA_AVAILABLE')}</h4>
          </div>
        </div>
      )}
    </div>
  );
};

TimeSeriesGraph.propTypes = {
  colors: PropTypes.object.isRequired,
  unit: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showLegend: PropTypes.bool.isRequired,
  visibleAggregations: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.string.isRequired,
      Value: PropTypes.string.isRequired,
    })
  ),
};

TimeSeriesGraph.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  colors: props.ignoreTheme ? THEMES.light.colors : state.themeReducer.severityColors,
});

export default connect(mapStateToProps, null)(forwardRef((props, ref) => <TimeSeriesGraph forwardRef={ref} {...props} />));
