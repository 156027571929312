import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Button from '../../../../common/form/components/button';
import Icon from '../../../../common/icon/components/icon';
import SetCameraPosition from './set-camera-position';

import { componentHasAllCoordinatesZero } from '../../helpers/inspection-helper';

import '../../styles/location-section.scss';

const LocationSection = (
  {
    viewer,
    coordinates,
    invalid,
    readonlyCamPos = false,
    locationIsAdding,
    locationIsEditing,
    setLocationIsAdding,
    setLocationIsEditing,
    handleAddLocation,
    handleCameraChange,
    handleDeleteLocation,
    addingLocationDescription,
    // missingLocationErrorMessage,
    missingLocationDescription,
    editLocationDescription,
    showSetCamera = true,
  },
  { t }
) => {
  const renderContent = () => {
    if (viewer && (isEmpty(coordinates) || componentHasAllCoordinatesZero(coordinates))) {
      // Location item has no geometry
      if (locationIsAdding) {
        // Location is being added
        return (
          <div className="adding-location">
            <div className="info-wrapper">
              <Icon name="info" handleHover={false} className="adding-location__icon" />
              <p className="f-secondary-dark adding-location__text">{t(addingLocationDescription)}</p>
            </div>
            <Button
              type="button"
              width="lg"
              height="md"
              disabled={invalid}
              onClick={() => {
                if (viewer) {
                  viewer.dispatchEvent({
                    type: 'cancel_all_insertions',
                  });
                }
                setLocationIsAdding(false);
              }}
              variant="gray-outline"
              text={t('CANCEL')}
            />
          </div>
        );
      } else {
        return (
          <div className="add-location">
            <div className="add-location__warning-wrapper">
              <div className="missing-location">
                <Icon name="warning" handleHover={false} size="md" />
                <p className="f-primary light-bold missing-pin__text">{t('WARNING.MISSING_PIN')}</p>
              </div>
              <p className="f-primary add-location__description">{t(missingLocationDescription)}</p>
            </div>
            {/* MARK PIN ON 3D BUTTON */}
            <Button type="button" width="lg" height="md" disabled={invalid} onClick={handleAddLocation} className="add-location__button" text={t('MARK_ON_3D.BUTTON.TEXT')} keepOriginalText />
            {/* {invalid && <p className="f-secondary-red">{t(missingLocationErrorMessage)}</p>} */}
          </div>
        );
      }
    } else {
      if (locationIsEditing) {
        return (
          <div className="editing-location">
            <div className="info-wrapper">
              <Icon name="info" className="editing-location__icon" handleHover={false} />
              <p className="f-secondary-dark editing-location__text">{t(editLocationDescription)}</p>
            </div>
            <Button
              type="button"
              width="lg"
              height="md"
              disabled={invalid}
              onClick={() => {
                setLocationIsEditing(false);
              }}
              keepOriginalText
              className="add-location__button"
              text={t('MARK_ON_3D.EDIT.FINISH_BUTTON.TEXT')}
            />
          </div>
        );
      } else if (!isEmpty(coordinates) && !componentHasAllCoordinatesZero(coordinates)) {
        const coordinatesLongText = (coordinates[0] || []).join(' / '),
          coordinatesShortText = (coordinates[0] || []).map(cord => Number(cord).toFixed(2)).join(' / ');
        return (
          <>
            <div className="location-list">
              <div className="location-list-item">
                <p className="f-primary location-list-item__coordinates" title={coordinatesLongText}>
                  {coordinatesShortText}
                </p>
                {!invalid && (
                  <div className="location-list-item__actions">
                    <Icon name="pen" size="xs" onClick={() => (!invalid ? setLocationIsEditing(true) : null)} handleHover={!invalid} className={`icon`} />
                    <Icon name="trash" size="xs" handleHover={!invalid} onClick={() => (!invalid ? handleDeleteLocation(null) : null)} className={`icon`} />
                  </div>
                )}
              </div>
            </div>
            {showSetCamera && !invalid && <SetCameraPosition viewer={viewer} disabled={readonlyCamPos} handleChange={handleCameraChange} horizontalAlign="left" />}
          </>
        );
      } else {
        return null;
      }
    }
  };
  return <div className="location-section">{renderContent()}</div>;
};

LocationSection.contextTypes = {
  t: PropTypes.func.isRequired,
  showSetCamera: PropTypes.bool,
};

export default LocationSection;
