import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { debounce, get, isBoolean, isEmpty, pick } from 'lodash';

import Modal from '../../../common/modal/components/modal';
import CollapsibleToolbar from '../../inspections/components/collapsible-toolbar';
import ComponentPDF from '../../inspections/components/right-toolbar/component-pdf';
import LeftToolbar from './left-toolbar';
import RightToolbar from './right-toolbar';
import TopToolbar from './top-toolbar';

import { duplicateComponent, getDefectDetails, getInspectionDefects, getInspectionElementsByType, selectDefect, setComponentDetails } from '../../inspections/actions/inspection-actions';

import { clearUnsavedChangesDirty, setElementDetails, setInspectionID, setProjectID, updateElementDetails } from '../../inspections/actions/action-creators';
import { handleAddNewComponent, removeComponentInArray, setComponentsLoading, setPdfComponents, setSelectedFile } from '../actions/action-creators';
import {
  changePDFPage,
  confirmComponentTag,
  confirmTagCvFound,
  createComponent,
  getComponentDrawingDetails,
  getPdfComponents,
  getPdfList,
  handleMergeCvWithExistingComponent,
  handleMergeCvWithNewComponent,
  handleNotInThisPdf,
  handleNotInThisPdfCVFound,
  initPdfTagScreen,
  saveComponentTag,
  selectDrawing,
  updateComponent,
  updateComponentTag,
} from '../actions/pdf-tag-actions';

import Helpers from '../../../common/helpers';
import routesConstants from '../../../common/routes-constants';
import { placements } from '../../inspections/constants/constants';
import { componentFields, componentFilterValues, defaultFilter, filterValues, pdfStatus } from '../constants/constants';

import { Tools } from '@tarik.djurdjevic/react-sketch';
import DrawingHelpers from '../../../common/drawing-helpers';
import { DefaultComponent } from '../../inspections/constants/component-constants';
import { measurementTypes } from '../../inspections/constants/constants';

import VisibleArea from '../../inspections/components/visible-area';
import { getComponentDMSFilesUploaded } from '../../upload/actions/external-upload-actions';
import '../style/pdf-tag.scss';

class PdfTag extends Component {
  constructor(props) {
    super(props);
    const { query } = props.router.location;

    this.state = {
      toolbarPlacements: {
        [placements.left]: false,
        [placements.right]: true,
        [placements.top]: false,
      },
      inspectionId: parseInt(query.inspection_id),
      projectId: parseInt(query.project_id),
      fileId: parseInt(query.file_id),
      selectedFile: null,
      filter: {
        ...defaultFilter,
      },
      selectedTool: Tools.Pan,
      enableDrawing: true,
      isEditingDrawing: false,
      modalData: {
        isOpen: false,
      },
      componentTagList: [],
    };
    this.searchChangeDebounced = debounce(this.handleFilterChanged, 500);
    this.handleDrawingEditDebounced = debounce(this.handleDrawingEdit, 50);
  }
  componentDidMount = () => {
    const { inspectionId, projectId, fileId } = this.state;
    const { initPdfTagScreen, setProjectID, setInspectionID, selectedComponent, setPdfComponents } = this.props;

    if (!inspectionId || !projectId) {
      Helpers.goTo(routesConstants.routes.protectedRoutes.project.fullPath);
    }

    setInspectionID(inspectionId);
    setProjectID(projectId);

    initPdfTagScreen(
      inspectionId,
      fileId,
      defaultFilter,
      1,
      selectedComponent ? selectedComponent[componentFields.componentId] : null,
      () => null,
      false,
      newDrawings => setPdfComponents(newDrawings)
    );
  };

  toggleToolbar = (placment, toggle) => {
    this.setState(prevProps => ({
      toolbarPlacements: {
        ...prevProps.toolbarPlacements,
        [placment]: isBoolean(toggle) ? toggle : !prevProps.toolbarPlacements[placment],
      },
    }));
  };

  componentWillUnmount = () => {
    const { clearPdfTagComponents, selectDefect } = this.props;

    selectDefect({ defect: {} });

    clearPdfTagComponents();
    this.setState({
      filter: {
        defaultFilter,
      },
    });
    this.searchChangeDebounced.cancel();
    this.handleDrawingEditDebounced.cancel();
  };

  handleComponentChange = (component, scrollIntoView) => {
    const { fileId } = this.state;
    const { getDefectDetails, updateElementDetails, getComponentDrawingDetails, isDirty } = this.props;

    if (isDirty && !window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
      return;
    }

    if (component[componentFields.componentId]) {
      updateElementDetails({});

      // sets the newly created defect as temp to handle rest of the comments logic in getDefectDetails method
      if (component[componentFields.componentId] < 1) component[componentFields.isTemp] = true;

      getDefectDetails(
        component,
        () => {
          this.toggleToolbar([placements.right], false);
          if (component[componentFields.componentId] > 0) {
            getComponentDrawingDetails(fileId, component[componentFields.componentId], component[componentFields.cvFoundID] || null, component, drawings =>
              this.setState({ componentTagList: drawings })
            );
          }
        },
        { ...pick(component, [componentFields.confirmed, componentFields.cvFoundID]) },
        true,
        true
      );
    } else if (component[componentFields.cvFoundID]) {
      updateElementDetails({});

      setTimeout(() => {
        updateElementDetails({ ...component, SystemType: measurementTypes.component, Geometry: {} });
      }, 500);
      getComponentDrawingDetails(fileId, null, component[componentFields.cvFoundID], component, drawings => this.setState({ componentTagList: drawings }));
      this.toggleToolbar([placements.right], false);
    }
    if (scrollIntoView) {
      setTimeout(() => {
        Helpers.scrollIntoView('items-renderer__container', null, 0, this.generateCustomIdentificator(component));
      }, 500);
    }
  };

  handleFilterChanged = newFilter => {
    const { inspectionId, fileId } = this.state;
    const { getPdfComponents, selectDefect, selectedComponent, pdfPageNumber, setPdfComponents } = this.props;
    //new filter will be in state as this func is debounced
    selectDefect({ defect: {} });
    getPdfComponents(
      inspectionId,
      fileId,
      newFilter,
      pdfPageNumber,
      selectedComponent ? selectedComponent[componentFields.componentId] : null,
      () => {
        this.setState({ filter: newFilter });
      },
      false,
      newDrawings => setPdfComponents(newDrawings)
    );
  };

  setSelectedFile = file => {
    const { inspectionId } = this.state;
    const { initPdfTagScreen, selectComponent, isDirty } = this.props;

    if (isDirty && !window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
      return;
    }

    initPdfTagScreen(inspectionId, file.FileID);
    this.setState({ fileId: file.FileID });

    this.setState({ filter: defaultFilter });
    selectComponent();
  };

  handleCreateComponentClick = () => {
    const { createTempComponent, selectDefect, isDirty } = this.props;
    const { inspectionId } = this.state;

    if (isDirty && !window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
      return;
    }
    // resets the componentTagList (on the right-hand side)
    this.setState({
      componentTagList: [],
    });

    const defaultComponent = DefaultComponent();
    const newComp = {
      ...defaultComponent,
      [componentFields.confirmed]: false,
      [componentFields.drawings]: null,
      [componentFields.componentId]: -2,
      [componentFields.taggingType]: pdfStatus.noTag,
      [componentFields.type]: defaultComponent.Name,
      DateCreated: Helpers.getUnixDate(new Date().getTime()),
      InspectionID: inspectionId,
      // something temp - remove soon
      Drawings: [],
    };
    createTempComponent(newComp);
    selectDefect({ defect: newComp });
    this.toggleToolbar([placements.right], false);

    setTimeout(() => {
      Helpers.scrollIntoView('items-renderer__container', null, 0, this.generateCustomIdentificator(newComp));
    }, 300);
  };

  updateTag = (tagId, drawing) => {
    const { updateComponentTag, selectedComponent, getComponentDMSFilesUploaded } = this.props;
    updateComponentTag(tagId, drawing, selectedComponent, false, () => getComponentDMSFilesUploaded(selectedComponent[componentFields.componentId]));
  };

  handleDrawingAdd = (drawing, pdfPageNumber) => {
    const { selectedComponent, selectedFile, saveComponentTag, getComponentDrawingDetails } = this.props;
    if (selectedComponent && selectedFile) {
      saveComponentTag(selectedFile.FileID, selectedComponent, drawing, pdfPageNumber, true, () =>
        getComponentDrawingDetails(selectedFile.FileID, selectedComponent[componentFields.componentId] || null, selectedComponent[componentFields.cvFoundID] || null, selectedComponent, drawings =>
          this.setState({ componentTagList: drawings })
        )
      );
      this.setState({ selectedTool: Tools.Pan });
    }
  };

  handleDrawingEdit = (_componentDrawings, editedDrawing) => {
    const { selectDrawing } = this.props;
    selectDrawing(editedDrawing);
    this.finishDrawingEdit(editedDrawing);
  };

  handleDrawingListClick = drawing => {
    const { fileId, inspectionId } = this.state;
    const { pdfPageNumber, selectDrawing, changePDFPage, filter, selectedComponent } = this.props;
    const parsedDrawing = JSON.parse(drawing[componentFields.drawing]).objects[0];
    parsedDrawing.ID = drawing.ID;

    if (drawing[componentFields.pageNumber] === pdfPageNumber) {
      // if on same page then select drawing
      // TODO: fix this when able, issue is that uuid from right-hand side list of tags is different than the drawings on the ComponentPDF
      this.selectComponentFromDrawing(parsedDrawing);
      selectDrawing(parsedDrawing);
    } else {
      // deeplink to pdf page and select drawing there
      changePDFPage(drawing[componentFields.pageNumber], inspectionId, fileId, filter, selectedComponent, false, () => selectDrawing(parsedDrawing));
    }
  };

  handleRemoveDrawing = (drawing, drawingPageNumber) => {
    const { filter } = this.state;
    const { selectedComponent, selectedFile } = this.props;
    const tags = get(selectedComponent, 'Drawings.Objects') || get(selectedComponent, 'Drawings.objects') || get(selectedComponent, 'Drawings') || [];

    if (!tags) {
      return;
    }

    if (filter[filterValues.componentFilter] === componentFilterValues.cvFound) {
      if (drawing) {
        this.notInThisPdfClick(selectedComponent, componentFilterValues.cvFound, drawing.ID);
      } else {
        const filteredTags = tags.filter(tag => tag.uuid !== drawing.uuid);
        const newDrawing = { ...selectedComponent[componentFields.drawings], objects: filteredTags };
        // call new action for save tag
        this.saveTag(selectedFile.FileID, selectedComponent, newDrawing, drawingPageNumber, true);
      }
    } else {
      if (drawing) {
        this.notInThisPdfClick(selectedComponent, filter[filterValues.componentFilter], drawing.ID);
      } else {
        const filteredTags = tags.filter(tag => tag.uuid !== drawing.uuid);
        const newDrawing = { ...selectedComponent[componentFields.drawings], objects: filteredTags };
        this.saveTag(selectedFile.FileID, selectedComponent, newDrawing, drawingPageNumber, true);
      }
    }
  };

  toggleEditMode = (callback = () => null) => {
    this.setState(
      prevState => ({
        isEditingDrawing: !prevState.isEditingDrawing,
        selectedTool: !prevState.isEditingDrawing ? Tools.Select : Tools.Pan,
      }),
      callback
    );
  };

  finishDrawingEdit = drawing => {
    const { selectedComponent, selectedFile } = this.props;
    if (selectedComponent && selectedFile && !isEmpty(selectedComponent[componentFields.drawings])) {
      const selectedComponentDrawings = selectedComponent[componentFields.drawings];
      let foundIndex = selectedComponentDrawings.findIndex(o => o.ID === drawing.ID);

      if (foundIndex > -1) {
        this.updateTag(drawing[componentFields.componentId], JSON.stringify(drawing));
      }
    }
  };

  markOnPdfClick = () => {
    this.setState({ selectedTool: Tools.Rectangle });
  };

  notInThisPdfClick = (component, type = componentFilterValues.cvFound, tagId) => {
    const { filter, fileId } = this.state;
    const { selectedComponent, handleNotInThisPdfCVFound, selectedFile, handleNotInThisPdf, getComponentDrawingDetails, selectComponent, removeComponentInArray } = this.props;

    if (filter[filterValues.componentFilter] === componentFilterValues.cvFound) {
      handleNotInThisPdfCVFound(component[componentFields.cvFoundID], !isEmpty(selectedComponent) && selectedComponent[componentFields.cvFoundID] === component[componentFields.cvFoundID], () =>
        getComponentDrawingDetails(selectedFile.FileID, selectedComponent[componentFields.componentId] || null, selectedComponent[componentFields.cvFoundID] || null, selectedComponent, drawings =>
          this.setState({ componentTagList: drawings })
        )
      );
    } else if (filter[filterValues.componentFilter] === componentFilterValues.cvLinked) {
      this.openConfirmNotInThisPdfModal(
        () => {
          handleNotInThisPdf(component, fileId, tagId, false, () =>
            getComponentDrawingDetails(
              selectedFile.FileID,
              selectedComponent[componentFields.componentId] || null,
              selectedComponent[componentFields.cvFoundID] || null,
              selectedComponent,
              drawings => {
                // if no drawings remaining from component, reset selected component
                if (isEmpty(drawings)) {
                  selectComponent();
                  removeComponentInArray(selectedComponent[componentFields.componentId], componentFields.componentId);
                } else {
                  this.setState({
                    componentTagList: drawings,
                  });
                }
              }
            )
          );
        },
        selectedComponent,
        tagId
      );
    } else {
      this.openConfirmNotInThisPdfModal(
        () => {
          handleNotInThisPdf(component, fileId, tagId, false, () =>
            getComponentDrawingDetails(
              selectedFile.FileID,
              selectedComponent[componentFields.componentId] || null,
              selectedComponent[componentFields.cvFoundID] || null,
              selectedComponent,
              drawings => {
                // if no drawings remaining from component, reset selected component
                if (isEmpty(drawings)) {
                  selectComponent();
                  removeComponentInArray(selectedComponent[componentFields.componentId], componentFields.componentId);
                } else {
                  this.setState({
                    componentTagList: drawings,
                  });
                }
              }
            )
          );
        },
        selectedComponent,
        tagId
      );
    }
  };

  openConfirmNotInThisPdfModal = (confirmAction, selectedComponent, tagId) => {
    const { t } = this.context;

    const closeAction = () =>
      this.setState({
        modalData: {
          isOpen: false,
        },
      });

    const modalData = {
      isOpen: true,
      // handles single tag or all tags removal
      title: tagId ? t('TAG_NOT_IN_THIS_PDF_MODAL.TITLE') : t('TAGS_NOT_IN_THIS_PDF_MODAL.TITLE'),
      content: t(tagId ? 'TAG_NOT_IN_THIS_PDF_MODAL.DESC' : 'TAGS_NOT_IN_THIS_PDF_MODAL.DESC', { name: selectedComponent[componentFields.name] }),
      type: 'yes-no',
      customClassName: 'modal-small',
      confirmAction: () => {
        confirmAction();
        closeAction();
      },
      closeAction,
    };
    this.setState({ modalData });
  };

  confirmTagClick = () => {
    const { selectedComponent, selectedFile, confirmTag, confirmTagCvFound, getComponentDMSFilesUploaded, pdfPageNumber, getComponentDrawingDetails } = this.props;
    const { filter, fileId } = this.state;
    if (filter[filterValues.componentFilter] === componentFilterValues.cvFound) {
      confirmTagCvFound(fileId, selectedComponent[componentFields.componentId], selectedComponent, pdfPageNumber, filter[filterValues.componentFilter]);
    } else {
      confirmTag(
        selectedFile.FileID,
        selectedComponent[componentFields.componentId],
        selectedComponent,
        pdfPageNumber,
        () => getComponentDMSFilesUploaded(selectedComponent[componentFields.componentId]),
        () =>
          getComponentDrawingDetails(selectedFile.FileID, selectedComponent[componentFields.componentId] || null, selectedComponent[componentFields.cvFoundID] || null, selectedComponent, drawings => {
            this.setState({
              componentTagList: drawings,
            });
          })
      );
    }
  };

  selectComponentFromDrawing = clickedObj => {
    const { components, selectedComponent } = this.props;
    console.log(components);
    const comp = DrawingHelpers.findComponentByDrawingID(components, clickedObj);

    if (isEmpty(comp)) return;
    if (
      !isEmpty(selectedComponent) &&
      selectedComponent[componentFields.componentId] === comp[componentFields.componentId] &&
      selectedComponent[componentFields.cvFoundID] === comp[componentFields.cvFoundID]
    ) {
      return;
    }
    comp && this.handleComponentChange(comp, true);
  };

  generateCustomIdentificator = item => {
    if (!item) {
      return '';
    }
    return `${item[componentFields.cvFoundID] || '-'}-${item[componentFields.componentId] || '-'}`;
  };

  render() {
    const {
      components,
      selectedComponent,
      updateComponent,
      pdfList,
      selectedFile,
      componentsLoading,
      createComponent,
      getInspectionElementsByType,
      inspectionComponents,
      handleMergeCvWithNewComponent,
      handleMergeCvWithExistingComponent,
      pdfFileLoading,
      selectedDrawing,
      selectDrawing,
      setComponentDetails,
      componentDetails,
      removeComponentInArray,
      duplicateComponent,
      location,
      setComponentsLoading,
      pdfPageNumber,
      isDirty,
      clearUnsavedChangesDirty,
      route,
    } = this.props;
    const {
      handleComponentChange,
      searchChangeDebounced,
      setSelectedFile,
      handleCreateComponentClick,
      handleDrawingAdd,
      handleDrawingEditDebounced,
      markOnPdfClick,
      notInThisPdfClick,
      confirmTagClick,
      handleRemoveDrawing,
      toggleEditMode,
      handleDrawingListClick,
    } = this;
    const {
      filter,
      toolbarPlacements: { [placements.left]: leftCollapsed, [placements.top]: topCollapsed, [placements.right]: rightCollapsed },
      selectedTool,
      enableDrawing,
      inspectionId,
      isEditingDrawing,
      modalData,
      projectId,
      fileId,
      componentTagList,
    } = this.state;

    return (
      <div className="pdf-tag-wrapper">
        <CollapsibleToolbar collapsed={leftCollapsed} toggleToolbar={this.toggleToolbar} placement={placements.left}>
          <LeftToolbar
            {...{
              components,
              selectedComponent,
              componentsLoading,
              notInThisPdfClick,
              handleComponentClick: (_e, c) => handleComponentChange(c),
              handleFilterChanged: (value, type) => {
                if (isDirty && !window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
                  return;
                }

                let newFilter = {
                  [type]: value,
                };
                if (type === filterValues.componentFilter) {
                  newFilter = { ...newFilter, [filterValues.searchText]: '' };
                }
                if (type === filterValues.searchText) {
                  //prevent text box from type disabling
                  this.searchChangeDebounced.cancel();
                  this.setState(prevProps => ({
                    filter: {
                      ...prevProps.filter,
                      [filterValues.searchText]: value,
                    },
                  }));
                }

                searchChangeDebounced({
                  ...filter,
                  ...newFilter,
                });
              },
              customIdentificatorFunction: this.generateCustomIdentificator,
              handleCreateComponentClick,
              filter,
              location,
            }}
          />
        </CollapsibleToolbar>
        <CollapsibleToolbar collapsed={topCollapsed} toggleToolbar={this.toggleToolbar} placement={placements.top}>
          <TopToolbar
            {...{
              pdfList,
              selectedFile,
              setSelectedFile,
            }}
          />
        </CollapsibleToolbar>
        <CollapsibleToolbar collapsed={rightCollapsed || isEmpty(selectedComponent)} toggleToolbar={this.toggleToolbar} placement={placements.right}>
          <RightToolbar
            {...{
              component: selectedComponent,
              updateComponent,
              tags: selectedComponent && componentTagList?.length > 0 ? componentTagList : [],
              createComponent,
              markOnPdfClick,
              notInThisPdfClick,
              confirmTagClick,
              components: inspectionComponents,
              inspectionId,
              handleRemoveDrawing,
              selectedDrawing,
              handleDrawingListClick,
              setComponentDetails,
              componentDetails,
              filter,
              duplicateComponent: id => duplicateComponent(id, DefaultComponent()[componentFields.name]),
              customDeleteCallback: ({ ComponentID }) => {
                setComponentsLoading(true);
                removeComponentInArray(ComponentID);
                setComponentsLoading(false);
              },
              activePdfPageNumber: pdfPageNumber,
            }}
            fetchSuggestions={searchText => getInspectionElementsByType(inspectionId, measurementTypes.component, searchText)}
            handleMergeCvWithNewComponent={() => handleMergeCvWithNewComponent(selectedComponent, inspectionId, (selectedFile || {}).FileID)}
            handleMergeCvWithExistingComponent={existingComp => handleMergeCvWithExistingComponent(selectedComponent, existingComp)}
            fileId={fileId}
            projectId={projectId}
            isDirty={isDirty}
            clearUnsavedChangesDirty={clearUnsavedChangesDirty}
            route={route}
          />
        </CollapsibleToolbar>
        <VisibleArea interactable={true} rightCollapsed={rightCollapsed} leftCollapsed={leftCollapsed} topCollapsed={topCollapsed}>
          {selectedFile && selectedFile.URL && !pdfFileLoading && (
            <ComponentPDF
              file={selectedFile}
              selectComponent={this.selectComponentFromDrawing}
              isFullScreen={true}
              handleDrawingAdd={handleDrawingAdd}
              handleDrawingEdit={handleDrawingEditDebounced}
              selectedTool={selectedTool}
              enableDrawing={enableDrawing}
              componentsLoading={componentsLoading}
              selectedDrawing={selectedDrawing}
              selectDrawing={selectDrawing}
              isEditingDrawing={isEditingDrawing}
              toggleEditMode={toggleEditMode}
              editEnabled={true}
              transformProps={{
                initialPositionX: 450,
                initialPositionY: 190,
                initialScale: 0.8,
              }}
              fileId={fileId}
              filter={filter}
              alignPDFLeft={true}
            />
          )}
        </VisibleArea>

        <Modal {...modalData} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  components: state.pdfTagReducer.pdfComponents,
  componentsLoading: state.pdfTagReducer.componentsLoading,
  selectedComponent: state.inspectionReducer.selectedDefect,
  pdfList: state.pdfTagReducer.pdfList,
  pdfFileLoading: state.pdfTagReducer.pdfFileLoading,
  selectedFile: state.pdfTagReducer.selectedFile,
  selectedDrawing: state.pdfTagReducer.selectedDrawing,
  inspectionComponents: state.inspectionReducer.components,
  componentDetails: state.inspectionReducer.componentDetails,
  pdfPageNumber: state.pdfTagReducer.pdfPageNumber,
  isDirty: state.unsavedChangesReducer.isDirty,
});

const mapDispatchToProps = dispatch => ({
  getInspectionDefects: (inspectionId, type, searchText, callback) => dispatch(getInspectionDefects(inspectionId, type, searchText, {}, callback)),
  getDefectDetails: (defect, callback, legacyProps, includeLoader, saveItem) => dispatch(getDefectDetails(defect, callback, legacyProps, includeLoader, saveItem)),
  updateComponent: defect => dispatch(updateComponent(defect)),
  setInspectionID: id => dispatch(setInspectionID(id)),
  setProjectID: id => dispatch(setProjectID(id)),
  getPdfList: (inspectionId, selectedFileID) => dispatch(getPdfList(inspectionId, selectedFileID)),
  initPdfTagScreen: (inspectionID, fileId, filter, pdfPageNumber, componentId, callback, loadMore, dataCallback) =>
    dispatch(initPdfTagScreen(inspectionID, fileId, filter, pdfPageNumber, componentId, callback, loadMore, dataCallback)),
  setSelectedFile: file => dispatch(setSelectedFile(file)),
  getPdfComponents: (inspectionID, fileId, filter, pdfPageNumber, componentId, callback, loadMore, dataCallback) =>
    dispatch(getPdfComponents(inspectionID, fileId, filter, pdfPageNumber, componentId, callback, loadMore, dataCallback)),
  selectComponent: component => dispatch(setElementDetails({ defect: component || {} })),
  selectDefect: component => dispatch(selectDefect(component)),
  updateElementDetails: el => dispatch(updateElementDetails(el)),
  createComponent: component => dispatch(createComponent(component)),
  createTempComponent: component => dispatch(handleAddNewComponent(component)),
  saveComponentTag: (inspectionFileID, selectedComponent, drawing, pdfPageNumber, reloadDrawings, drawingCallback) =>
    dispatch(saveComponentTag(inspectionFileID, selectedComponent, drawing, pdfPageNumber, reloadDrawings, drawingCallback)),
  updateComponentTag: (tagId, drawing, selectedComponent, reloadDrawings, callback) => dispatch(updateComponentTag(tagId, drawing, selectedComponent, reloadDrawings, callback)),
  selectDrawing: drawing => dispatch(selectDrawing(drawing)),
  confirmTag: (inspectionFileID, componentID, selectedComponent, pdfPageNumber, successCallback, drawingCallback) =>
    dispatch(confirmComponentTag(inspectionFileID, componentID, selectedComponent, pdfPageNumber, successCallback, drawingCallback)),
  confirmTagCvFound: (FileID, ComponentID, selectedComponent, pdfPageNumber, componentFilter) => dispatch(confirmTagCvFound(FileID, ComponentID, selectedComponent, pdfPageNumber, componentFilter)),
  getInspectionElementsByType: (inspectionID, elementType, searchText) => dispatch(getInspectionElementsByType(inspectionID, elementType, searchText)),
  clearPdfTagComponents: () => dispatch(setPdfComponents([])),
  handleMergeCvWithNewComponent: (cvComponent, inspectionId, fileID) => dispatch(handleMergeCvWithNewComponent(cvComponent, inspectionId, fileID)),
  handleMergeCvWithExistingComponent: (cvComponent, existingComponent) => dispatch(handleMergeCvWithExistingComponent(cvComponent, existingComponent)),
  handleNotInThisPdfCVFound: (unconfirmedTagId, shouldCloseSidebar, drawingCallback) => dispatch(handleNotInThisPdfCVFound(unconfirmedTagId, shouldCloseSidebar, drawingCallback)),
  setComponentDetails: id => dispatch(setComponentDetails(id)),
  handleNotInThisPdf: (Component, FileID, tagId, shouldRemoveItFromTheList, drawingCallback) => dispatch(handleNotInThisPdf(Component, FileID, tagId, shouldRemoveItFromTheList, drawingCallback)),
  removeComponentInArray: id => dispatch(removeComponentInArray(id)),
  duplicateComponent: (id, name, callback) => dispatch(duplicateComponent(id, name, callback)),
  getComponentDMSFilesUploaded: id => dispatch(getComponentDMSFilesUploaded(id)),
  setPdfComponents: selectedModuleItemDrawings => dispatch(setPdfComponents(selectedModuleItemDrawings)),
  getComponentDrawingDetails: (FileID, ComponentID, CvFoundID, selectedComponent, dataCallback) =>
    dispatch(getComponentDrawingDetails(FileID, ComponentID, CvFoundID, selectedComponent, dataCallback)),
  changePDFPage: (newPDFPageNumber, inspectionId, fileId, filter, selectedComponent, showOnlyConfirmedDrawings, callback) =>
    dispatch(changePDFPage(newPDFPageNumber, inspectionId, fileId, filter, selectedComponent, showOnlyConfirmedDrawings, callback)),
  setComponentsLoading: loading => dispatch(setComponentsLoading(loading)),
  clearUnsavedChangesDirty: () => dispatch(clearUnsavedChangesDirty()),
});

PdfTag.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PdfTag);
