import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AccessRenderer from '../../access-renderer/components/access-renderer';
import ToolRenderer from './tool-renderer';

import '../styles/toolbox.scss';

class ToolboxWrapper extends Component {
  render() {
    const { activeTool, toolbox, type, className } = this.props;

    return (
      <div className={`toolbox ${type} ${className}`}>
        {toolbox.map(({ visibleFor, isHidden, action, toolType, ...restProps }, index) => {
          return isHidden ? null : (
            <AccessRenderer visibleFor={visibleFor} key={index}>
              {({ hasAccess }) => {
                return <ToolRenderer activeTool={activeTool} disabled={!hasAccess} handleClick={hasAccess ? action : () => null} active={toolType === activeTool} {...restProps} />;
              }}
            </AccessRenderer>
          );
        })}
      </div>
    );
  }
}

ToolboxWrapper.defaultProps = {
  type: 'individual',
  className: '',
  activeTool: null,
};
ToolboxWrapper.propTypes = {
  type: PropTypes.oneOf(['individual', 'merged']),
};

ToolboxWrapper.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ToolboxWrapper;
